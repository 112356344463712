<template>
  <div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Milestone</th>
          <th>Data</th>
          <th>Complete</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="m in project.milestones"
          :key="m.id"
        >
          <td v-text="m.milestone.name" />
          <td v-if="m.m_date">
            {{ formatDate(m.m_date) }}
          </td>
          <td
            v-else-if="m.milestone.is_text"
            class="milestone-data"
          >
            {{ m.m_text }}
          </td>
          <td v-else-if="m.milestone.attachment_type_id">
            Attachment
          </td>
          <td v-else />
          <td :class="{ success: m.checked, danger: !m.checked }">
            {{ m.checked ? 'Yes' : 'No' }}
            <span
              v-if="canChangeMilestones"
              class="edit-icon"
              title="Update"
              @click="editAssignedMilestone(m)"
            >
              <EditIcon title="Update" />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <router-link
      v-if="canChangeMilestones"
      :to="{ name: 'update_project_milestones' }"
      class="btn btn-primary"
      tag="button"
    >
      Change Milestones
    </router-link>
    <AssignedMilestoneUpdate ref="assignedMilestoneUpdate" />
    <AttachFile
      ref="attachFile"
      :project="project"
      @file-upload="$emit('reload-project')"
    />
  </div>
</template>

<script>
/**
 * View to show all Milestones assigned to a project and their status.
 */
import AssignedMilestoneUpdate from './AssignedMilestoneUpdate'
import AttachFile from '@/views/Project/AttachFile'
import EditIcon from 'vue-material-design-icons/SquareEditOutline.vue'
import formatDate from '@/filters/formatDate'

export default {
  components: {
    AssignedMilestoneUpdate,
    AttachFile,
    EditIcon,
  },
  props: {
    /** project */
    project: {
      type: Object,
      required: true,
    },
    /** if current user can attach files */
    canAttachFile: {
      type: Boolean,
      required: true,
    },
    /** if current user can modifiy milestones status */
    canChangeMilestones: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      error: '',
    }
  },
  methods: {
    editAssignedMilestone (milestone) {
      if (this.canAttachFile && milestone.milestone.attachment_type_id) {
        this.$refs.attachFile.show(milestone.milestone.attachment_type_id)
      } else {
        this.$refs.assignedMilestoneUpdate.edit(milestone)
      }
    },
    formatDate,
  },
}
</script>

<style scoped>
.milestone-data {
  white-space: pre;
}
td.danger {
  background-color: #f5c6cb;
}
td.success {
  background-color: #c3e6cb;
}
</style>
