<template>
  <li class="nav-item">
    <router-link
      :to="route"
      class="nav-link"
    >
      <font-awesome-icon
        v-if="routeIcon"
        :icon="routeIcon"
        fixed-width
      />
      {{ routeTitle }}
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    route: {
      type: [String, Object],
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
  },
  computed: {
    routeObject () {
      return this.$router.resolve(this.route)
    },
    routeMeta () {
      return (this.routeObject && this.routeObject.route && this.routeObject.route.meta) || {}
    },
    routeSidebarMeta () {
      return this.routeObject.route.meta.sidebar || {}
    },
    routeTitle () {
      return this.title || this.routeSidebarMeta.title || this.routeMeta.title
    },
    routeIcon () {
      return this.icon || this.routeSidebarMeta.icon
    },
  },
}
</script>
