<template>
  <Modal
    ref="modal"
    :title="title"
    :no-header="confirmOnly"
  >
    <p>{{ message }}</p>
    <div :class="confirmOnly ? 'd-flex justify-content-center' : ''">
      <button
        class="btn btn-primary"
        @click.prevent="confirmed"
      >
        {{ confirmOnly ? 'Ok' : 'Yes' }}
      </button>
      <button
        v-if="!confirmOnly"
        class="btn btn-default"
        @click.prevent="cancel"
      >
        No
      </button>
    </div>
  </Modal>
</template>

<script>
/**
 * A confirmation modal that asks a question and emit's an event (with supplied data)
 * when 'Yes' is clicked.
 */
import Modal from '@/components/Modal'

export default {
  components: {
    Modal,
  },
  props: {
    confirmOnly: {
      type: Boolean,
    },
  },
  data () {
    return {
      title: '',
      message: '',
      data: null,
    }
  },
  methods: {
    cancel () {
      this.$refs.modal.hide()
    },
    /**
     * @description emit 'confirmed' event when Yes clicked in dialog and close model
     * @private
     */
    confirmed () {
      this.$emit('confirmed', this.data)
      this.$refs.modal.hide()
    },
    /**
     * @description open confirmation modal
     * @param {String} title modal title
     * @param {String} message to be displayed for yes/no answer
     * @param {String} data to be emitted with 'confirmed' event when Yes clicked
     */
    confirm (title, message, data) {
      this.title = title
      this.message = message
      this.data = data
      this.$refs.modal.show()
    },
  },
}
</script>
