import Support from '@/views/Support'

export default [
  {
    path: 'support',
    name: 'support',
    component: Support,
    meta: {
      crumbs: [{ title: 'Contact Support' }],
    },
  },
]
