<template>
  <div>
    <BaseFormCard
      :heading="submitName"
      :submit-title="submitName"
      :submit-promise="submit"
    >
      <BaseInput
        v-if="projectId"
        :value="project.id"
        title="ID"
        name="project_id"
        readonly
      />
      <BaseInput
        v-model="project.name"
        :maxlength="64"
        title="Project Name"
        name="name"
        type="text"
        required
        trim
      />
      <BaseSelect
        v-model="project.project_type"
        :options="projectTypes"
        :readonly="Boolean(project.project_type)"
        title="Delivery Group"
        name="project_type"
        track-by="name"
        label="name"
        placeholder=""
        required
        @input="getGroupData"
      />
      <span v-if="project.project_type">
        <template v-if="!projectId">
          <FieldRoleUser
            v-for="role in roles"
            :key="role.usr_role.id"
            v-model="role.usr"
            :role="role.usr_role"
            :required="role.usr_role.required_state.is_start"
          />
        </template>
        <SchemaPropertyField
          v-for="prop in editProps"
          :key="prop.fullname"
          v-model="prop.base[prop.name]"
          :name="prop.fullname"
          :schema="prop.schema"
          :required="prop.required"
        />
        <BaseInput
          v-model="project.p4p.clarity_code"
          :maxlength="16"
          title="Clarity Code"
          name="clarity_code"
          type="text"
          required
          trim
        />
        <BaseInput
          v-model="project.p4p.pan_code"
          title="PAN Code / OPEX Cost Centre"
          name="pan_code"
          type="text"
          required
          trim
        />
        <BaseInput
          v-model="project.p4p.release_id"
          title="Release ID (Ops Engagement)"
          name="release_id"
          type="text"
          required
          trim
        />
        <BaseTextarea
          v-model="project.p4p.work_required"
          :rows="4"
          title="Work Required"
          name="work_required"
          required
          trim
        />
        <BaseInputDate
          v-model="project.p4p.delivery_date"
          :readonly="!canEditDeliveryDate"
          :min="minDate"
          :disabled-days="[0, 6]"
          :disabled-dates="disabledDates"
          title="Work Completion Date"
          help="This is a desired date only. Based on the requirements, the actual delivery date will be proposed. If urgent, please mention in the Work Required field."
          name="delivery_date"
          required
        />
        <AttachmentsInput
          v-if="!projectId"
          v-model="attachments"
          :project="project"
          :attachment-types="attachmentTypes"
        />
      </span>
      <FieldError v-model="error" />
    </BaseFormCard>
  </div>
</template>

<script>
/**
 * View to Create or Update a project.
 * Emits a 'reload-project' when a project update is succesful.
 */
import FieldError from '@/components/FieldError'
import FieldRoleUser from '@/components/FieldRoleUser'
import groupAPI from '@/api/group'
import projectAPI from '@/api/project'
import holidayAPI from '@/api/holiday'
import AttachmentsInput from '@/views/Project/AttachmentsInput'
import SchemaPropertyField from '@/components/SchemaPropertyField'
import moment from 'moment'

export default {
  components: {
    AttachmentsInput,
    FieldError,
    FieldRoleUser,
    SchemaPropertyField,
  },
  props: {
    /**
     * Optional project id.
     * If supplied will edit this project.
     * If a new project will be created.
     */
    projectId: {
      type: [String, Number],
      default: '',
    },
  },
  data () {
    return {
      project: {
        name: '',
        properties: {},
        project_type: undefined,
        p4p: {
          clarity_code: '',
          pan_code: '',
          release_id: '',
          work_required: '',
          delivery_date: '',
        },
      },
      error: '',
      projectTypes: [],
      attachmentTypes: [],
      attachments: {},
      roles: [],
      minDate: '',
      disabledDays: null,
      disabledDates: null,
      schema: { properties: {} },
    }
  },
  computed: {
    submitName () {
      if (this.projectId) {
        return 'Update Project'
      }
      return 'Create Project'
    },
    canEditDeliveryDate () {
      // Can edit if creating a new project or editing a newly created (or rejected) project.
      return !this.projectId ||
        (this.project && this.project.project_state &&
          (this.project.project_state.is_start || this.project.project_state.reset_project))
    },
    editProps () {
      return this.getObjectProps(this.schema, this.project)
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getObjectProps (schema, data) {
      let props = schema['ui:order'] || []
      if (props.length === 0) {
        props = Object.keys(schema.properties || {}).sort()
      }
      props = props.filter(p => schema.properties[p] && !schema.properties[p].readOnly)
      return props.map(p => {
        const propSchema = schema.properties[p]
        if (propSchema.type === 'object') {
          // get nested props
          return this.getObjectProps(propSchema, data[p]).map(op => {
            return { ...op, fullname: p + '.' + op.name }
          })
        }
        return {
          name: p,
          schema: propSchema,
          base: data,
          fullname: name,
          required: schema.required && schema.required.includes(p),
        }
      }).flat()
    },
    async submit () {
      if (this.projectId) {
        await projectAPI.updateProject(this.project)
        this.$router.go(-1)
      } else {
        await this.createProject()
        if (this.project.project_type.use_subcats) {
          this.$router.replace({ name: 'update_project_services', params: { projectId: this.project.id, showCatalog: true } })
        } else {
          this.$router.go(-1)
        }
      }
    },
    getGroupData (projectType) {
      this.roles = []
      this.attachmentTypes = []
      Promise.all([
        groupAPI.getNewProjectSchema(projectType.id),
        groupAPI.getGroupRoles(projectType.id),
        groupAPI.getAttachmentTypes(projectType.id),
      ]).then(([schema, roles, attachmentTypes]) => {
        if (!this.projectId) {
          this.schema = schema.data
        }
        this.roles = roles.data.filter(r => r.create_at_start).map(r => { return { usr_role: r, usr: {} } })
        this.attachmentTypes = attachmentTypes.data
      }).catch(error => {
        this.error = error.message
      })
    },
    createFormData () {
      const formData = new FormData()
      const atTypes = Object.keys(this.attachments)
      if (atTypes.length === 0) {
        return undefined
      }
      // set file name to work around IE including full path in attachment upload
      atTypes.forEach(atType => {
        this.attachments[atType].forEach(file => {
          formData.append(atType, file, file.name)
        })
      })
      return formData
    },
    createProject () {
      let projId = ''
      this.project.roles = this.roles
      return projectAPI.createProject(this.project).then(resp => {
        this.$parent.$toasted.success('Project ' + this.project.name + ' created')
        projId = resp.data.id
        this.project.id = resp.data.id
        const formData = this.createFormData()
        if (formData) {
          return projectAPI.uploadAttachment(projId, formData)
        }
        return Promise.resolve()
      }).catch(error => {
        if (projId) {
          this.$parent.$toasted.error('Attachment upload failed: ' + error.message)
          this.$router.push({ name: 'show_project_info', params: { projectId: projId } })
        }
        return Promise.reject(error)
      })
    },
    getData () {
      const fromDate = new Date()
      const toDate = new Date()
      // get next 12 months of holidays
      toDate.setFullYear(toDate.getFullYear() + 1)
      const requests = [
        groupAPI.getGroups(),
        holidayAPI.getHolidaysBetween(fromDate, toDate),
      ]
      if (this.projectId) {
        requests.push(projectAPI.getProject(this.projectId))
        requests.push(projectAPI.getSchema(this.projectId))
      }
      Promise.all(requests).then(([projectTypes, holidays, project, schema]) => {
        this.projectTypes = projectTypes.data
        this.buildDisabledDates(holidays.data.map(h => h.date))
        if (project) {
          this.project = project.data
          this.schema = schema.data
          this.getGroupData(this.project.project_type)
        } else if (this.projectTypes.length === 1) {
          // If there is only one group then set it to it
          this.project.project_type = this.projectTypes[0]
          this.getGroupData(this.project.project_type)
        }
      }).catch(error => {
        this.error = error.message
      })
    },
    goBack () {
      this.$router.go(-1)
    },
    buildDisabledDates (holidays) {
      // work day offset for work completion date
      let days = 10
      let m = moment().startOf('day')
      // convert holidays from text to Date objects
      holidays = holidays.map(h => moment(h, 'YYYY-MM-DD').toDate())
      // sort holidays
      holidays.sort((a, b) => { return a - b })
      // remove old holidays (including today)
      while (holidays.length > 0 && holidays[0].valueOf() <= m.valueOf()) {
        holidays.shift()
      }
      // count 'days' working days ahead
      while (days > 0) {
        m = m.add(1, 'days')
        const day = m.day()
        if (holidays.length > 0 && holidays[0].valueOf() === m.valueOf()) {
          // is a holiday, remove it from holidays and continue
          holidays.shift()
        } else if (day > 0 && day < 6) {
          // only count mon-fri non-holidays
          days--
        }
      }
      this.minDate = m.format('YYYY-MM-DD')
      this.disabledDates = holidays
    },
  },
}
</script>
