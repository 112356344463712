<template>
  <ProjectBookingList
    v-if="bookingTypeId"
    :project-id="project.id"
    :booking-type-id="bookingTypeId"
    :default-filters="[]"
  >
    <template #booking-header>
      <ul>
        <router-link
          v-for="bt in bookingTypes"
          :key="bt.id"
          :to="{ params: { bookingTypeId: bt.id } }"
          :class="{ active: bt.id === parseInt(bookingTypeId) }"
          class="pointer text-primary"
          tag="li"
        >
          <span>{{ bt.title }}</span>
        </router-link>
      </ul>
    </template>
  </ProjectBookingList>
</template>

<script>
import ProjectBookingList from '@/views/Booking/ProjectBookingList'
import bookingAPI from '@/api/booking'

export default {
  components: {
    ProjectBookingList,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    bookingTypeId: {
      type: [String, Number],
      default: undefined,
    },
    canCreateBookings: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      error: '',
      bookingTypes: [],
    }
  },
  computed: {
    innerValue: {
      get () {
        return this.bookingTypeId
      },
      set (n) {
        this.$router.push({ params: { bookingTypeId: n } })
      },
    },
  },
  watch: {
    '$route': function () {
      this.setDefaultType()
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    setDefaultType () {
      if (!this.bookingTypeId && this.bookingTypes && this.bookingTypes.length > 0) {
        this.$router.replace({ params: { bookingTypeId: this.bookingTypes[0].id } })
      }
    },
    getData () {
      bookingAPI.getBookingTypes().then(resp => {
        this.bookingTypes = resp.data
          .filter(t => t.project_type_id === this.project.project_type.id)
          .sort((a, b) => a.title.localeCompare(b.title))
        this.setDefaultType()
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
ul {
  padding-left: 0;
  margin-bottom: 0;

  li {
    font-size: 18px;
    display: inline-block;
    list-style: none;

    span {
      margin-right: 10px;
      color: $gray-600;
      padding-bottom: 3px;
    }
    &.active span {
      color: $primary;
      font-weight: bold;
      border-bottom: 2px solid $primary;
    }
    &:last-child::after {
      display: none;
    }
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}
</style>
