<template>
  <CustomFilterModal
    :open.sync="open"
    :filter-active="filterActive"
    @show="updateOptions"
    @update="update"
  >
    <form
      class="filter-form"
      @submit.prevent="addOption"
    >
      <p
        v-if="error"
        class="text-danger"
        v-text="error"
      />
      <div class="custom-control custom-checkbox pb-1">
        <input
          :id="`${field.name}-selectedCheck`"
          v-model="showSelected"
          type="checkbox"
          class="custom-control-input"
        >
        <label
          class="custom-control-label user-select-none font-weight-normal"
          :for="`${field.name}-selectedCheck`"
        >
          Show Selected
        </label>
      </div>
      <div class="filter-input py-1">
        <input
          v-model="filter"
          :disabled="showSelected"
          :placeholder="placeholder"
          type="text"
          class="form-control"
        >
      </div>
      <div
        v-if="!singleOptionOnly"
        class="py-1 user-select-none"
      >
        <a
          href="#"
          @click.prevent="selectAll"
        >Select all</a>
        <a
          href="#"
          class="float-right"
          @click.prevent="clearAll"
        >Clear</a>
      </div>
      <div class="filter-options">
        <div
          v-for="(opt, i) in filteredOptions"
          :key="opt.value"
          class="custom-control custom-checkbox w-100"
        >
          <input
            :id="`${field.name}-filterOption-${i}`"
            :checked="opt.selected"
            type="checkbox"
            class="custom-control-input"
            @change="toggleOption(opt)"
          >
          <label
            class="custom-control-label user-select-none font-weight-normal text-nowrap w-100"
            :for="`${field.name}-filterOption-${i}`"
          >
            {{ opt.label ? opt.label : '(Blank)' }}
          </label>
        </div>
      </div>
      <input
        :hidden="true"
        type="submit"
      >
    </form>
  </CustomFilterModal>
</template>

<script>
/**
 * Modal to allow updating the User assigned to a Usr Role for a Project.
 */
import CustomFilterModal from '@/components/CustomFilterModal'

export default {
  components: {
    CustomFilterModal,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      open: false,
      filter: '',
      showSelected: false,
      options: [],
      loading: false,
      error: '',
    }
  },
  computed: {
    filterActive () {
      return Object.keys(this.$route.query).includes(this.field.filterField)
    },
    filterLower () {
      return this.filter.toLowerCase()
    },
    selectedOptions () {
      return this.options.filter(o => o.selected)
    },
    singleOptionOnly () {
      return this.field && !!this.field.filterSingleOnly
    },
    filteredOptions () {
      if (this.showSelected) {
        return this.selectedOptions
      } else if (!this.filter || !this.field.filterOptions) {
        return this.options
      }
      return this.options.filter(o => o.lower.indexOf(this.filterLower) >= 0)
    },
    placeholder () {
      if (this.field.filterOptions) {
        return 'Search filters'
      }
      return 'Enter new filter'
    },
  },
  watch: {
    'field.filterOptions': function () { this.buildOptions() },
  },
  beforeMount () {
    this.buildOptions()
  },
  methods: {
    toggleOption (opt) {
      opt.selected = !opt.selected
      if (!this.singleOptionOnly || !opt.selected) { return }
      this.selectedOptions.forEach((v, i, arr) => {
        if (v.value !== opt.value) {
          arr[i].selected = false
        }
      })
    },
    reset () {
      this.error = ''
      this.loading = false
      this.filter = ''
      this.options = []
    },
    clearAll () {
      this.filteredOptions.forEach((v, i, arr) => { arr[i].selected = false })
    },
    selectAll () {
      this.filteredOptions.forEach((v, i, arr) => { arr[i].selected = true })
    },
    addOption () {
      if (!this.filter || this.field.filterOptions ||
        (this.field.filterType === Number && isNaN(this.filter))
      ) {
        return
      }
      const i = this.filteredOptions.findIndex(o => o.lower === this.filterLower)
      if (i >= 0) {
        this.filteredOptions[i].selected = true
      } else {
        this.options.unshift(
          {
            value: this.filter,
            label: this.filter,
            lower: this.filterLower,
            selected: true,
          },
        )
      }
      this.filter = ''
    },
    sortOptions () {
      // sort by name
      this.options.sort(function (a, b) {
        const valA = a.value.toLowerCase()
        const valB = b.value.toLowerCase()
        if (valA < valB) {
          return -1
        }
        if (valA > valB) {
          return 1
        }
        return 0
      })
    },
    updateOptions () {
      this.filter = ''
      const q = this.$route.query[this.field.filterField]
      let values = []
      if (Array.isArray(q)) {
        values = q.slice()
      } else if (q) {
        values = [q]
      }
      if (values.length > 1) {
        // remove duplicates
        values = values.filter((v, i, a) => a.indexOf(v) === i)
      }
      this.options.forEach((v, i, arr) => {
        const vi = values.findIndex(value => value === v.value)
        if (vi >= 0) {
          arr[i].selected = true
          values.splice(vi, 1)
        } else {
          arr[i].selected = false
        }
      })
      if (!this.field.filterOptions && values.length > 0) {
        const newOptions = values.map(v => {
          return {
            value: v,
            label: v,
            lower: v.toLowerCase(),
            selected: true,
          }
        })
        this.options = this.options.concat(newOptions)
        this.sortOptions()
      }
    },
    createOptions (values) {
      const indexField = this.field.filterTrackBy
      const labelField = this.field.filterLabel || this.field.filterTrackBy
      const options = values.map(v => {
        if (indexField) {
          return {
            value: v[indexField],
            label: v[labelField],
            lower: v[labelField].toLowerCase(),
            selected: false,
          }
        } else {
        // Must be a string to compare with url params
          v = String(v)
          return {
            value: v,
            label: v,
            lower: v.toLowerCase(),
            selected: false,
          }
        }
      })
      // remove duplicates
      this.options = options.filter((v, i, a) => a.findIndex(av => av.lower === v.lower) === i)
      this.updateOptions()
    },
    buildOptions () {
      if (typeof this.field.filterOptions === 'function') {
        this.field.filterOptions().then(resp => {
          this.createOptions(resp)
          this.loading = false
        }).catch(error => {
          this.loading = false
          this.error = error.message
        })
      } else if (this.field.filterOptions) {
        this.createOptions(this.field.filterOptions)
      } else {
        let q = this.$route.query[this.field.filterField]
        if (Array.isArray(q)) {
          // remove duplicates
          q = q.filter((v, i, a) => a.indexOf(v) === i)
          this.createOptions(q)
        } else if (q) {
          this.createOptions([q])
        }
      }
    },
    update () {
      this.open = false
      const query = { ...this.$route.query }
      delete query.page
      if (!this.selectedOptions || this.selectedOptions.length === 0) {
        delete query[this.field.filterField]
      } else {
        query[this.field.filterField] = this.selectedOptions.map(o => o.value)
      }
      this.$router.push({ path: this.$route.path, query: query })
    },
  },
}
</script>

<style lang="scss" scoped>
// remove box shadow as it is being cut off
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.filter-form {
  .filter-options {
    height: 150px;
    overflow-y: auto;
    overflow-x: hidden;

    .custom-checkbox:hover {
      background-color: $gray-300;
    }
  }
}
// Hack for mozilla to stop cutting off wide options
// TODO: do something better
@-moz-document url-prefix() {
  .custom-control-label {
    padding-right: 18px;
  }
}
</style>
