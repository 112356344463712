<template>
  <Modal
    ref="modal"
    title="Post Project Survey"
  >
    <BaseForm
      :submit-title="submitTitle"
      cancel-title="Decline"
      @cancel="decline"
      @submit="submit"
    >
      <div class="pb-3">
        <div class="font-weight-bold">
          {{ curQuestion.rating }}
        </div>
        <BaseInputRadio
          id="radio1"
          v-model="rating"
          name="ratingRadio"
          inline
          :value="1"
        >
          <EmojiFrown style="width: 1.5em; height: 1.5em;" />
        </BaseInputRadio>
        <BaseInputRadio
          id="ratingRadio2"
          v-model="rating"
          name="ratingRadio"
          inline
          :value="3"
        >
          <EmojiNeutral style="width: 1.5em; height: 1.5em;" />
        </BaseInputRadio>
        <BaseInputRadio
          id="ratingRadio3"
          v-model="rating"
          name="ratingRadio"
          inline
          :value="5"
        >
          <EmojiSmile style="width: 1.5em; height: 1.5em;" />
        </BaseInputRadio>
      </div>
      <div
        v-if="curQuestion.detail"
        class="pb-2"
      >
        <BaseInputCheckbox
          v-model="enterDetail"
          :title="curQuestion.detail"
          name="enterDetail"
          class="mb-1"
        />
        <BaseTextarea
          v-model="detail"
          title=""
          :rows="5"
          name="detail"
          :disabled="!enterDetail"
          trim
        />
      </div>
      <FieldError v-model="error" />
      <template #buttons>
        <button
          class="btn btn-primary"
          type="submit"
          :disabled="!rating"
        >
          {{ submitTitle }}
        </button>
        <button
          class="btn btn-default float-right"
          @click.prevent="decline"
        >
          Skip Survey
        </button>
      </template>
    </BaseForm>
  </Modal>
</template>

<script>
/**
 * Modal to change the Sites assigned to a Project.
 */
import Modal from '@/components/Modal'
import FieldError from '@/components/FieldError'
import EmojiFrown from 'bootstrap-icons/icons/emoji-frown.svg?inline'
import EmojiSmile from 'bootstrap-icons/icons/emoji-smile.svg?inline'
import EmojiNeutral from 'bootstrap-icons/icons/emoji-neutral.svg?inline'
import moment from 'moment'
import projectAPI from '@/api/project'
import teamAPI from '@/api/team'

export default {
  components: {
    EmojiFrown,
    EmojiSmile,
    EmojiNeutral,
    FieldError,
    Modal,
  },
  data () {
    return {
      error: '',
      project: {},
      results: {},
      questions: [],
      curIndex: 0,
      rating: null,
      detail: '',
      enterDetail: false,
    }
  },
  computed: {
    curQuestion () {
      return this.questions[this.curIndex] || {}
    },
    lastQuestion () {
      return this.curIndex + 1 === this.questions.length
    },
    submitTitle () {
      return this.lastQuestion ? 'Submit' : 'Next Question'
    },
  },
  methods: {
    resetForm () {
      this.rating = null
      this.detail = ''
      this.enterDetail = false
    },
    reset () {
      this.error = ''
      this.project = {}
      this.results = {}
      this.questions = []
      this.curIndex = 0
      this.resetForm()
    },
    submit () {
      const result = { rating: this.rating }
      if (this.enterDetail && this.detail) {
        result.detail = this.detail
      }
      this.results[this.curQuestion.id] = result
      if (!this.lastQuestion) {
        this.resetForm()
        this.curIndex = this.curIndex + 1
      } else {
        projectAPI.createProjectSurvey(this.project.id, this.results).then(resp => {
          this.$refs.modal.hide()
        }).catch(error => {
          this.error = error.message
        })
      }
    },
    decline () {
      this.$refs.modal.hide()
      projectAPI.createProjectSurvey(this.project.id, {}).catch(error => {
        this.error = error.message
      })
      this.reset()
    },
    start (project) {
      // first check if project was closed more than a week ago
      const closedDate = moment.utc(String(project.updated_on))
      const weekAgo = moment().subtract(7, 'days')
      if (closedDate.isBefore(weekAgo)) {
        return
      }

      this.reset()
      this.project = project
      Promise.all([
        projectAPI.getProjectSurvey(this.project.id),
        teamAPI.getSurveyQuestions(project.team_id),
      ]).then(([survey, questions]) => {
        this.questions = questions.data.filter(q => !q.disabled)
        if (!survey.data.created_on && this.questions.length > 0) {
          this.$refs.modal.show()
        }
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
