<template>
  <div>
    <div class="row">
      <div class="col-md-5 col-xs-5">
        <form class="form">
          <BaseSelect
            v-model="milestone_group"
            :options="milestone_groups"
            title="Milestone Groups"
            placeholder=""
            name="milestone_group"
            track-by="id"
            label="name"
            @input="getMilestones"
          />
        </form>
      </div>
      <div class="col-md-2 col-xs-2 text-center">
        <ChevronRightIcon title="" />
      </div>
      <div class="col-md-5 col-xs-5">
        <label>Project's Milestones</label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5 col-xs-5 form-group">
        <select
          v-model="selected_milestones"
          class="form-control"
          multiple
          size="10"
        >
          <option
            v-for="m in milestones"
            :key="m.id"
            :value="m"
          >
            {{ m.name }}
          </option>
        </select>
      </div>
      <div class="col-md-2 col-xs-2 center-block text-center">
        <div class="center-block">
          <div class="btn-group-vertical">
            <button
              class="btn btn-primary"
              @click="addMilestone"
            >
              Add
            </button>
            <button
              class="btn btn-primary"
              @click="deleteMilestone"
            >
              Delete
            </button>
            <button
              class="btn btn-primary"
              @click="moveUp"
            >
              Up
            </button>
            <button
              class="btn btn-primary"
              @click="moveDown"
            >
              Down
            </button>
            <button
              class="btn btn-primary"
              @click="selectAll"
            >
              Select All
            </button>
            <button
              class="btn btn-primary"
              @click="deleteAll"
            >
              Delete All
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-xs-5 form-group">
        <select
          v-model="assigned_index"
          class="form-control"
          size="10"
        >
          <option
            v-for="(am, index) in assigned_milestones"
            :key="am.milestone.id"
            :value="index"
          >
            {{ am.milestone.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <button
        :disabled="!modified"
        class="btn btn-primary"
        @click="save"
      >
        Save
      </button>
      <button
        class="btn btn-default"
        @click.prevent="goBack"
      >
        Back
      </button>
      <span
        v-if="saved"
        class="tick-icon"
      >
        <CheckIcon title="" />
        <label>Saved</label>
      </span>
    </div>
  </div>
</template>

<script>
/**
 * A view to select the Milestones assigned to a Project.
 */
import CheckIcon from 'vue-material-design-icons/Check.vue'
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue'
import groupAPI from '@/api/group'
import projectAPI from '@/api/project'

export default {
  components: {
    CheckIcon,
    ChevronRightIcon,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      modified: false,
      assigned_index: '',
      assigned_milestones: [],
      selected_milestones: [],
      milestones: [],
      milestone_group: '',
      milestone_groups: [],
      saved: false,
    }
  },
  watch: {
    project: {
      handler: function () {
        this.assigned_milestones = this.project.milestones.slice()
      },
      immediate: true,
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      Promise.all([
        groupAPI.getMilestones(this.project.project_type.id),
        groupAPI.getMilestoneGroups(this.project.project_type.id),
      ]).then(([milestones, milestoneGroups]) => {
        this.milestones = milestones.data
        this.milestone_groups = milestoneGroups.data
      }).catch(error => {
        console.log(error)
      })
    },
    getMilestones () {
      let promise
      if (this.milestone_group && this.milestone_group.id) {
        promise = groupAPI.getMilestoneGroup(this.project.project_type.id, this.milestone_group.id)
      } else {
        promise = groupAPI.getMilestones(this.project.project_type.id)
      }
      promise.then(response => {
        if (this.milestone_group && this.milestone_group.id) {
          this.milestones = response.data.milestones
        } else {
          this.milestones = response.data
        }
      }).catch(error => {
        console.log(error)
      })
    },
    save () {
      // update ord values
      for (let i = 0, len = this.assigned_milestones.length; i < len; i++) {
        this.assigned_milestones[i].ord = i + 1
      }
      projectAPI.updateAssignedMilestones(this.project.id, this.assigned_milestones).then(response => {
        this.saved = true
        this.modified = false
        this.assigned_index = ''
        this.selected_milestones = []
        this.$emit('reload-project')
      }).catch(error => {
        console.log(error)
      })
    },
    moveUp () {
      const i = this.assigned_index - 1
      if (i >= 0) {
        const am = this.assigned_milestones.splice(i, 2).reverse()
        this.assigned_milestones.splice(i, 0, ...am)
        this.assigned_index = i
        this.modified = true
        this.saved = false
      }
    },
    moveDown () {
      const i = this.assigned_index
      if (i < this.assigned_milestones.length - 1) {
        const am = this.assigned_milestones.splice(i, 2).reverse()
        this.assigned_milestones.splice(i, 0, ...am)
        this.assigned_index += 1
        this.modified = true
        this.saved = false
      }
    },
    selectAll () {
      this.selected_milestones = this.milestones
    },
    deleteMilestone () {
      this.$delete(this.assigned_milestones, this.assigned_index)
      this.modified = true
      this.saved = false
    },
    deleteAll () {
      this.assigned_milestones = []
      this.modified = true
      this.saved = false
    },
    addMilestone () {
      for (const milestone of this.selected_milestones) {
        if (this.assigned_milestones.findIndex(am => am.milestone.id === milestone.id) < 0) {
          this.assigned_milestones.push({ milestone: milestone })
          this.modified = true
          this.saved = false
        }
      }
      this.selected_milestones = []
    },
    goBack () {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped>
.tick-icon {
  padding-left: 5px;
  color: green;
}
.tick-icon label {
  padding-left: 5px;
}
</style>
