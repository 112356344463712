<template>
  <span :style="{ 'white-space': whiteSpace }">{{ displayProp }}</span>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line
    property: {},
    schema: {
      type: Object,
      required: true,
    },
  },
  computed: {
    whiteSpace () {
      if (this.schema.type === 'string' && this.schema['ui:widget'] && this.schema['ui:widget'] === 'textarea') {
        return 'pre-wrap'
      }
      return 'normal'
    },
    displayProp () {
      if (Array.isArray(this.property)) {
        return this.property.join(', ')
      }
      return this.property
    },
  },
}
</script>
