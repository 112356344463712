<template>
  <Modal
    ref="modal"
    title="Update Team"
  >
    <BaseForm
      submit-title="Save"
      @submit="save"
      @cancel="cancel"
    >
      <BaseSelect
        v-model="team"
        :options="teams"
        title="Team"
        name="team"
        placeholder="Select Team"
        required
      />
      <FieldError v-model="error" />
    </BaseForm>
  </Modal>
</template>

<script>
/**
 * Modal to set the team for a Project.
 * Emits the 'team-update' even if successful.
 */
import Modal from '@/components/Modal'
import FieldError from '@/components/FieldError'
import teamAPI from '@/api/team'
import projectAPI from '@/api/project'

export default {
  components: {
    Modal,
    FieldError,
  },
  data () {
    return {
      error: '',
      project: {},
      team: null,
      teams: [],
    }
  },
  beforeMount () {
    this.getTeams()
  },
  methods: {
    getTeams () {
      teamAPI.getTeamNames().then(resp => {
        this.teams = resp
      }).catch(error => {
        this.error = error.message
      })
    },
    reset () {
      this.error = ''
      this.project = {}
      this.team = null
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    edit (project) {
      this.reset()
      this.project = project
      this.team = project.team
      this.$refs.modal.show()
    },
    save () {
      projectAPI.updateTeam(this.project.id, this.team).then(resp => {
        this.$emit('team-update')
        this.cancel()
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
