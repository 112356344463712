<template>
  <Modal
    ref="modal"
    title="Services Catalogue"
    scrollable
    extra-large
  >
    <!-- Search Box -->
    <template #header-extension>
      <div
        class="d-flex mx-3 w-100"
        style="max-width: 40%; min-width: 230px;"
      >
        <!-- Icon -->
        <div class="d-flex align-items-center mr-2">
          <font-awesome-icon
            icon="search"
            fixed-width
            :class="searchInput.length > 0 ? 'text-primary' : 'text-secondary'"
          />
        </div>
        <!-- Input -->
        <input
          v-model="searchInput"
          style="font-size: 16px;"
          placeholder="Search services..."
          class="form-control"
          :class="searchInput.length > 0 ? 'text-primary border-primary' : ''"
        >
      </div>
    </template>
    <!-- Done Button -->
    <template #header-action>
      <button
        class="btn btn-lg btn-primary"
        @click="$refs.modal.hide"
      >
        Done
      </button>
    </template>
    <div class="d-flex">
      <!-- Service Categories Menu -->
      <div class="border-right">
        <!-- Menu Options -->
        <div
          v-for="[option, services] of platformGroups"
          :key="option"
          style="cursor: pointer;"
          @click="selectedPlatform = option"
        >
          <!-- Menu Option -->
          <div
            class="d-flex px-3 py-2"
            :class="selectedPlatform === option ? 'bg-light border-info' : 'border-white'"
            style="border-right-width: thick; border-right-style: solid;"
          >
            <!-- Title -->
            <div
              class="flex-grow-1 pr-4 text-nowrap"
              :class="selectedPlatform === option ? 'text-info font-weight-bold' : 'text-black-50'"
            >
              {{ option }}
            </div>
            <!-- Items Count -->
            <div
              class="d-flex align-items-center px-2 rounded-pill text-black-50  text-right"
              style="background-color: #e9e9e9;"
            >
              {{ services.length }}
            </div>
          </div>
        </div>
      </div>
      <!-- Service Items List -->
      <div
        class="flex-grow-1 overflow-auto px-5"
      >
        <div
          v-for="item in selectedPlatformItems"
          :key="item.id"
        >
          <ServiceCatalogItem
            :item="item"
            :show-price="showPrices"
          >
            <button
              class="btn btn-info my-auto"
              style="min-width: 102px; max-width: 102px;"
              @click="addItemWithNotice(item)"
            >
              Add Service
            </button>
          </ServiceCatalogItem>
        </div>
      </div>
    </div>
    <ConfirmDialog
      ref="dialog"
      confirm-only
      @confirmed="addItem"
    />
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
import Enumerable from 'linq'
import ServiceCatalogItem from './CatalogItem'
import ConfirmDialog from '@/components/ConfirmDialog'

export default {
  components: {
    ServiceCatalogItem,
    Modal,
    ConfirmDialog,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
    showPrices: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      searchInput: '',
      selectedPlatform: 'All',
    }
  },
  computed: {
    // TODO: replace this hack dependant on platform field
    titleProperty () {
      // use group platform field to group items in menu if any of the platform fields have been filled
      return (this.groups.findIndex(g => !!g.platform) >= 0) ? 'platform' : 'name'
    },
    // Map group id to title, ignoring disabled groups
    groupIdToTitle () {
      return this.groups
        .filter(g => g.max !== 0)
        .reduce((acc, curr) => {
          acc[curr.id] = curr[this.titleProperty] || 'Others'
          return acc
        }, {})
    },
    postSearchItems () {
      return this.items.filter(item => {
        // ignore disabled groups
        if (!this.groupIdToTitle[item.service.service_group_id]) return false
        // Apply text search filtering
        if (this.searchInput.length === 0) return true
        const search = this.searchInput.toLowerCase()
        return item.service.name.toLowerCase().includes(search) ||
                        item.service.descr.toLowerCase().includes(search)
      })
    },
    platformGroups () {
      const opts = [['All', this.postSearchItems]]
        .concat(
          Enumerable
            .from(this.postSearchItems)
            .groupBy(item => this.groupIdToTitle[item.service.service_group_id])
            .select(group => [group.key(), group.toArray()])
            .orderBy(group => group[0])
            .toArray(),
        )
      return new Map(opts)
    },
    selectedPlatformItems () {
      return this.platformGroups.get(this.selectedPlatform)
    },
  },
  watch: {
    searchInput (value, oldValue) {
      if (oldValue.length === 0 && value.length > 0) {
        this.selectedPlatform = 'All'
      }
    },
  },
  methods: {
    show () {
      this.$refs.modal.show()
    },
    addItemWithNotice (item) {
      if (item.service.notification) {
        this.$refs.dialog.confirm(
          '',
          item.service.notification,
          item,
        )
      } else {
        this.addItem(item)
      }
    },
    addItem (item) {
      this.$emit('add-item', item)
    },
  },
}
</script>
