<template>
  <div
    class="d-flex flex-column flex-grow-1"
    style="min-height: 0;"
  >
    <ListTemplate
      v-if="subTypes && subTypes.length > 0 "
      ref="listTemplate"
      :fields="fields"
      :update-action="false"
      :api-url="apiURL"
      :per-page="20"
      :default-filters="defaultFilters"
      default-sort="start,id"
      :title="`${bookingType.title} Bookings`"
      csv-download="bookings.csv"
      select-columns
      :select-columns-key="selectColumnsKey"
    >
      <template #list-header>
        <slot name="booking-header" />
      </template>
      <template #project_id="{ rowData }">
        <router-link
          v-if="rowData.project_id"
          v-tooltip="{
            content: rowData.project_name,
            placement: 'top-center',
            classes: ['proj-name'],
            offset: '5',
          }"
          :to="projectLink(rowData)"
        >
          {{ rowData.project_id }}
        </router-link>
        <div v-else>
          {{ rowData.project_name }}
        </div>
      </template>
      <template #booking_status="{ rowData }">
        <span>
          {{ rowData.booking_status }}
        </span>
        <EditIcon
          v-if="canApprovePending(rowData)"
          title="Approve"
          class="table-icon"
          @click="approvePending(rowData)"
        />
        <CancelIcon
          v-else-if="canCancel(rowData)"
          title="Cancel"
          class="table-icon"
          @click="confirmCancel(rowData)"
        />
      </template>
      <template #review_status="{ rowData }">
        <span v-if="!rowData.review_status || rowData.booking_status === 'pending'">N/A</span>
        <span v-else>
          <span
            class="clickable"
            @click="$refs.pbreviewlist.show(rowData.id)"
          >
            {{ rowData.review_status }}
            <template v-if="rowData.review_count > 1">({{ rowData.review_count }})</template>
          </span>
          <EditIcon
            v-if="canReview(rowData)"
            title="Review"
            class="table-icon"
            @click="reviewBooking(rowData)"
          />
        </span>
      </template>
      <template #child_review_status="{ rowData }">
        <span v-if="!rowData.child_review_status || rowData.booking_status === 'pending'">N/A</span>
        <span v-else>
          <span
            class="clickable"
            @click="$refs.pbreviewlist.show(rowData.child_id)"
          >
            {{ rowData.child_review_status }}
            <template v-if="rowData.child_review_count > 1">({{ rowData.child_review_count }})</template>
          </span>
          <EditIcon
            v-if="canReview(childBooking(rowData))"
            title="Review"
            class="table-icon"
            @click="reviewBooking(childBooking(rowData))"
          />
        </span>
      </template>
      <template
        v-for="f in customFields"
        #[f.name]="{ rowData }"
      >
        <a
          v-if="isUrl(f.name, rowData)"
          :key="`link_${f.name}`"
          target="_blank"
          rel="noopener noreferrer"
          :href="rowData.data[f.name]"
          :title="rowData.data[f.name]"
        >
          <LinkIcon
            class="link-icon"
            :title="rowData.data[f.name]"
          />
        </a>
        <span
          v-else
          :key="`span_${f.name}`"
        >
          {{ formatCustomData(rowData.data[f.name]) }}
        </span>
        <EditIcon
          v-if="canEditField(f.name, rowData)"
          :key="f.name"
          :title="`Edit ${f.title}`"
          class="table-icon"
          @click="editField(f.name, rowData)"
        />
      </template>
    </ListTemplate>
    <FieldError
      v-model="error"
      class="mt-2"
    />
    <ProjectBookingStatus
      ref="pbs"
      @updated="refreshTable"
    />
    <ProjectBookingPropEdit
      ref="pbprop"
      @updated="refreshTable"
    />
    <ConfirmDialog
      ref="dialog"
      @confirmed="cancelBooking"
    />
    <ProjectBookingReviewTimeline ref="pbreviewlist" />
    <ProjectBookingReview
      ref="pbr"
      :subtypes="subTypes"
      @updated="refreshTable"
    />
  </div>
</template>

<script>
import FieldError from '@/components/FieldError'
import ListTemplate from '@/components/ListTemplate'
import ConfirmDialog from '@/components/ConfirmDialog'
import ProjectBookingReview from '@/views/Booking/ProjectBookingReview'
import ProjectBookingStatus from '@/views/Booking/ProjectBookingStatus'
import ProjectBookingPropEdit from '@/views/Booking/ProjectBookingPropEdit'
import ProjectBookingReviewTimeline from '@/views/Booking/ProjectBookingReviewTimeline'
import CancelIcon from 'vue-material-design-icons/CloseBox.vue'
import EditIcon from 'vue-material-design-icons/PencilBox.vue'
import LinkIcon from 'vue-material-design-icons/Link.vue'
import bookingAPI from '@/api/booking'
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    CancelIcon,
    EditIcon,
    LinkIcon,
    ConfirmDialog,
    FieldError,
    ProjectBookingReview,
    ProjectBookingStatus,
    ProjectBookingPropEdit,
    ProjectBookingReviewTimeline,
    ListTemplate,
  },
  props: {
    projectId: {
      type: [String, Number],
      default: null,
    },
    bookingTypeId: {
      type: [String, Number],
      required: true,
    },
    defaultFilters: {
      type: Array,
      default: () => [
        {
          // ignore closed states by default unless state or id set
          params: { complete: 'false' },
          fields: ['booking_status', 'project_id', 'booking_subtype_name', 'review_status', 'child_review_status'],
        },
      ],
    },
  },
  data () {
    return {
      error: '',
      bookingType: {
        name: '',
        title: '',
        project_type_id: '',
      },
      subTypes: [],
      reviewStatuses: [],
    }
  },
  computed: {
    apiURL () {
      if (this.projectId) {
        return `/projects/${this.projectId}/booking_types/${this.bookingTypeId}`
      }
      return '/booking_types/' + this.bookingTypeId + '/bookings'
    },
    isBookingAdmin () {
      return this.isAdmin || this.isGroupAdmin(this.bookingType.project_type_id)
    },
    priDateTitle () {
      if (this.showChild) {
        return this.bookingType.name + ' Date'
      }
      return 'Date'
    },
    priSubTypes () {
      return this.subTypes.filter(t => !t.child)
    },
    childSubTypes () {
      return this.subTypes.filter(t => t.child)
    },
    priReviewStatuses () {
      return this.reviewStatuses.filter(rs =>
        this.priSubTypes.findIndex(bs => rs.booking_subtype_id === bs.id) >= 0,
      )
    },
    childReviewStatuses () {
      return this.reviewStatuses.filter(rs =>
        this.childSubTypes.findIndex(bs => rs.booking_subtype_id === bs.id) >= 0,
      )
    },
    priSubTypeNames () {
      return this.priSubTypes.map(st => st.name)
    },
    showChild () {
      return this.childSubTypes.length > 0
    },
    showPrimaryTime () {
      return this.priSubTypes.reduce((acc, curr) => acc || curr.show_time, false)
    },
    showChildTime () {
      return this.childSubTypes.reduce((acc, curr) => acc || curr.show_time, false) && this.showChild
    },
    showPrimaryReviewer () {
      // show primary review column only if there are review statuses for it
      return this.priReviewStatuses.length > 0
    },
    showChildReviewer () {
      // show child review column only if there are review statuses for it
      return this.childReviewStatuses.length > 0
    },
    showSlot () {
      return this.priSubTypes.reduce((acc, curr) => acc || curr.show_slot, false)
    },
    showChildSlot () {
      return this.childSubTypes.reduce((acc, curr) => acc || curr.show_slot, false) && this.showChild
    },
    reviewFilterOptions () {
      return this.reviewStatuses.map(rs => rs.name)
        .filter((v, index, arr) => arr.indexOf(v) === index)
    },
    selectColumnsKey () {
      if (this.projectId) {
        return `project:${this.bookingTypeId}`
      }
      return `${this.bookingTypeId}`
    },
    customFields () {
      // get schema properties for each subtype
      let props = this.subTypes.map(st => (st.schema && st.schema.properties) || {})
      // merge into one object
      props = Object.assign({}, ...props)
      // create custom fields objects
      return Object.keys(props).map(k => {
        return {
          name: k,
          title: props[k].title,
          shortTitle: props[k].shortTitle,
          position: props[k]['ui:order'],
        }
      }).sort((a, b) => a.position - b.position)
    },
    fields () {
      const fl = [
        {
          name: 'project_id',
          title: 'Project',
          filterType: Number,
          visible: !this.projectId,
        },
        {
          name: 'project_name',
          title: 'Project Name',
          visible: false,
        },
        {
          name: 'requester_name',
          title: 'Requester',
          visible: false,
        },
      ]
      if (this.showChild) {
        fl.push({
          name: 'child_start',
          title: 'Review Date',
          dataClass: 'text-nowrap',
          sortField: 'child_start',
          sortAppend: { sortField: 'child_slot', direction: 'asc' },
          filterType: Date,
          formatter: this.formatDate,
        })
      }
      if (this.showChildTime) {
        fl.push({
          name: 'booking',
          title: 'Review Time',
          dataClass: 'text-nowrap',
          rowFormatter: this.formatChildTime,
        })
      }
      if (this.showChildSlot) {
        fl.push({
          name: 'child_slot',
          title: 'Slot',
          formatter: this.formatSlot,
        })
      }
      fl.push({
        name: 'start',
        title: this.priDateTitle,
        dataClass: 'text-nowrap',
        sortField: 'start',
        filterType: Date,
        formatter: this.formatDate,
      })
      if (this.showPrimaryTime) {
        fl.push({
          name: 'booking',
          title: 'Time',
          dataClass: 'text-nowrap',
          rowFormatter: this.formatTime,
        })
      }
      if (this.showSlot) {
        fl.push({
          name: 'slot',
          title: 'Slot',
          formatter: this.formatSlot,
        })
      }
      fl.push({
        name: 'booking_status',
        title: 'Status',
        editFunc: this.approvePendingFunc,
        filterType: String,
        filterOptions: ['booked', 'pending', 'cancelled', 'rescheduled'],
      })
      fl.push({
        name: 'booking_subtype_name',
        title: 'Type',
        dataClass: 'text-nowrap',
        filterType: String,
        filterOptions: this.priSubTypeNames,
      })
      if (this.showPrimaryReviewer) {
        fl.push({
          name: 'review_status',
          title: 'Review Status',
          editFunc: this.reviewBookingFunc,
          filterType: String,
          filterOptions: this.reviewFilterOptions,
          csvRowFormatter: this.formatPendingField('review_status'),
        })
      }
      if (this.showChildReviewer) {
        fl.push({
          name: 'child_review_status',
          title: 'Review Status',
          editFunc: this.reviewBookingFunc,
          filterType: String,
          filterOptions: this.reviewFilterOptions,
          csvRowFormatter: this.formatPendingField('child_review_status'),
        })
      }
      this.customFields.forEach(v => {
        const newField = {
          name: v.name,
          title: v.title,
          editFunc: this.editFieldFunc,
          titleClass: 'text-nowrap',
          csvRowFormatter: this.formatCustomField(v.name),
        }
        if (v.shortTitle) {
          newField.shortTitle = v.shortTitle
        }
        if (v.position <= 0 || v.position > fl.length) {
          fl.push(newField)
        } else if (v.position > 0) {
          fl.splice(v.position - 1, 0, newField)
        }
      })
      fl.push({
        name: 'detailLink',
        title: '',
      })
      return fl
    },
    ...mapGetters('user', ['isAdmin', 'isGroupAdmin', 'isRole']),
    ...mapState('user', ['currentUser']),
  },
  watch: {
    '$route': function () {
      this.getData()
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      Promise.all([
        bookingAPI.getBookingSubtypes(this.bookingTypeId),
        bookingAPI.getBookingType(this.bookingTypeId),
        bookingAPI.getReviewStatuses(this.bookingTypeId),
      ]).then(([subTypes, bookingType, reviewStatuses]) => {
        this.subTypes = subTypes.data
        this.bookingType = bookingType.data
        this.reviewStatuses = reviewStatuses.data
      }).catch(error => {
        console.log(error)
      })
    },
    refreshTable () {
      this.$refs.listTemplate.refresh()
    },
    formatSlot (s) {
      return s || 'N/A'
    },
    formatDate (d) {
      if (!d) { return 'N/A' }
      return moment(d).utc().format('ddd D MMM')
    },
    formatTime (b) {
      if (!b.start || !b.finish) {
        return ''
      }
      const ms = moment(b.start).utc()
      const mf = moment(b.finish).utc()
      // don't show time if start & finish not on the same day
      if (!ms.isSame(mf, 'day')) {
        return ''
      }
      const st = ms.format('h:mm')
      const ft = mf.format('h:mm')
      let sap = ms.format('a')
      const fap = mf.format('a')
      if (sap === fap) {
        sap = ''
      }
      return st + sap + ' - ' + ft + fap
    },
    formatChildTime (b) {
      if (!b.child_start || !b.child_finish) {
        return ''
      }
      const ms = moment(b.child_start).utc()
      const mf = moment(b.child_finish).utc()
      // don't show time if start & finish not on the same day
      if (!ms.isSame(mf, 'day')) {
        return ''
      }
      if (!b.child_slot || !b.child_booking_subtype_show_time) {
        return 'N/A'
      }
      const st = ms.format('h:mm')
      const ft = mf.format('h:mm')
      let sap = ms.format('a')
      const fap = mf.format('a')
      if (sap === fap) {
        sap = ''
      }
      return st + sap + ' - ' + ft + fap
    },
    formatCustomData (v) {
      return Array.isArray(v) ? v.join(', ') : v
    },
    formatCustomField (field) {
      return rowData => rowData.data[field]
    },
    formatPendingField (field) {
      return rowData => rowData.booking_status === 'pending' ? 'N/A' : rowData[field]
    },
    childBooking (b) {
      const rb = {
        project_id: b.project_id,
        project_type_id: b.project_type_id,
        requester_id: b.requester_id,
      }
      Object.keys(b)
        .filter(k => k.startsWith('child_'))
        .forEach(k => { rb[k.slice(6)] = b[k] })
      return rb
    },
    isRequester (b) {
      return this.currentUser.id === b.requester_id
    },
    isReviewer (b) {
      return this.isRole(b.booking_reviewer_role_id)
    },
    isReviewerAny (b) {
      return this.isRole(b.booking_reviewer_role_id) ||
        this.isRole(b.child_booking_reviewer_role_id)
    },
    bookingReviewStatuses (b) {
      if (!b.review_next_statuses) {
        return []
      }
      return this.reviewStatuses.filter(s =>
        s.booking_subtype_id === b.booking_subtype_id &&
        b.review_next_statuses.includes(s.name) &&
        (
          this.isBookingAdmin || this.isReviewer(b) || (s.user_state && this.isRequester(b))
        ),
      )
    },
    canReview (b) {
      return this.bookingReviewStatuses(b).length > 0
    },
    reviewBooking (b) {
      this.$refs.pbr.edit(b, this.bookingReviewStatuses(b))
    },
    reviewBookingFunc (f, b) {
      if (f.name.startsWith('child_')) {
        b = this.childBooking(b)
      }
      if (this.canReview(b)) {
        return () => this.reviewBooking(b)
      }
      return null
    },
    canCancel (b) {
      // requesters can cancel their own booking. Ignore timezone when comparing start times.
      return b.booking_status !== 'cancelled' &&
        (this.isRequester(b) || this.isReviewerAny(b) || this.isBookingAdmin) &&
        (moment().isBefore(moment(b.start, 'YYYY-MM-DDTHH:mm:ss')) ||
        (b.child_start && moment().isBefore(moment(b.child_start, 'YYYY-MM-DDTHH:mm:ss'))))
    },
    canApprovePending (b) {
      // Approvers can cancel 'pending' bookings
      const subType = this.subTypes.find(s => s.id === b.booking_subtype_id)
      return Boolean(
        b.booking_status === 'pending' &&
        subType &&
        subType.approver_role &&
        (this.isRole(subType.approver_role.id) || this.isBookingAdmin),
      )
    },
    approvePending (b) {
      this.$refs.pbs.edit(b)
    },
    approvePendingFunc (f, b) {
      if (this.canApprovePending(b)) {
        return () => this.approvePending(b)
      }
      return null
    },
    isUrl (f, b) {
      const subType = this.subTypes.find(st => st.id === b.booking_subtype_id)
      return b.data[f] && subType && subType.schema && subType.schema.properties &&
        subType.schema.properties[f] && subType.schema.properties[f].format === 'uri'
    },
    isFieldLocked (f, b, subType) {
      return Boolean(b.review_field_lock &&
        subType && subType.schema &&
        subType.schema['review:required'] &&
        subType.schema['review:required'].includes(f))
    },
    canEditField (f, b) {
      // TODO: remove hardcoded reference to 'Standard CR'
      const subType = this.subTypes.find(st => st.id === b.booking_subtype_id)
      return (this.isRequester(b) || this.isReviewerAny(b) || this.isBookingAdmin) &&
        !this.isFieldLocked(f, b, subType) &&
        b.booking_status !== 'cancelled' &&
        (!b.complete || b.booking_subtype_name === 'Standard CR') &&
        subType && subType.schema && subType.schema.properties &&
        subType.schema.properties[f] && !subType.schema.properties[f].readOnly
    },
    editField (f, b) {
      const subType = this.subTypes.find(st => st.id === b.booking_subtype_id)
      const field = {
        name: f,
        schema: subType.schema.properties[f],
        required: subType.schema.required && subType.schema.required.includes(f),
      }
      this.$refs.pbprop.edit(field, b)
    },
    editFieldFunc (f, b) {
      if (this.canEditField(f.name, b)) {
        return () => this.editField(f.name, b)
      }
      return null
    },
    confirmCancel (b) {
      this.$refs.dialog.confirm(
        'Confirm Booking Cancellation',
        'Are you sure you want to cancel?',
        b,
      )
    },
    cancelBooking (b) {
      bookingAPI.updateBookingStatus(b.id, 'cancelled').then(resp => {
        this.refreshTable()
      }).catch(error => {
        this.error = error.message
      })
    },
    projectLink (b) {
      return b.project_id ? { name: 'show_project_info', params: { projectId: b.project_id } } : ''
    },
  },
}
</script>

<style lang="scss" scoped>
div ::v-deep .table tbody {
  .link-icon {
    color: $primary;
    font-size: 1.25rem;
    bottom: 0.125em;
    float: left;
  }
  .table-icon {
    visibility: hidden;
    color: $primary;
    font-size: 1.25rem;
    bottom: 0.125em;
    float: right;

    &:hover {
      cursor: pointer;
    }
  }
  tr:hover {
    background-color:rgb(244, 244, 244);
    .table-icon {
      visibility: visible;
    }
  }
}
</style>
