<template>
  <ValidationProvider
    v-slot="{ errors }"
    :rules="calcRules"
    :name="title"
    slim
  >
    <FormGroup
      :title="title"
      :error="errors[0]"
      :required="required && !readonly"
      :horizontal="horizontal"
    >
      <input
        v-if="readonly"
        :value="formatDateTime(value)"
        type="text"
        readonly
        class="form-control-plaintext"
      >
      <DatePicker
        v-else
        v-model="innerValue"
        type="datetime"
        :name="name"
        :input-class="{ 'mx-input': true, 'form-control': true, 'is-invalid': errors[0] }"
        :show-second="false"
        :clearable="false"
        :disabled-date="disabledDate"
        :disabled-time="disabledTime"
        format="YYYY-MM-DD HH:mm"
      >
        <template #icon-calendar>
          <div />
        </template>
      </DatePicker>
    </FormGroup>
  </ValidationProvider>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment'
import FormGroup from './FormGroup'
import { ValidationProvider, extend } from 'vee-validate'
import formatDateTime from '@/filters/formatDateTime'

extend('minDate', {
  validate: (value, { date }) => {
    if (!value || !date) { return true }
    const v = moment(value, 'YYYY-MM-DDTHH:mm:ss')
    const d = moment(date, 'YYYY-MM-DDTHH:mm:ss')
    if (!v.isValid() || !d.isValid() || !v.isBefore(d)) return true
    return 'The {_field_} must be after ' + d.format('YYYY-MM-DD HH:mm')
  },
  params: ['date'],
})

extend('maxDate', {
  validate: (value, { date }) => {
    if (!value || !date) { return true }
    const v = moment(value, 'YYYY-MM-DDTHH:mm:ss')
    const d = moment(date, 'YYYY-MM-DDTHH:mm:ss')
    if (!v.isValid() || !d.isValid() || !v.isAfter(d)) return true
    return 'The {_field_} must be before ' + d.format('YYYY-MM-DD HH:mm')
  },
  params: ['date'],
})

export default {
  components: {
    DatePicker,
    ValidationProvider,
    FormGroup,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    rules: {
      type: Object,
      default () {
        return {}
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerValue: {
      get () { return this.strToDate(this.value) },
      set (date) { this.$emit('input', this.dateToStr(date)) },
    },
    calcRules () {
      if (this.readonly) {
        return {}
      }
      const r = Object.assign({ required: this.required }, this.rules)
      if (this.min) {
        r.minDate = this.min
      }
      if (this.max) {
        r.maxDate = this.max
      }
      return r
    },
    minTime () {
      return this.min ? new Date(this.strToDate(this.min)) : null
    },
    minDate () {
      if (!this.minTime) { return null }
      const d = new Date(this.minTime)
      d.setHours(0, 0, 0, 0)
      return d
    },
    maxTime () {
      return this.max ? new Date(this.strToDate(this.max)) : null
    },
    maxDate () {
      if (!this.maxTime) { return null }
      const d = new Date(this.maxTime)
      d.setHours(0, 0, 0, 0)
      return d
    },
  },
  methods: {
    strToDate (s) {
      // 2020-07-13T16:02:02Z
      const m = moment(s, 'YYYY-MM-DDTHH:mm:ss')
      return m.isValid() ? m.toDate() : null
    },
    dateToStr (date) {
      if (!date) {
        return null
      }
      return moment(date).format('YYYY-MM-DDTHH:mm:ssZ') || null
    },
    disabledTime (date) {
      if (this.minTime) { return date < this.minTime }
      if (this.maxTime) { return date > this.maxTime }
      return false
    },
    disabledDate (date) {
      if (this.minDate) { return date < this.minDate }
      if (this.maxDate) { return date > this.maxDate }
      return false
    },
    formatDateTime,
  },
}
</script>

<style lang="scss">
.form-group .mx-datepicker {
  width: 100%;
}
</style>
