<template>
  <Modal
    ref="modal"
    :title="title"
  >
    <BaseForm
      :submit-title="submitTitle"
      :submit-promise="submit"
      @cancel="cancel"
    >
      <BaseSelect
        v-if="!hasOnlyOtherType"
        v-model="attachment_type"
        :options="attachment_types"
        :readonly="attachment_type_fixed"
        title="Attachment Type"
        name="attachment_type"
        track-by="name"
        label="name"
        required
      />
      <div
        v-if="!hasOnlyOtherType"
        class="form-group"
      >
        <a
          v-if="attachment_type && attachment_type.template_url"
          target="_blank"
          rel="noopener noreferrer"
          :href="attachment_type.template_url"
        >
          Download {{ attachment_type.name }} Template
        </a>
        <a
          v-else-if="dowloadURL"
          :href="dowloadURL"
        >
          Download {{ attachment_type.name }} Template
        </a>
      </div>
      <BaseInput
        v-if="attachment_type.name === 'Other'"
        v-model="attachment_type_name"
        title="Description"
        name="attachment_type_name"
        type="text"
        required
        trim
      />
      <BaseInputFile
        v-model="file"
        :max-size="maxFileSize"
        title="File"
        name="file"
        type="file"
        required
      />
      <FieldError v-model="error" />
    </BaseForm>
  </Modal>
</template>

<script>
/**
 * Modal to attach a file to a project.
 * Emit's a 'file-upload' event when successful.
 */
import Modal from '@/components/Modal'
import FieldError from '@/components/FieldError'
import { mapState } from 'vuex'
import groupAPI from '@/api/group'

export default {
  components: {
    Modal,
    FieldError,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    submitTitle: {
      type: String,
      required: true,
    },
    submitPromise: {
      type: Function,
      required: true,
    },
    financialTypes: {
      type: Boolean,
      default: false,
    },
    // project to attach file to
    project: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      file: null,
      attachment_type_fixed: false,
      attachment_type: '',
      attachment_type_name: '',
      attachment_types: [],
      project_type_id: '',
      error: '',
    }
  },
  computed: {
    maxFileSize () {
      return this.currentUser.max_attachment_size_mb * 1024
    },
    dowloadURL () {
      if (this.attachment_type && this.attachment_type.template_filename) {
        return process.env.VUE_APP_API_URL + groupAPI.attachmentTypeTemplateDownloadURL(this.attachment_type.id)
      }
      return ''
    },
    hasOnlyOtherType () {
      return this.attachment_types.length === 1 && this.attachment_types[0].name === 'Other'
    },
    ...mapState('user', ['currentUser']),
  },
  methods: {
    getData (typeID) {
      const ptID = this.project.project_type.id
      if (!ptID) {
        this.attachment_types = []
        return
      }
      if (ptID === this.project_type_id) {
        this.setAttachmentType(typeID)
        return
      }
      groupAPI.getAttachmentTypes(ptID).then(resp => {
        if (this.financialTypes) {
          this.attachment_types = resp.data.filter(t => t.is_user_type && t.is_financial)
        } else {
          this.attachment_types = resp.data.filter(t => t.is_user_type && !t.is_financial)
        }
        this.error = ''
        this.project_type_id = ptID
        this.setAttachmentType(typeID)
      }).catch(error => {
        this.error = error.message
      })
    },
    setAttachmentType (typeID) {
      const at = this.attachment_types.find(t => t.id === typeID)
      if (at) {
        this.attachment_type = at
        this.attachment_type_fixed = true
      } else if (this.hasOnlyOtherType) {
        // TODO: remove 'Other' naming dependency and add boolean field to attachment type
        this.attachment_type = this.attachment_types[0]
      }
    },
    reset () {
      this.file = null
      this.attachment_type_fixed = false
      this.attachment_type = ''
      this.attachment_type_name = ''
      this.error = ''
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    /**
     * @description open modal and attachments
     * @param {Object} typeID - id for group to which get possible attachment types from
     */
    show (typeID) {
      this.reset()
      this.getData(typeID)
      this.$refs.modal.show()
    },
    async submit () {
      let partName = this.attachment_type.name
      if (partName === 'Other' && this.attachment_type_name) {
        partName = this.attachment_type_name
      }
      partName = this.attachment_type.id + '_' + partName
      await this.submitPromise({ file: this.file, type_name: partName })
      this.cancel()
    },
  },
}
</script>
