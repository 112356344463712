<template>
  <div class="container">
    <div class="login-form">
      <form
        method="post"
        action=""
        role="login"
      >
        <h3>Motion</h3>
        <slot />
        <p
          v-if="error"
          class="login-form-error"
          v-text="error"
        />
        <button
          :disabled="submitting || disableSubmit"
          class="btn btn-lg btn-primary btn-block"
          type="submit"
          @click.prevent="submitForm"
        >
          <div
            v-if="submitting"
            class="spinner-border"
          />
          <span v-else>
            {{ submitLabel }}
          </span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disableSubmit: {
      type: Boolean,
      default: false,
    },
    submitLabel: {
      type: String,
      required: true,
    },
    submit: {
      type: Function,
      required: true,
    },
    errorMsg: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      submitting: false,
      error: this.errorMsg,
    }
  },
  methods: {
    submitForm () {
      this.error = ''
      this.submitting = true
      Promise.resolve(this.submit()).then(resp => {
        this.submitting = false
      }).catch(error => {
        this.error = error.message
        this.submitting = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  outline: none;
}
.login-form {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 100px auto;
  max-width: 400px;

  p {
    text-align: center;
    color: #fff;
  }
  h3 {
    text-align: center;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .login-form-error {
    color: $red;
  }
  form[role=login] {
    color: #cacaca;
    background: $layout-primary-color;
    padding: 26px;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;

    input,
    button {
      font-size: 16px;
      margin: 16px 0;
    }
    button {
      background-color: #486179;
    }
  }
}
</style>
