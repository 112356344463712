import axios from 'axios'
import router from '@/router'
import qs from 'qs'

// axios instance that redirects to login page if it gets an authentication error on api requests
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  },
})

instance.interceptors.response.use(undefined, function (error) {
  const response = error.response
  if (response) {
    // change format of error payload
    if (response.data && response.data.error) {
      error.message = response.data.error
    } else if (response.data && response.data.message) {
      error.message = response.data.message
    } else if (response.statusText) {
      error.message = response.statusText
    }
    if (response.status === 401 && router.currentRoute.name !== 'login') {
      router.push({
        name: 'login',
        params: { redirect: router.currentRoute.fullPath, errorMsg: error.message },
      })
    }
  }
  return Promise.reject(error)
})

export default instance
