<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-if="disableMarkdown"
    class="text-gray-600 my-3"
    style="white-space: pre-wrap;"
    v-text="info"
  />
  <div
    v-else
    class="service-info text-gray-600 pt-3"
    v-html="infoMarkdown"
  />
</template>

<script>
import marked from 'marked'

export default {
  props: {
    info: {
      type: String,
      required: true,
    },
    disableMarkdown: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    infoMarkdown () {
      return marked(this.info)
    },
  },
}
</script>

<style lang="scss">
  .service-info {
    h1, h2, h3, h4, h5, h6 {
      // padding-top: 16px;
      font-size: 1em;
      font-weight: 700;
      color: #17a2b8;
    }
    & > ul {
      padding-left: 0;
    }
    details > *:not(summary) {
      padding-left: 10px;
    }
    li {
      padding-top: 4px;
      padding-left: 1em;
      text-indent: -1em;
    }
    li:before {
      content: "\2713";
      padding-right: 5px;
      color: #28a745;
      font-weight: 700;
    }
    ul {
      list-style: none;
      padding-left: 0px;
    }
  }
</style>
