<template>
  <div class="sidebar d-flex flex-column justify-content-between">
    <ul
      id="side-menu"
      class="nav"
    >
      <SidebarMenuItem
        v-if="reportGroups.length > 0"
        :route="{ name: 'dashboard' }"
        icon="tachometer-alt"
      />

      <SidebarSubmenu
        v-if="isAdmin || isGroupAdminAny"
        parent="side-menu"
        name="admin-menu"
        title="Admin"
        icon="user"
      >
        <SidebarMenuItem
          v-if="isAdmin || isManager"
          :route="{ name: 'team_list' }"
        />
        <SidebarMenuItem
          v-if="isAdmin"
          :route="{ name: 'user_list' }"
        />
        <SidebarMenuItem
          :route="{ name: 'groups' }"
        />
        <SidebarMenuItem
          v-if="isAdmin"
          :route="{ name: 'holiday_list' }"
        />
      </SidebarSubmenu>
      <SidebarMenuItem
        v-else-if="isManager"
        :route="{ name: 'team_list' }"
        icon="users"
      />

      <SidebarMenuItem
        :route="{ name: 'projects' }"
        icon="clipboard"
      />

      <SidebarSubmenu
        v-if="showReports"
        parent="side-menu"
        name="reports-menu"
        title="Report"
        icon="file-alt"
      >
        <template v-for="r in reports">
          <SidebarMenuItem
            v-if="!r.require_subcats || showSubcatReports"
            :key="r.name"
            :route="{ name: r.route }"
            :title="r.name"
          />
        </template>
      </SidebarSubmenu>

      <SidebarSubmenu
        v-if="bookingTypes && bookingGroups.length > 0"
        parent="side-menu"
        name="booking-menu"
        title="Review Bookings"
        icon="calendar"
      >
        <SidebarSubmenu
          v-for="(g, i) in bookingGroups"
          :key="'booking-' + g"
          parent="booking-menu"
          :name="'booking-submenu-' + i"
          :title="g"
        >
          <SidebarMenuItem
            v-for="r in bookingTypes[g]"
            :key="'booking-type-' + r.id"
            :route="{ name: 'bookings', params: { bookingTypeId: r.id } }"
            :title="r.title"
          />
        </SidebarSubmenu>
      </SidebarSubmenu>

      <SidebarMenuItem
        v-for="r in featureRoutes"
        :key="r.name"
        :route="{ name: r.name }"
      />
      <li
        v-if="devVersion"
        class="nav-item"
      >
        <a
          class="nav-link"
          href="http://10.102.33.86:3000/d/oHie0atMz/grx-traffic?orgId=1&kiosk=tv"
          target="_blank"
        >
          <font-awesome-icon
            icon="list-alt"
            fixed-width
          />
          Visibility Module
        </a>
      </li>
      <li
        v-if="devVersion"
        class="nav-item"
      >
        <a
          class="nav-link"
          href="http://unicorn-dev.internal.layer2.com.au:8000/search"
          target="_blank"
        >
          <font-awesome-icon
            icon="list-alt"
            fixed-width
          />
          Network Discovery
        </a>
      </li>

      <SidebarMenuItem
        :route="{ name: 'create_project' }"
        icon="plus-circle"
      />
    </ul>
    <img
      class="d-block mx-auto my-3 bg-white"
      src="@assets/img/Logo.png"
      style="border-radius: 24px; padding: 14px;"
      width="150px"
    >
  </div>
</template>

<script>
import SidebarSubmenu from '@/components/layout/SidebarSubmenu'
import SidebarMenuItem from '@/components/layout/SidebarMenuItem'
import { mapGetters, mapState } from 'vuex'
import bookingAPI from '@/api/booking'
import groupAPI from '@/api/group'

export default {
  components: {
    SidebarSubmenu,
    SidebarMenuItem,
  },
  data () {
    return {
      devVersion: (process.env.VUE_APP_VERSION.indexOf('.') === -1),
      groupReports: [
        { route: 'projects_duration_report' },
        { route: 'projects_duration_trend_report' },
        { route: 'general_financial_overview' },
        { route: 'platforms_summary' },
        { route: 'services_financial_overview', require_subcats: true },
        { route: 'week_report' },
        { route: 'year_report' },
      ],
      crReports: [
        { route: 'cr_report' },
      ],
      groups: [],
      bookingTypes: {},
    }
  },
  computed: {
    featureRoutes () {
      const mainRoute = this.$router.options.routes.find(r => r.path === '/')
      return mainRoute.children.filter(r => r.meta && r.meta.sidebar && (r.name !== 'cr_dashboard' || this.showCRDashboard))
    },
    reports () {
      if (this.showCRDashboard) {
        return [...this.groupReports, ...this.crReports]
      }
      return this.groupReports
    },
    bookingGroups () {
      return Object.keys(this.bookingTypes)
    },
    reportGroups () {
      return this.groups.filter(g =>
        this.currentUser.is_admin ||
        this.currentUser.roles.findIndex(
          r => (r.name === 'Report' || r.name === 'Admin') && r.project_type_id === g.id) >= 0,
      )
    },
    showReports () {
      return this.reportGroups.length > 0
    },
    showSubcatReports () {
      return this.reportGroups.findIndex(g => g.use_subcats) >= 0
    },
    showCRDashboard () {
      return this.currentUser.teams.findIndex(t => t.enable_cr_dash) >= 0
    },
    ...mapState('user', ['currentUser']),
    ...mapGetters('user', ['isAdmin', 'isGroupAdminAny', 'isManager']),
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      Promise.all([
        bookingAPI.getBookingTypes(),
        groupAPI.getGroups(),
      ]).then(([bookingTypes, groups]) => {
        this.groups = groups.data
        bookingTypes.data.forEach((v, i, arr) => {
          const pt = this.groups.find(g => g.id === v.project_type_id)
          if (!this.bookingTypes[pt.name]) {
            this.$set(this.bookingTypes, pt.name, [])
          }
          this.bookingTypes[pt.name].push(v)
        })
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.sidebar {
  background-color: $sidebar-background-color;
  position: fixed;
  width: $sidebar-width;
  top: $navbar-height;
  bottom: 0;
  overflow-y: hidden;
  // $zindex-fixed - 1
  z-index: 1029;
}

#side-menu ::v-deep {
  ul,
  li {
    width: 100%;
  }
  & > li.nav-item {
    border-bottom: 1px solid $sidebar-hover-color;
  }
  .nav-link {
    padding: 0.7em 1em;
    color: $sidebar-color;

    &:hover {
      background-color: $sidebar-hover-color;
    }
  }
  .nav .nav-link {
    padding-left: 2.5em;
  }
  .nav .nav .nav-link {
    padding-left: 3.5em;
  }
}
</style>
