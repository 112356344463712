<template>
  <LoginForm
    submit-label="Submit"
    :disable-submit="submitted"
    :submit="recover"
  >
    <p v-if="submitted">
      Account recovery request submitted
    </p>
    <p v-else>
      Enter email address for recovery message
    </p>
    <div class="form-group">
      <input
        v-model="email"
        class="form-control form-control-lg"
        type="text"
        placeholder="Email Address"
        autofocus
        required
      >
    </div>
  </LoginForm>
</template>

<script>
/**
 * View for requesting password reset email for account
 */
import LoginForm from './LoginForm'
import sessionApi from '@/api/session'

export default {
  components: {
    LoginForm,
  },
  data () {
    return {
      error: '',
      email: '',
      submitted: false,
    }
  },
  methods: {
    /* post api request for email with link to reset password */
    recover () {
      return sessionApi.requestRecoveryEmail(this.email).then(response => {
        this.submitted = true
      })
    },
  },
}
</script>
