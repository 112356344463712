import axios from 'axios'
import qs from 'qs'

// axios instance for unauthenticated requests
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  },
})

instance.interceptors.response.use(undefined, function (error) {
  const response = error.response
  if (response) {
    // change format of error payload
    if (response.data && response.data.error) {
      error.message = response.data.error
    } else if (response.data && response.data.message) {
      error.message = response.data.message
    } else if (response.statusText) {
      error.message = response.statusText
    }
  }
  return Promise.reject(error)
})

export default instance
