<template>
  <div>
    <ValidationProvider
      v-slot="{ errors }"
      :rules="calcRules"
      :name="title"
      :vid="name"
      slim
    >
      <FormGroup
        :title="title"
        :error="errors[0]"
        :required="required && !disabled"
        :horizontal="horizontal"
      >
        <input
          v-model="innerValue"
          :name="name"
          :placeholder="placeholder"
          :disabled="disabled"
          type="password"
          class="form-control"
          :class="{ 'is-invalid': errors[0] }"
        >
      </FormGroup>
    </ValidationProvider>
    <ValidationProvider
      v-if="confirm"
      v-slot="{ errors }"
      :rules="confirmRules"
      :name="confirmTitle"
      slim
    >
      <FormGroup
        :title="confirmTitle"
        :error="errors[0]"
        :required="required && !disabled"
        :horizontal="horizontal"
      >
        <input
          v-model="passwordConfirm"
          :name="confirmName"
          :placeholder="confirmPlaceholder"
          :disabled="disabled"
          type="password"
          class="form-control"
          :class="{ 'is-invalid': errors[0] }"
        >
      </FormGroup>
    </ValidationProvider>
  </div>
</template>

<script>
import FormGroup from './FormGroup'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    FormGroup,
    ValidationProvider,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    rules: {
      type: Object,
      default () {
        return {}
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    confirm: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      passwordConfirm: '',
    }
  },
  computed: {
    innerValue: {
      get () { return this.value },
      set (n) { this.$emit('input', n) },
    },
    confirmName () {
      return 'confirm-' + this.name
    },
    confirmTitle () {
      return this.title ? 'Confirm ' + this.title : this.title
    },
    confirmRules () {
      return this.value ? { required: true, confirmed: this.name } : {}
    },
    confirmPlaceholder () {
      return this.placeholder ? 'Confirm ' + this.placeholder : ''
    },
    calcRules () {
      return Object.assign({ required: this.required }, this.rules)
    },
  },
}
</script>
