import axios from '@/axios/default'

export default {
  teamsURL () { return '/teams' },
  getTeams () { return axios.get('/teams') },
  createTeam (t) { return axios.post('/teams', t) },
  teamURL (teamId) { return '/teams/' + teamId },
  getTeam (id) { return axios.get('/teams/' + id) },
  updateTeam (t) { return axios.put('/teams/' + t.id, t) },
  deleteTeam (t) { return axios.delete('/teams/' + t.id) },
  teamMembersURL (teamId) { return this.teamURL(teamId) + '/members' },
  createTeamMember (teamId, tm) { return axios.post(this.teamMembersURL(teamId), tm) },
  teamMemberURL (teamId, id) { return this.teamMembersURL(teamId) + '/' + id },
  getTeamMember (teamId, id) { return axios.get(this.teamMemberURL(teamId, id)) },
  updateTeamMember (tm) { return axios.put(this.teamMemberURL(tm.team_id, tm.id), tm) },

  getTeamMemberNames (teamId) {
    return axios.get(this.teamMembersURL(teamId)).then(r => {
      return Promise.resolve(r.data.map(m => m.usr.name))
    })
  },

  getTeamNames () {
    return axios.get('/teams').then(r => {
      return Promise.resolve(r.data.map(g => g.name))
    })
  },

  getTeamUsers (t) { return axios.get('/teams/' + t + '/users') },

  teamSurveyQuestionsURL (teamId) { return this.teamURL(teamId) + '/survey_questions' },
  createSurveyQuestion (teamId, q) { return axios.post(this.teamSurveyQuestionsURL(teamId), q) },
  getSurveyQuestions (teamId) { return axios.get(this.teamSurveyQuestionsURL(teamId), { params: { sort: 'ord' } }) },
  teamSurveyQuestionURL (teamId, id) { return this.teamSurveyQuestionsURL(teamId) + '/' + id },
  getSurveyQuestion (teamId, id) { return axios.get(this.teamSurveyQuestionURL(teamId, id)) },
  updateSurveyQuestion (q) { return axios.put(this.teamSurveyQuestionURL(q.team_id, q.id), q) },

  teamSurveyResultsURL (teamId) { return this.teamURL(teamId) + '/survey_results' },
}
