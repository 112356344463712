import Vue from 'vue'
import Router from 'vue-router'

import Login from '@/views/Auth/Login'
import PasswordChange from '@/views/Auth/PasswordChange'
import AccountRecover from '@/views/Auth/AccountRecover'
import AccountRecoverRequest from '@/views/Auth/AccountRecoverRequest'
import Main from '@/views/Main'
import NotFound from '@/views/NotFound'

// import main routes from separate .js files.
const requireRoutes = require.context('./', false, /\.js$/)
const mainRoutes = [
  {
    path: '',
    redirect: { name: 'projects' },
    /*
    beforeEnter (to, from, next) {
      if (to.fullPath.substr(0, 2) === '/#') {
        // redirect old hash mode paths to history mode path by removing the leading hash
        const path = to.fullPath.substr(2)
        next(path)
      } else if (to.path === '/') {
        next({ name: 'projects' })
      } else {
        next()
      }
    }
    */
  },
].concat(
  requireRoutes.keys().filter(f => f !== './index.js').reduce(
    (acc, f) => acc.concat(requireRoutes(f).default), [],
  ),
)

Vue.use(Router)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    props: true,
  },
  {
    path: '/password_change',
    name: 'password_change',
    component: PasswordChange,
    props: true,
  },
  {
    path: '/account/request_recover',
    component: AccountRecoverRequest,
  },
  {
    path: '/account/recover',
    component: AccountRecover,
  },
  {
    path: '/',
    component: Main,
    children: mainRoutes,
  },
  {
    path: '*',
    component: NotFound,
  },
]

const router = new Router({
  // mode: 'history',
  routes: routes,
})

export default router
