<template>
  <div
    class="d-flex flex-column"
    :style="$route.meta.shrink ? 'min-height: 0px;' : ''"
  >
    <h3>
      Project: {{ projectId }}
      <span
        v-if="project.project_state.is_start"
        class="text-danger"
      >
        ({{ project.project_state.name }})
      </span>
      <button
        v-if="canChangeHoldStatus"
        class="btn btn-primary btn-sm"
        @click="changeHoldStatus"
      >
        {{ onHoldTitle }}
      </button>
    </h3>
    <ul class="nav nav-tabs mb-3">
      <li
        class="nav-item"
        role="presentation"
      >
        <router-link
          :to="{ name: 'show_project_info' }"
          :class="{ 'active': $route.name.includes('show_project_info') }"
          class="nav-link"
        >
          Info
        </router-link>
      </li>
      <li
        v-if="showServices"
        class="nav-item"
        role="presentation"
      >
        <router-link
          :to="{ name: 'show_project_services' }"
          :class="{ 'active': $route.name.includes('services') }"
          class="nav-link"
        >
          Services
        </router-link>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <router-link
          :to="{ name: 'show_project_timeline' }"
          :class="{ 'active': $route.name.includes('show_project_timeline') }"
          class="nav-link"
        >
          Timeline
        </router-link>
      </li>
      <li
        v-if="showMilestones"
        class="nav-item"
        role="presentation"
      >
        <router-link
          :to="{ name: 'show_project_milestones' }"
          :class="{ 'active': $route.name.includes('milestones') }"
          class="nav-link"
        >
          Milestones
        </router-link>
      </li>
      <li
        v-if="showBookings"
        class="nav-item"
        role="presentation"
      >
        <router-link
          :to="{ name: 'show_project_bookings' }"
          :class="{ 'active': $route.name.includes('booking') }"
          class="nav-link"
        >
          Bookings
        </router-link>
      </li>
      <li
        v-if="canChangeFinancials"
        class="nav-item"
        role="presentation"
      >
        <router-link
          :to="{ name: 'project_financials_submit' }"
          :class="{ 'active': $route.name.includes('financials') }"
          class="nav-link"
        >
          Financials
        </router-link>
      </li>
      <li
        v-else-if="showFinancials"
        class="nav-item"
        role="presentation"
      >
        <router-link
          :to="{ name: 'project_financials' }"
          :class="{ 'active': $route.name.includes('financials') }"
          class="nav-link"
        >
          Financials
        </router-link>
      </li>
      <li
        v-if="showAIMs"
        class="nav-item"
        role="presentation"
      >
        <router-link
          :to="{ name: 'list_aims' }"
          :class="{ 'active': $route.name.includes('aims') || $route.name.includes('_aim') }"
          class="nav-link"
        >
          AIMs
        </router-link>
      </li>
    </ul>
    <router-view
      v-if="project.id"
      :project="project"
      :permissions="permissions"
      :can-attach-file="canAttachFile"
      :can-create-aim="canCreateAim"
      :can-create-bookings="canCreateBookings"
      :can-create-update="canCreateUpdate"
      :can-change-milestones="canChangeMilestones"
      :can-change-financials="canChangeFinancials"
      :can-change-services="canChangeServices"
      :can-change-locking="canChangeLocking"
      :can-show-service-prices="canShowServicePrices"
      :can-show-services="showServices"
      @reload-project="getData"
    />
    <ProjectOnHoldUpdate
      ref="onHoldUpdate"
      @on-hold-update="getData"
    />
  </div>
</template>

<script>
/**
 * View to show a Projects details.
 */
import ProjectOnHoldUpdate from './ProjectOnHoldUpdate'
import projectAPI from '@/api/project'

export default {
  components: {
    ProjectOnHoldUpdate,
  },
  props: {
    projectId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      project: {
        name: '',
        creator: { name: '' },
        domain: null,
        project_type: { name: '' },
        project_state: { name: '' },
        milestones: [],
        services: [],
        bookings: [],
      },
      permissions: [],
      error: '',
    }
  },
  computed: {
    showAIMs () {
      return this.canDo('GET', 'aims')
    },
    showBookings () {
      return this.project.bookings.length > 0 || this.canCreateBookings
    },
    showMilestones () {
      return this.project.milestones.length > 0 || this.canChangeMilestones
    },
    showServices () {
      return this.project.project_type.use_subcats && (
        (this.project.services && this.project.services.length > 0) ||
        this.canChangeServices ||
        this.canChangeLocking
      )
    },
    showFinancials () {
      return this.canDo('GET', 'financials')
    },
    canChangeFinancials () {
      return this.canDo('PUT', 'financials')
    },
    canAttachFile () {
      return this.canDo('POST', 'attachments')
    },
    canChangeMilestones () {
      return this.canDo('PUT', 'milestone') || this.canDo('PUT', 'milestones')
    },
    canChangeServices () {
      return this.canDo('PUT', 'services')
    },
    canShowServicePrices () {
      return this.canDo('GET', 'service_prices')
    },
    canChangeLocking () {
      return this.canDo('PUT', 'services_lock')
    },
    canCreateUpdate () {
      return this.canDo('POST', 'update')
    },
    canCreateAim () {
      return this.canDo('POST', 'aims')
    },
    canCreateBookings () {
      return this.canDo('POST', 'bookings')
    },
    canChangeHoldStatus () {
      return this.canDo('PUT', 'on_hold')
    },
    onHoldTitle () {
      return this.project.on_hold ? 'Remove hold' : 'Place on hold'
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getDataPromise () {
      this.error = ''
      return Promise.all([
        projectAPI.getProject(this.projectId),
        projectAPI.getProjectRoles(this.projectId),
        projectAPI.getProjectMilestones(this.projectId),
        projectAPI.getProjectBookings(this.projectId),
        projectAPI.getProjectPermissions(this.projectId),
        projectAPI.getProjectRolePermissions(this.projectId),
      ]).then(([project, roles, milestones, bookings, permissions, rolePerms]) => {
        roles.data.forEach(role => {
          const perm = rolePerms.data.find(p => p.usr_role_id === role.usr_role.id)
          if (perm) { role.can_edit = perm.can_edit }
        })
        project.data.roles = roles.data
        project.data.milestones = milestones.data
        project.data.bookings = bookings.data
        this.permissions = permissions.data
        this.project = project.data
      })
    },
    getData () {
      this.getDataPromise().catch(error => {
        this.error = error.message
      })
    },
    canDo (method, resource) {
      return this.permissions.findIndex(p => (p.method === method) && (p.resource === resource)) >= 0
    },
    changeHoldStatus () {
      this.$refs.onHoldUpdate.edit(this.project)
    },
  },
}
</script>
