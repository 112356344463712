<template>
  <ValidationProvider
    ref="provider"
    v-slot="{ errors }"
    :rules="calcRules"
    :name="title"
    slim
  >
    <FormGroup
      :title="title"
      :help="readonly || disabled ? '' : help"
      :error="errors[0]"
      :required="required && !readonly"
      :horizontal="horizontal"
    >
      <input
        v-if="readonly && !multiple"
        :value="labelValue"
        type="text"
        readonly
        class="form-control-plaintext"
      >
      <Multiselect
        v-else
        v-model="innerValue"
        :track-by="trackBy || bindBy"
        :label="label || trackBy || bindBy"
        :options="computedOptions"
        :allow-empty="!required"
        :name="name"
        :class="{ 'is-invalid': errors[0] }"
        :multiple="multiple"
        :custom-label="customLabel"
        :deselect-label="required ? '' : 'Press enter to remove'"
        :loading="loading || isLoading"
        :placeholder="placeholder"
        :disabled="disabled"
        :taggable="taggable"
        :tag-placeholder="tagPlaceholder"
        :open-direction="openDirection"
        :internal-search="!asyncFind"
        @search-change="debouncedSearch"
        @tag="v => $emit('tag', v)"
        @select="v => $emit('select', v)"
        @remove="v => $emit('remove', v)"
      />
    </FormGroup>
  </ValidationProvider>
</template>

<script>
import FormGroup from './FormGroup'
import Multiselect from 'vue-multiselect'
import urlTemplate from 'url-template'
import { ValidationProvider, extend } from 'vee-validate'
import axios from '@/axios/default'
import debounce from 'lodash/debounce'

extend('strictOneOf', {
  validate: (value, args) => {
    const key = args.objectKey
    if (Array.isArray(value)) {
      return value.every(val => args.options.some(opt => key ? opt[key] === val[key] : opt === val))
    }
    return args.options.some(opt => key ? opt[key] === value[key] : opt === value)
  },
  params: ['objectKey', 'options'],
  message: 'The {_field_} field is not a valid value',
})

export default {
  components: {
    FormGroup,
    Multiselect,
    ValidationProvider,
  },
  props: {
    // eslint-disable-next-line
    value: {
      required: true,
    },
    rules: {
      type: Object,
      default () {
        return {}
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    help: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => { return [] },
    },
    optionsUriTemplate: {
      type: String,
      default: '',
    },
    trackBy: {
      type: String,
      default: null,
    },
    bindBy: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    tagPlaceholder: {
      type: String,
      default: undefined,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    customLabel: {
      type: Function,
      default: undefined,
    },
    asyncFind: {
      type: Function,
      default: undefined,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    openDirection: {
      type: String,
      default: 'auto',
    },
  },
  data () {
    return {
      remoteOptions: [],
      isLoading: false,
    }
  },
  computed: {
    innerValue: {
      get () {
        if (this.bindBy) {
          return this.computedOptions.find(opt => opt[this.bindBy] === this.value)
        }
        return this.value
      },
      set (n) {
        if (this.bindBy && n) {
          this.$emit('input', n[this.bindBy])
        } else {
          this.$emit('input', n)
        }
      },
    },
    labelValue () {
      if (!this.value) {
        return ''
      } else if (this.customLabel) {
        return this.customLabel(this.value)
      } else if (this.label) {
        return this.value[this.label]
      } else if (this.trackBy) {
        return this.value[this.trackBy]
      }
      return this.value
    },
    computedOptions () {
      if (this.options && this.options.length > 0) {
        return this.options
      }
      return this.remoteOptions
    },
    template () {
      if (this.optionsUriTemplate) {
        return urlTemplate.parse(this.optionsUriTemplate)
      }
      return ''
    },
    calcRules () {
      if (this.readonly) {
        return {}
      }
      const rules = Object.assign({ required: this.required }, this.rules)
      if (!this.taggable && !this.asyncFind) {
        rules.strictOneOf = { options: this.computedOptions, objectKey: this.trackBy }
      }
      return rules
    },
  },
  watch: {
    '$route.params': function () { this.getOptions() },
    template: function () { this.getOptions() },
  },
  created () {
    this.getOptions()
    this.debouncedSearch = debounce(this.search, 500)
    this.debouncedSearch('')
  },
  methods: {
    getOptions () {
      if (!this.template) {
        this.remoteOptions = []
        return
      }
      const url = this.template.expand(this.$route.params)
      this.isLoading = true
      axios.get(url).then(resp => {
        this.remoteOptions = resp.data
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        this.$refs.provider.applyResult({
          errors: ['Option retrival failed: ' + error.message],
          valid: false,
          failedRules: {},
        })
      })
    },
    async search (s) {
      if (!this.asyncFind) { return }
      try {
        this.isLoading = true
        this.remoteOptions = await this.asyncFind(s)
      } catch (error) {
        this.$refs.provider.applyResult({
          errors: ['Option search failed: ' + error.message],
          valid: false,
          failedRules: {},
        })
      }
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.multiselect.is-invalid ::v-deep {
  .multiselect__tags {
    border-color: $form-feedback-invalid-color;
  }
  .multiselect__select:before {
    border-top-color: $form-feedback-invalid-color;
  }
}
.multiselect ::v-deep {
  font-size: $font-size-base;

  .multiselect__tags {
    border-color: rgb(206, 212, 218);
    font-size: 1em;
  }
  .multiselect, .multiselect__input, .multiselect__single {
    font-size: $font-size-base;
  }
  .multiselect--disabled {
    opacity: 1;
    background-color: transparent;
    .multiselect__tags, .multiselect__single {
      background-color: #e9ecef;
    }
    .multiselect__tag-icon {
      width: 0;
    }
    .multiselect__select {
      display: none;
    }
    .multiselect__tag {
      padding-right: 10px;
    }
  }
}

</style>
