<template>
  <ValidationProvider
    v-slot="{ errors }"
    :rules="calcRules"
    :name="title"
    slim
  >
    <FormGroup
      :title="title"
      :help="readonly || disabled ? '' : help"
      :error="errors[0]"
      :required="required && !readonly && !disabled"
      :horizontal="horizontal"
    >
      <div class="input-group">
        <div
          v-if="hasPrependSlot"
          class="input-group-prepend"
        >
          <div class="input-group-text">
            <slot name="prepend" />
          </div>
        </div>
        <input
          v-model="innerValue"
          :name="name"
          :maxlength="maxlength"
          :type="type"
          :placeholder="placeholder"
          :disabled="disabled"
          :readonly="readonly"
          :class="inputClass(errors[0])"
          :pattern="pattern"
          @blur="innerValue = trimValue"
        >
      </div>
    </FormGroup>
  </ValidationProvider>
</template>

<script>
import FormGroup from './FormGroup'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    FormGroup,
    ValidationProvider,
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    rules: {
      type: Object,
      default () {
        return {}
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    help: {
      type: String,
      default: '',
    },
    maxlength: {
      type: Number,
      default: undefined,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    trim: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    pattern: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      innerValue: '',
    }
  },
  computed: {
    trimValue () {
      if (this.trim && (typeof this.innerValue === 'string' || this.innerValue instanceof String)) {
        return this.innerValue.trim()
      }
      return this.innerValue
    },
    calcRules () {
      return Object.assign({ required: this.required }, this.rules)
    },
    hasPrependSlot () {
      return !!this.$slots.prepend
    },
  },
  watch: {
    value: {
      immediate: true,
      handler: function (newVal) {
        if (!this.trim || this.trimValue !== newVal) {
          this.innerValue = newVal
        }
      },
    },
    trimValue (newVal) {
      if (this.value !== newVal) {
        this.$emit('input', newVal)
      }
    },
  },
  methods: {
    inputClass (invalid) {
      return {
        'form-control-plaintext': this.readonly,
        'form-control': !this.readonly,
        'is-invalid': invalid,
      }
    },
  },
}
</script>
