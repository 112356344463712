<template>
  <div
    v-if="showModal"
    :class="{ modal: true, in: showModal }"
    :style="{ 'overflow-y': scrollable ? null : 'auto' }"
    tabindex="-1"
    role="dialog"
  >
    <div
      :class="customClass"
      role="document"
    >
      <div class="modal-content">
        <div
          v-if="!noHeader"
          class="modal-header align-items-center shadow-sm"
        >
          <h4 class="modal-title flex-grow-1">
            {{ title }}
          </h4>
          <slot name="header-extension" />
          <slot name="header-action">
            <button
              type="button"
              class="close"
              @click.prevent="hide"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </slot>
        </div>
        <div class="modal-body">
          <slot />
        </div>
        <div
          v-if="hasFooter"
          class="modal-footer"
        >
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * A Modal component
 */
export default {
  props: {
    /** title of modal */
    title: {
      type: String,
      required: true,
    },
    large: {
      type: Boolean,
      default: false,
    },
    extraLarge: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    noHeader: {
      type: Boolean,
    },
  },
  data () {
    return {
      showModal: false,
    }
  },
  computed: {
    customClass () {
      return {
        'modal-dialog': true,
        'modal-lg': this.large,
        'modal-xl': this.extraLarge,
        'modal-dialog-scrollable': this.scrollable,
      }
    },
    hasFooter () {
      return !!this.$slots.footer
    },
  },
  methods: {
    /**
     * @description Show modal
     */
    show () {
      this.showModal = true
    },
    /**
     * @description Hide modal
     */
    hide () {
      this.showModal = false
    },
  },
}
</script>

<style scoped>
.modal.in {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
