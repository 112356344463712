<template>
  <div class="py-4">
    <div class="row">
      <!-- Service Group Summary -->
      <div class="col-3">
        <!-- Service Group Name -->
        <div class="font-weight-bold pb-3 text-info">
          {{ serviceGroup.name }}
        </div>
        <!-- Sub Total -->
        <div
          v-if="canShowServicePrices"
          class="text-gray-600"
        >
          Sub Total: {{ formatCurrency(totalPrice) }}
        </div>
        <div />
      </div>
      <!-- Items -->
      <div class="col-9">
        <div
          v-for="item in serviceGroup.items"
          :key="item.id"
        >
          <ServiceCatalogItem
            :item="item"
            :show-price="canShowServicePrices"
            show-quantity
            :read-only="readOnly"
            :group-max="serviceGroup.max"
          >
            <slot :item="item" />
          </ServiceCatalogItem>
        </div>
      </div>
    </div>
    <!-- Limit Reached Alert -->
    <ValidationProvider
      v-slot="{ invalid }"
      ref="totalProvider"
      :rules="rules"
      :name="`${serviceGroup.name} Total`"
    >
      <div
        v-if="invalid"
        class="alert alert-danger mt-2 mb-0"
        role="alert"
      >
        You have reached the limit of {{ serviceGroup.max }} items for {{ serviceGroup.name }}
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
import ServiceCatalogItem from './CatalogItem'
import { ValidationProvider } from 'vee-validate'
import formatCurrency from '@/filters/formatCurrency'

export default {
  components: {
    ServiceCatalogItem,
    ValidationProvider,
  },
  props: {
    serviceGroup: {
      type: Object,
      required: true,
    },
    canShowServicePrices: {
      type: Boolean,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // TODO: change input in ServiceCatalogItem to not return empty string for count value
    // force to Number as curr.count may be empty string '' which causes result to be concatenated string
    totalCount () {
      return this.serviceGroup.items.reduce((acc, curr) => {
        if (curr.count === null || curr.count === undefined) { return acc }
        return acc + Number(curr.count)
      }, 0)
    },
    totalPrice () {
      return this.serviceGroup.items.reduce((acc, curr) => {
        if (curr.price === null || curr.price === undefined) { return acc }
        return acc + Number(curr.price)
      }, 0)
    },
    rules () {
      return this.serviceGroup.max > 0 ? { max_value: this.serviceGroup.max } : {}
    },
  },
  watch: {
    totalCount (newValue) {
      this.$refs.totalProvider.validate(newValue)
    },
  },
  mounted () {
    this.validateTotal()
  },
  methods: {
    validateTotal () {
      this.$refs.totalProvider.validate(this.totalCount)
    },
    formatCurrency,
  },
}
</script>
