import moment from 'moment'

export default function (value, fmt = 'Do MMM YYYY h:mm a') {
  if (value) {
    const m = moment.utc(String(value))
    if (m.isValid) {
      return m.format(fmt)
    }
  }
}
