<template>
  <tr>
    <template v-for="field in visibleFields">
      <th
        :key="field.name"
        class="custom-header"
        :class="field.titleClass"
      >
        <span>
          <span
            :class="{ clickable: field.sortField }"
            class="custom-header-sort"
            @click="onColumnHeaderClicked(field, $event)"
          >
            <span class="noprint">
              <ArrowUpIcon
                v-if="field.sortDirection === 'asc'"
                title=""
              />
              <ArrowDownIcon
                v-else-if="field.sortDirection === 'desc'"
                title=""
              />
            </span>
            <span>{{ field.shortTitle || field.title }}</span>
          </span>
          <span class="noprint">
            <CustomFilterDate
              v-if="field.filterType === Date"
              :field="field"
            />
            <CustomFilter
              v-else-if="field.filterType"
              :field="field"
            />
          </span>
        </span>
      </th>
    </template>
  </tr>
</template>

<script>
import ArrowUpIcon from 'vue-material-design-icons/ArrowUp.vue'
import ArrowDownIcon from 'vue-material-design-icons/ArrowDown.vue'
import CustomFilter from '@/components/CustomFilter'
import CustomFilterDate from '@/components/CustomFilterDate'

export default {
  components: {
    ArrowUpIcon,
    ArrowDownIcon,
    CustomFilter,
    CustomFilterDate,
  },
  computed: {
    visibleFields () {
      return this.vuetable.tableFields.filter(f => f.visible).map(f => {
        const nf = { ...f }
        const i = this.sortFields.findIndex(f => f.direction && f.sortField === nf.sortField)
        if (i >= 0) {
          nf.sortIndex = i
          nf.sortDirection = this.sortFields[i].direction
        }
        nf.filterField = nf.filterField || nf.sortField || nf.name
        return nf
      })
    },
    sortFields () {
      if (!this.appendParams.sort) {
        return []
      }
      return this.appendParams.sort.split(',').map(s => {
        const p = s.split(' ')
        return { sortField: p[0], direction: p[1] ? p[1] : 'asc' }
      })
    },
    appendParams () {
      return this.$parent.vuetable.appendParams
    },
    vuetable () {
      return this.$parent
    },
  },
  methods: {
    onColumnHeaderClicked (field, event) {
      if (!field.sortField) {
        return
      }
      const query = { ...this.$route.query }
      // reset page number on sorting change
      delete query.page
      let sortFields = this.sortFields.slice()
      if (!event.altKey && sortFields.length > 0) {
        if (
          field.sortIndex !== 0 ||
          (sortFields.length > 1 &&
          !(sortFields.length === 2 && field.sortAppend &&
            sortFields[1].sortField === field.sortAppend.sortField &&
            sortFields[1].direction === field.sortAppend.direction)
          )
        ) {
          // reset sort fields if it wasn't an alt-click combo and
          // there is more than one sort field.
          sortFields = sortFields.filter(f => f.sortField === field.sortField)
          if (sortFields.length > 0) {
            field.sortIndex = 0
          } else {
            delete field.sortIndex
          }
        }
      }
      if (isNaN(field.sortIndex)) {
        // field not in existing list so add to list
        sortFields.push({ sortField: field.sortField, direction: 'asc' })
        if (field.sortAppend && sortFields.length === 1) {
          sortFields.push(field.sortAppend)
        }
      } else if (field.sortDirection === 'asc') {
        // change sorting of field from asc to desc
        sortFields[field.sortIndex].direction = 'desc'
      } else {
        // change sorting of field from desc to asc
        sortFields[field.sortIndex].direction = 'asc'
      }
      // create sort query string from sort fields
      query.sort = sortFields.map(f => f.sortField + (f.direction === 'desc' ? ' desc' : '')).join(',')
      this.$router.push({ path: this.$route.path, query: query })
    },
  },
}
</script>

<style scoped>
.custom-header {
  vertical-align: middle;
}
.custom-header > span {
  display: flex;
  align-items: center;
}
.custom-header-sort {
  display: flex;
  align-items: center;
}
</style>
