import axios from '@/axios/default'

export default {
  usersURL () { return '/users' },
  getUsers () { return axios.get('/users') },
  createUser (u) { return axios.post('/users', u) },

  userURL (userID) { return '/users/' + userID },
  getUser (id) { return axios.get(this.userURL(id)) },
  updateUser (u) { return axios.put(this.userURL(u.id), u) },
  deleteUser (u) { return axios.delete(this.userURL(u.id)) },

  getUserNames () {
    return axios.get('/users').then(r => {
      return Promise.resolve(r.data.map(u => u.name))
    })
  },

  getRoles () { return axios.get('/user_roles') },
}
