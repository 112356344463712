<template>
  <Modal
    ref="modal"
    :title="`Edit ${field.schema && field.schema.title}`"
  >
    <BaseForm
      submit-title="Submit"
      @submit="save"
      @cancel="cancel"
    >
      <SchemaPropertyField
        v-model="value"
        :name="field.name"
        :schema="field.schema"
        :required="field.required"
      />
      <FieldError v-model="error" />
    </BaseForm>
  </Modal>
</template>

<script>
/**
 * Modal to change the Sites assigned to a Project.
 */
import Modal from '@/components/Modal'
import SchemaPropertyField from '@/components/SchemaPropertyField'
import FieldError from '@/components/FieldError'
import bookingAPI from '@/api/booking'

export default {
  components: {
    Modal,
    SchemaPropertyField,
    FieldError,
  },
  data () {
    return {
      error: '',
      booking: {},
      field: {
        name: '',
        title: '',
        rules: '',
      },
      value: '',
    }
  },
  methods: {
    reset () {
      this.error = ''
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    edit (field, booking) {
      this.reset()
      this.booking = booking
      this.field = field
      this.value = booking.data[field.name]
      this.$refs.modal.show()
    },
    save () {
      bookingAPI.updateBookingProp(this.booking.id, this.field.name, this.value).then(resp => {
        this.$emit('updated')
        this.$set(this.booking.data, this.field.name, this.value)
        this.cancel()
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
