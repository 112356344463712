
export default function (value) {
  if (typeof value !== 'number') {
    return value
  }
  const val = parseFloat(value).toFixed(2)
  const prefix = val < 0 ? '-$' : '$'

  return prefix + Math.abs(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
