<template>
  <div class="mx-3">
    <div>
      <FormGroup
        v-if="panOwnerName"
        title="PAN Owner"
      >
        <p>{{ panOwnerName }}</p>
      </FormGroup>
      <FormGroup
        v-if="panCode"
        title="PAN Code"
      >
        <p>{{ panCode }}</p>
      </FormGroup>
      <template v-if="!hasServices">
        <FormGroup
          v-if="financials.category"
          title="Category"
        >
          <p>{{ financials.category.descr }}</p>
        </FormGroup>
        <FormGroup title="Scope">
          <pre>{{ financials.scope }}</pre>
        </FormGroup>
      </template>
      <template v-else>
        <div class="font-weight-bold mt-2 mb-2">
          Scope
        </div>
        <table class="mb-2">
          <tr
            v-for="s in services"
            :key="s.id"
          >
            <td class="pr-3 align-top text-nowrap">
              {{ s.count }} x {{ s.service.name }}
            </td>
            <td class="pr-3 align-top">
              ( {{ s.service.descr }} )
              <div
                v-if="s.scope"
                class="rounded-lg bg-gray-200 px-4 py-1 mb-1"
              >
                <CatalogItemInfo
                  :info="s.scope"
                  disable-markdown
                />
              </div>
            </td>
            <td class="pr-3 align-top">
              {{ s.completion }} {{ s.completion ? 'days' : '' }}
            </td>
            <td class="pr-3 text-right align-top">
              {{ formatCurrency(s.price) }}
            </td>
          </tr>
        </table>
      </template>
      <FormGroup title="Price">
        <p>{{ formatCurrency(financials.price) }}</p>
      </FormGroup>
      <ProjectFinancialsAttachments :attachments="attachments.filter(at => !at.quote_id)" />
      <div
        v-for="q in quotes"
        :key="q.id"
        :class="{ 'quoted-rejected': q.approved === false }"
        class="project-quote"
      >
        <hr>
        <h5>Additional Quote {{ q.id }}</h5>
        <FormGroup title="Scope">
          <pre>{{ q.scope }}</pre>
        </FormGroup>
        <FormGroup title="Price">
          <p class="quote-price">
            {{ formatCurrency(q.price) }}
          </p>
        </FormGroup>
        <div
          v-if="q.approved === null && canEditQuote"
          class="mb-3"
        >
          <button
            class="btn btn-primary"
            @click="confirmAcceptQuote(q)"
          >
            Approve
          </button>
          <button
            class="btn btn-default"
            @click="confirmRejectQuote(q)"
          >
            Reject
          </button>
        </div>
        <FormGroup
          v-else
          title="Status"
        >
          <p>{{ statusText(q.approved) }}</p>
        </FormGroup>
        <ProjectFinancialsAttachments :attachments="attachments.filter(at => at.quote_id === q.id)" />
      </div>
      <hr v-if="canCreateQuote || quotes.length > 0">
      <FormGroup
        v-if="quotes.length > 0"
        title="Total"
      >
        <p>{{ formatCurrency(total) }}</p>
      </FormGroup>
      <div>
        <button
          v-if="canCreateQuote"
          class="btn btn-primary"
          @click="$refs.createQuote.show"
        >
          New Quote
        </button>
        <template
          v-if="transitions.length > 0"
        >
          <button
            v-for="tr in transitions"
            :key="tr.dst_state.id"
            class="btn btn-primary mr-2"
            @click="changeStateID(tr)"
          >
            {{ tr.name }}
          </button>
        </template>
        <button
          v-if="hasFinancialAttachTypes"
          class="btn btn-primary"
          @click="attachFile"
        >
          Add Attachment
        </button>
      </div>
      <p
        v-if="error"
        class="text-danger"
        v-text="error"
      />
    </div>
    <ProjectQuoteCreate
      ref="createQuote"
      :project="project"
      @created="getData"
    />
    <ConfirmDialog
      ref="dialog"
      @confirmed="updateQuote"
    />
    <ConfirmStateChange
      ref="confirmStateChange"
      :project="project"
      @state-change="refreshData"
    />
    <AttachFile
      ref="attachFile"
      :project="project"
      financial-types
      @file-upload="refreshData"
    />
  </div>
</template>

<script>
/**
 * View to show financials for a project.
 */
import FormGroup from '@/components/forms/FormGroup'
import projectAPI from '@/api/project'
import groupAPI from '@/api/group'
import ConfirmStateChange from '@/views/Project/ConfirmStateChange'
import CatalogItemInfo from '@/views/Project/Services/CatalogItemInfo'
import formatCurrency from '@/filters/formatCurrency'
import ProjectQuoteCreate from '@/views/Project/Financial/ProjectQuoteCreate'
import ProjectFinancialsAttachments from '@/views/Project/Financial/ProjectFinancialsAttachments'
import ConfirmDialog from '@/components/ConfirmDialog'
import AttachFile from '@/views/Project/AttachFile'

export default {
  components: {
    FormGroup,
    ConfirmStateChange,
    CatalogItemInfo,
    ProjectQuoteCreate,
    ProjectFinancialsAttachments,
    ConfirmDialog,
    AttachFile,
  },
  props: {
    /** project */
    project: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      financials: {
        scope: '',
        price: '',
      },
      transitions: [],
      services: [],
      quotes: [],
      permissions: [],
      attachments: [],
      error: '',
      hasFinancialAttachTypes: false,
    }
  },
  computed: {
    hasServices () {
      return this.services && this.services.length > 0
    },
    panOwnerName () {
      if (!this.project.roles) {
        return ''
      }
      const po = this.project.roles.find(r => r.usr_role.name.includes('PAN Owner'))
      return po ? po.usr.name : ''
    },
    panCode () {
      return this.project.p4p.pan_code
    },
    total () {
      return (this.financials.price || 0) +
        this.quotes.filter(q => q.approved).map(q => q.price).reduce((a, b) => a + b, 0)
    },
    canEditQuote () {
      return this.canDo('PUT', 'quotes')
    },
    canCreateQuote () {
      return this.canDo('POST', 'quotes')
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      Promise.all([
        projectAPI.getFinancials(this.project.id),
        projectAPI.getProjectTransitions(this.project.id),
        projectAPI.getServices(this.project.id),
        projectAPI.getQuotes(this.project.id),
        projectAPI.getProjectPermissions(this.project.id),
        projectAPI.getFinancialAttachments(this.project.id),
        groupAPI.getAttachmentTypes(this.project.project_type.id),
      ]).then(([financials, transitions, services, quotes, permissions, attachments, attachTypes]) => {
        this.financials = financials.data
        // get available named transitions that are for Quoted stage
        this.transitions = transitions.data.filter(tr => tr.name && tr.src_state.name === 'Quoted')
        this.services = services.data
        this.quotes = quotes.data
        this.permissions = permissions.data
        this.hasFinancialAttachTypes = attachTypes.data.findIndex(t => t.is_financial) >= 0
        this.attachments = attachments.data
      }).catch(error => {
        this.error = error.message
      })
    },
    refreshData () {
      this.$emit('reload-project')
      this.getData()
    },
    attachFile () {
      this.$refs.attachFile.show()
    },
    changeStateID (transition) {
      this.$refs.confirmStateChange.toConfirm(transition)
    },
    statusText (approved) {
      if (approved === null) {
        return 'Pending Approval'
      }
      return approved ? 'Approved' : 'Rejected'
    },
    canDo (method, resource) {
      return this.permissions.findIndex(p => (p.method === method) && (p.resource === resource)) >= 0
    },
    confirmAcceptQuote (q) {
      q = { ...q, approved: true }
      this.$refs.dialog.confirm(
        'Confirm Accept Additional Quote ' + q.id,
        'Are you sure you want to accept Additional Quote (' + q.id + ')?',
        q,
      )
    },
    confirmRejectQuote (q) {
      q = { ...q, approved: false }
      this.$refs.dialog.confirm(
        'Confirm Reject Additional Quote ' + q.id,
        'Are you sure you want to reject Additional Quote (' + q.id + ')?',
        q,
      )
    },
    updateQuote (q) {
      projectAPI.updateQuote(this.project.id, q).then(resp => {
        this.getData()
      }).catch(error => {
        this.error = error.message
      })
    },
    formatCurrency,
  },
}
</script>

<style scoped>
.project-quote {
  margin-top: 20px;
  margin-bottom: 20px;
}
.quoted-rejected .quote-price {
  text-decoration: line-through;
}
pre {
  display: inline-block;
  overflow: auto;
  max-width: 100%;
  min-width: 25%;
  min-height: 50px;
  margin-bottom: 0px;
}
</style>
