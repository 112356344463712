
const GroupList = () => import(/* webpackChunkName: "groups" */ '@/views/Group/GroupList')

const Group = () => import(/* webpackChunkName: "groups" */ '@/views/Group/Group')
const GroupCreate = () => import(/* webpackChunkName: "groups" */ '@/views/Group/GroupCreate')
const GroupRoleList = () => import(/* webpackChunkName: "groups" */ '@/views/Group/GroupRoleList')
const GroupRoleCreate = () => import(/* webpackChunkName: "groups" */ '@/views/Group/GroupRoleCreate')
const GroupRole = () => import(/* webpackChunkName: "groups" */ '@/views/Group/GroupRole')
const GroupRoleUserList = () => import(/* webpackChunkName: "groups" */ '@/views/Group/GroupRoleUserList')
const GroupRoleUserCreate = () => import(/* webpackChunkName: "groups" */ '@/views/Group/GroupRoleUserCreate')
const ProjectRoleRuleList = () => import(/* webpackChunkName: "groups" */ '@/views/Group/ProjectRoleRuleList')
const ProjectRoleRuleCreate = () => import(/* webpackChunkName: "groups" */ '@/views/Group/ProjectRoleRuleCreate')

const MilestoneList = () => import(/* webpackChunkName: "groups" */ '@/views/Group/MilestoneList')
const MilestoneCreate = () => import(/* webpackChunkName: "groups" */ '@/views/Group/MilestoneCreate')
const MilestoneGroupCreate = () => import(/* webpackChunkName: "groups" */ '@/views/Group/MilestoneGroupCreate')
const MilestoneGroupList = () => import(/* webpackChunkName: "groups" */ '@/views/Group/MilestoneGroupList')
const MilestoneGroup = () => import(/* webpackChunkName: "groups" */ '@/views/Group/MilestoneGroup')
const AttachmentTypeCreate = () => import(/* webpackChunkName: "groups" */ '@/views/Group/AttachmentTypeCreate')
const AttachmentTypeList = () => import(/* webpackChunkName: "groups" */ '@/views/Group/AttachmentTypeList')

const ProjectStateEmailList = () => import(/* webpackChunkName: "groups" */ '@/views/Group/ProjectStateEmailList')
const ProjectStateEmailCreate = () => import(/* webpackChunkName: "groups" */ '@/views/Group/ProjectStateEmailCreate')

const ProjectChangeEmailList = () => import(/* webpackChunkName: "groups" */ '@/views/Group/ProjectChangeEmailList')
const ProjectChangeEmailCreate = () => import(/* webpackChunkName: "groups" */ '@/views/Group/ProjectChangeEmailCreate')

const EmailTemplateList = () => import(/* webpackChunkName: "groups" */ '@/views/Group/EmailTemplateList')
const EmailTemplate = () => import(/* webpackChunkName: "groups" */ '@/views/Group/EmailTemplate')
const EmailTemplateCreate = () => import(/* webpackChunkName: "groups" */ '@/views/Group/EmailTemplateCreate')

const ProjectPermissionList = () => import(/* webpackChunkName: "groups" */ '@/views/Group/ProjectPermissionList')
const ProjectPermissionCreate = () => import(/* webpackChunkName: "groups" */ '@/views/Group/ProjectPermissionCreate')

const BookingSubtypeList = () => import(/* webpackChunkName: "groups" */ '@/views/Group/BookingSubtypeList')
const BookingSubtypeUpdate = () => import(/* webpackChunkName: "groups" */ '@/views/Group/BookingSubtypeUpdate')

const ServiceList = () => import(/* webpackChunkName: "groups" */ '@/views/Group/ServiceList')
const ServiceCreate = () => import(/* webpackChunkName: "groups" */ '@/views/Group/ServiceCreate')
const ServiceGroupList = () => import(/* webpackChunkName: "groups" */ '@/views/Group/ServiceGroupList')
const ServiceGroupCreate = () => import(/* webpackChunkName: "groups" */ '@/views/Group/ServiceGroupCreate')
const ServiceIconList = () => import(/* webpackChunkName: "groups" */ '@/views/Group/ServiceIconList')
const ServiceIconCreate = () => import(/* webpackChunkName: "groups" */ '@/views/Group/ServiceIconCreate')

const ServiceAttachmentList = () => import(/* webpackChunkName: "groups" */ '@/views/Group/ServiceAttachmentList')
const ServiceAttachmentCreate = () => import(/* webpackChunkName: "groups" */ '@/views/Group/ServiceAttachmentCreate')

const PropertyList = () => import(/* webpackChunkName: "groups" */ '@/views/Group/PropertyList')
const PropertyCreate = () => import(/* webpackChunkName: "groups" */ '@/views/Group/PropertyCreate')

const PropertyOptionList = () => import(/* webpackChunkName: "groups" */ '@/views/Group/PropertyOptionList')
const PropertyOptionCreate = () => import(/* webpackChunkName: "groups" */ '@/views/Group/PropertyOptionCreate')

const StateTransitionList = () => import(/* webpackChunkName: "groups" */ '@/views/Group/StateTransitionList')
const StateTransitionCreate = () => import(/* webpackChunkName: "groups" */ '@/views/Group/StateTransitionCreate')

const groupTitle = 'Delivery Groups'

export default [
  {
    path: 'groups',
    name: 'groups',
    component: GroupList,
    meta: {
      title: groupTitle,
      crumbs: [{ title: groupTitle }],
    },
  },
  {
    path: 'groups/:groupId',
    component: Group,
    props: true,
    meta: {
      crumbs: [
        { route: 'groups', title: groupTitle },
        { route: 'milestones', getter: 'group/groupName' },
      ],
    },
    children: [
      // redirect
      {
        path: '',
        redirect: 'milestones',
      },
      // milestones
      {
        path: 'milestones',
        name: 'milestones',
        props: true,
        component: MilestoneList,
        meta: {
          crumbs: [{ title: 'Milestones' }],
        },
      },
      {
        path: 'milestones/create',
        name: 'milestone_create',
        props: true,
        component: MilestoneCreate,
        meta: {
          crumbs: [
            { route: 'milestones', title: 'Milestones' },
            { title: 'Create' },
          ],
        },
      },
      {
        path: 'milestones/:id/update',
        name: 'milestone_update',
        props: true,
        component: MilestoneCreate,
        meta: {
          crumbs: [
            { route: 'milestones', title: 'Milestones' },
            { title: 'Update' },
          ],
        },
      },

      // milestone groups
      {
        path: 'milestone_groups',
        name: 'milestone_groups',
        props: true,
        component: MilestoneGroupList,
        meta: {
          crumbs: [{ title: 'Milestone Groups' }],
        },
      },
      {
        path: 'milestone_groups/create',
        name: 'create_milestone_group',
        props: true,
        component: MilestoneGroupCreate,
        meta: {
          crumbs: [
            { route: 'milestone_groups', title: 'Milestone Groups' },
            { title: 'Create' },
          ],
        },
      },
      {
        path: 'milestone_groups/:id',
        name: 'show_milestone_group',
        props: true,
        component: MilestoneGroup,
        meta: {
          crumbs: [
            { route: 'milestone_groups', title: 'Milestone Groups' },
            { param: 'id' },
          ],
        },
      },
      {
        path: 'milestone_groups/:id/update',
        name: 'update_milestone_group',
        props: true,
        component: MilestoneGroupCreate,
        meta: {
          crumbs: [
            { route: 'milestone_groups', title: 'Milestone Groups' },
            { route: 'show_milestone_group', param: 'id' },
            { title: 'Update' },
          ],
        },
      },

      // roles
      {
        path: 'roles',
        name: 'group_roles',
        props: true,
        component: GroupRoleList,
        meta: {
          crumbs: [{ title: 'Roles' }],
        },
      },
      {
        path: 'roles/create',
        name: 'group_role_create',
        props: true,
        component: GroupRoleCreate,
        meta: {
          crumbs: [
            { route: 'group_roles', title: 'Roles' },
            { title: 'Create' },
          ],
        },
      },
      {
        path: 'roles/:roleId',
        name: 'group_role',
        props: true,
        component: GroupRole,
        meta: {
          crumbs: [
            { route: 'group_roles', title: 'Roles' },
            { getter: 'group/roleName' },
          ],
        },
        children: [
          {
            path: 'users',
            name: 'group_role_user_list',
            props: true,
            component: GroupRoleUserList,
            meta: {
              crumbs: [
                { title: 'Users' },
              ],
            },
          },
          {
            path: 'users/create',
            name: 'group_role_user_create',
            props: true,
            component: GroupRoleUserCreate,
            meta: {
              crumbs: [
                { title: 'Add User' },
              ],
            },
          },
          {
            path: 'rules',
            name: 'group_role_rule_list',
            props: true,
            component: ProjectRoleRuleList,
            meta: {
              crumbs: [
                { title: 'Rules' },
              ],
            },
          },
          {
            path: 'rules/create',
            name: 'group_role_rule_create',
            props: true,
            component: ProjectRoleRuleCreate,
            meta: {
              crumbs: [
                { route: 'group_role_rule_list', title: 'Rules' },
                { title: 'Create' },
              ],
            },
          },
          {
            path: 'rules/:id/update',
            name: 'group_role_rule_update',
            props: true,
            component: ProjectRoleRuleCreate,
            meta: {
              crumbs: [
                { route: 'group_role_rule_list', title: 'Rules' },
                { title: 'Update' },
              ],
            },
          },
        ],
      },
      {
        path: 'roles/:id/update',
        name: 'group_role_update',
        props: true,
        component: GroupRoleCreate,
        meta: {
          crumbs: [
            { route: 'group_roles', title: 'Roles' },
            { title: 'Update' },
          ],
        },
      },

      // attachment types
      {
        path: 'attachment_types',
        name: 'attachment_types',
        props: true,
        component: AttachmentTypeList,
        meta: {
          crumbs: [{ title: 'Attachment Types' }],
        },
      },
      {
        path: 'attachment_types/create',
        name: 'create_attachment_type',
        props: true,
        component: AttachmentTypeCreate,
        meta: {
          crumbs: [
            { route: 'attachment_types', title: 'Attachment Types' },
            { title: 'Create' },
          ],
        },
      },
      {
        path: 'attachment_types/:id/update',
        name: 'attachment_type_update',
        props: true,
        component: AttachmentTypeCreate,
        meta: {
          crumbs: [
            { route: 'attachment_types', title: 'Attachment Types' },
            { title: 'Update' },
          ],
        },
      },

      // email notifications
      {
        path: 'project_state_emails',
        name: 'project_state_emails',
        props: true,
        component: ProjectStateEmailList,
        meta: {
          crumbs: [{ title: 'Email Notifications' }],
        },
      },
      {
        path: 'project_state_emails/create',
        name: 'create_project_state_email',
        props: true,
        component: ProjectStateEmailCreate,
        meta: {
          crumbs: [
            { route: 'project_state_emails', title: 'Email Notifications' },
            { title: 'Create' },
          ],
        },
      },
      {
        path: 'project_state_emails/:id/update',
        name: 'update_project_state_email',
        props: true,
        component: ProjectStateEmailCreate,
        meta: {
          crumbs: [
            { route: 'project_state_emails', title: 'Email Notifications' },
            { title: 'Update' },
          ],
        },
      },
      // change email notifications
      {
        path: 'project_change_emails',
        name: 'project_change_emails',
        props: true,
        component: ProjectChangeEmailList,
        meta: {
          crumbs: [{ title: 'Change Notifications' }],
        },
      },
      {
        path: 'project_change_emails/create',
        name: 'create_project_change_email',
        props: true,
        component: ProjectChangeEmailCreate,
        meta: {
          crumbs: [
            { route: 'project_change_emails', title: 'Change Notifications' },
            { title: 'Create' },
          ],
        },
      },

      // email templates
      {
        path: 'email_templates',
        name: 'email_templates',
        props: true,
        component: EmailTemplateList,
        meta: {
          crumbs: [{ title: 'Email Templates' }],
        },
      },
      {
        path: 'email_templates/create',
        name: 'create_email_template',
        props: true,
        component: EmailTemplateCreate,
        meta: {
          crumbs: [
            { route: 'email_templates', title: 'Email Templates' },
            { title: 'Create' },
          ],
        },
      },
      {
        path: 'email_templates/:id',
        name: 'email_template',
        props: true,
        component: EmailTemplate,
        meta: {
          crumbs: [
            { route: 'email_templates', title: 'Email Templates' },
            { param: 'id' },
          ],
        },
      },
      {
        path: 'email_templates/:id/update',
        name: 'update_email_template',
        props: true,
        component: EmailTemplateCreate,
        meta: {
          crumbs: [
            { route: 'email_templates', title: 'Email Templates' },
            { route: 'email_template', param: 'id' },
            { title: 'Update' },
          ],
        },
      },

      // project permissions
      {
        path: 'project_permissions',
        name: 'project_permissions',
        props: true,
        component: ProjectPermissionList,
        meta: {
          crumbs: [{ title: 'Project Permissions' }],
        },
      },
      {
        path: 'project_permissions/create',
        name: 'create_project_permission',
        props: true,
        component: ProjectPermissionCreate,
        meta: {
          crumbs: [
            { route: 'project_permissions', title: 'Project Permissions' },
            { title: 'Create' },
          ],
        },
      },
      {
        path: 'project_permissions/:id/update',
        name: 'update_project_permission',
        props: true,
        component: ProjectPermissionCreate,
        meta: {
          crumbs: [
            { route: 'project_permissions', title: 'Project Permissions' },
            { title: 'Update' },
          ],
        },
      },

      // services
      {
        path: 'services',
        name: 'service_list',
        props: true,
        component: ServiceList,
        meta: {
          crumbs: [{ title: 'Services' }],
        },
      },
      {
        path: 'services/create',
        name: 'service_create',
        props: true,
        component: ServiceCreate,
        meta: {
          crumbs: [
            { route: 'service_list', title: 'Services' },
            { title: 'Create' },
          ],
        },
      },
      {
        path: 'services/:id/update',
        name: 'service_update',
        props: true,
        component: ServiceCreate,
        meta: {
          crumbs: [
            { route: 'service_list', title: 'Services' },
            { title: 'Update' },
          ],
        },
      },
      // service attachments
      {
        path: 'services/:serviceId/attachments',
        name: 'service_attachment_list',
        props: true,
        component: ServiceAttachmentList,
        meta: {
          crumbs: [{ title: 'Service Attachments' }],
        },
      },
      {
        path: 'services/:serviceId/attachments/create',
        name: 'service_attachment_create',
        props: true,
        component: ServiceAttachmentCreate,
        meta: {
          crumbs: [
            { route: 'service_attachment_list', title: 'Service Attachments' },
            { title: 'Create' },
          ],
        },
      },
      {
        path: 'services/:serviceId/attachments/:attachmentTypeId/update',
        name: 'service_attachment_update',
        props: true,
        component: ServiceAttachmentCreate,
        meta: {
          crumbs: [
            { route: 'service_attachment_list', title: 'Service Attachments' },
            { title: 'Update' },
          ],
        },
      },
      {
        path: 'service_groups',
        name: 'service_group_list',
        props: true,
        component: ServiceGroupList,
        meta: {
          crumbs: [{ title: 'ServiceGroups' }],
        },
      },
      {
        path: 'service_groups/create',
        name: 'service_group_create',
        props: true,
        component: ServiceGroupCreate,
        meta: {
          crumbs: [
            { route: 'service_group_list', title: 'ServiceGroups' },
            { title: 'Create' },
          ],
        },
      },
      {
        path: 'service_groups/:id/update',
        name: 'service_group_update',
        props: true,
        component: ServiceGroupCreate,
        meta: {
          crumbs: [
            { route: 'service_group_list', title: 'ServiceGroups' },
            { title: 'Update' },
          ],
        },
      },
      {
        path: 'service_icons',
        name: 'service_icon_list',
        props: true,
        component: ServiceIconList,
        meta: {
          crumbs: [{ title: 'Service Icons' }],
        },
      },
      {
        path: 'service_icons/create',
        name: 'service_icon_create',
        props: true,
        component: ServiceIconCreate,
        meta: {
          crumbs: [
            { route: 'service_icon_list', title: 'Service Icons' },
            { title: 'Create' },
          ],
        },
      },
      {
        path: 'service_icons/:id/update',
        name: 'service_icon_update',
        props: true,
        component: ServiceIconCreate,
        meta: {
          crumbs: [
            { route: 'service_icon_list', title: 'Service Icons' },
            { title: 'Update' },
          ],
        },
      },

      // property options
      {
        path: 'property_options',
        name: 'property_option_list',
        props: true,
        component: PropertyOptionList,
        meta: {
          crumbs: [{ title: 'Property Options' }],
        },
      },
      {
        path: 'property_options/create',
        name: 'property_option_create',
        props: true,
        component: PropertyOptionCreate,
        meta: {
          crumbs: [
            { route: 'property_option_list', title: 'Property Options' },
            { title: 'Create' },
          ],
        },
      },
      {
        path: 'property_options/:id/update',
        name: 'property_option_update',
        props: true,
        component: PropertyOptionCreate,
        meta: {
          crumbs: [
            { route: 'property_option_list', title: 'Property Options' },
            { title: 'Update' },
          ],
        },
      },

      // properties
      {
        path: 'properties',
        name: 'property_list',
        props: true,
        component: PropertyList,
        meta: {
          crumbs: [{ title: 'Properties' }],
        },
      },
      {
        path: 'properties/create',
        name: 'property_create',
        props: true,
        component: PropertyCreate,
        meta: {
          crumbs: [
            { route: 'property_list', title: 'Properties' },
            { title: 'Create' },
          ],
        },
      },
      {
        path: 'properties/:id/update',
        name: 'property_update',
        props: true,
        component: PropertyCreate,
        meta: {
          crumbs: [
            { route: 'property_list', title: 'Properties' },
            { title: 'Update' },
          ],
        },
      },

      // booking types
      {
        path: 'booking_subtypes',
        name: 'booking_subtype_list',
        props: true,
        component: BookingSubtypeList,
        meta: {
          crumbs: [{ title: 'Booking Subtypes' }],
        },
      },
      {
        path: 'booking_subtypes/:id/update',
        name: 'booking_subtype',
        props: true,
        component: BookingSubtypeUpdate,
        meta: {
          crumbs: [
            { route: 'booking_subtype_list', title: 'Booking Subtypes' },
            { title: 'Update' },
          ],
        },
      },

      // state transitions
      {
        path: 'transitions',
        name: 'transition_list',
        props: true,
        component: StateTransitionList,
        meta: {
          crumbs: [{ title: 'Transitions' }],
        },
      },
      {
        path: 'transitions/create',
        name: 'transition_create',
        props: true,
        component: StateTransitionCreate,
        meta: {
          crumbs: [
            { route: 'transition_list', title: 'Transitions' },
            { title: 'Create' },
          ],
        },
      },
      {
        path: 'transitions/:id/update',
        name: 'transition_update',
        props: true,
        component: StateTransitionCreate,
        meta: {
          crumbs: [
            { route: 'transition_list', title: 'Transitions' },
            { title: 'Update' },
          ],
        },
      },
    ],
  },
  {
    // redirect old dashboard link
    // TODO: delete this route after version upgrade
    path: 'groups/:groupId/dashboard',
    redirect: { name: 'dashboard' },
  },
  {
    path: 'groups/:id/update',
    name: 'group_update',
    props: true,
    component: GroupCreate,
    meta: {
      crumbs: [
        { route: 'groups', title: groupTitle },
        { route: 'milestones', getter: 'group/groupName' },
        { title: 'Update' },
      ],
    },
  },
]
