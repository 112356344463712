import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '@/assets/scss/motion.scss'
import 'vue-material-design-icons/styles.css'
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import Vue from 'vue'
import router from './router'
import store from './store'
import Toasted from 'vue-toasted'
// Import Top Level View
import App from './views/App.vue'
import VTooltip from 'v-tooltip'

// Import functions to add VeeValidate rules
import { extend } from 'vee-validate'
import isURL from 'validator/lib/isURL'

// font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faUserCircle, faTachometerAlt, faUser, faCaretDown, faBook, faLifeRing, faSignOutAlt, faUsers, faClipboard, faFileAlt, faPlusCircle, faTable, faServer, faFilter, faDownload, faChartBar, faChartPie, faChartLine, faSpinner, faBriefcase, faCalendar, faShareAlt, faSearch, faLock, faCheckSquare, faPlus, faListAlt } from '@fortawesome/free-solid-svg-icons'
import { faFile, faClock } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faHome, faTachometerAlt, faUserCircle, faUser, faCaretDown, faBook, faLifeRing, faSignOutAlt, faUsers, faClipboard, faFileAlt, faPlusCircle, faTable, faServer, faClock, faFile, faFilter, faDownload, faChartBar, faChartPie, faChartLine, faSpinner, faBriefcase, faCalendar, faShareAlt, faSearch, faLock, faCheckSquare, faPlus, faListAlt)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)

Vue.use(Toasted, { position: 'top-right', duration: 5000 })
Vue.use(VTooltip)

// Import all base components
const requireComponent = require.context('./components', true, /Base[A-Z]\w+\.vue$/)
requireComponent.keys().forEach(fileName => {
  // Get component config
  let componentConfig = requireComponent(fileName)
  componentConfig = componentConfig.default || componentConfig
  // Get name of component
  const componentName = fileName.replace(/^.+\//, '').replace(/\.\w+$/, '')
  // Register component globally
  Vue.component(componentName, componentConfig)
})

// Add a VeeValidate 'url' rule
extend('url', {
  validate: value => isURL(value),
  message: (field) => `The ${field} field is not a valid URL`,
})

/* eslint-disable no-new */
new Vue({
  el: '#app-view',
  router,
  store,
  render: h => h(App),
})
