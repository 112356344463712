<template>
  <div class="mx-4">
    <!-- Selected Items per Group -->
    <div>
      <!-- Service Groups -->
      <ServiceCatalogGroup
        v-for="serviceGroup in servicesByGroup"
        :key="serviceGroup.id"
        :service-group="serviceGroup"
        :can-show-service-prices="canShowServicePrices"
        read-only
      />
      <!-- Summary -->
      <div
        class="d-flex py-4"
      >
        <!-- Totals -->
        <div
          v-if="!empty && canShowServicePrices"
          class="flex-grow-1"
        >
          <div>
            <!-- Price -->
            <div class="h4 font-weight-bold">
              Total: {{ formatCurrency(totalPrice) }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="error"
        class="alert alert-danger mb-4"
        role="alert"
      >
        {{ error }}
      </div>
      <!-- Lock indicator -->
      <div
        v-if="locked && (canChangeServices || canChangeLocking)"
        class="text-gray-600 mb-3"
      >
        <font-awesome-icon icon="lock" />&nbsp;
        Services Locked
      </div>
      <!-- Page Actions -->
      <div class="d-flex align-items-center">
        <button
          v-for="tr in transitions"
          :key="tr.dst_state.id"
          class="btn btn-primary mr-2"
          @click="changeStateID(tr)"
        >
          {{ tr.name }}
        </button>
        <!-- Edit Action -->
        <router-link
          v-if="canChangeServices && !locked"
          :to="{ name: 'update_project_services' }"
          class="btn btn-primary mr-2"
          tag="button"
        >
          Edit
        </router-link>
        <button
          v-if="canAttachFile"
          class="btn btn-primary mr-2"
          @click="attachFile"
        >
          Add Attachment
        </button>
        <!-- Unlock Action -->
        <button
          v-if="locked && canChangeLocking"
          class="btn btn-outline-success mr-2"
          @click="unlock"
        >
          Unlock
        </button>
        <!-- Lock Action -->
        <button
          v-if="!locked && canChangeLocking"
          class="btn btn-outline-danger"
          @click="lock"
        >
          Lock
        </button>
      </div>
    </div>
    <AttachFile
      ref="attachFile"
      :project="project"
    />
    <ConfirmStateChange
      ref="confirmStateChange"
      :project="project"
      @state-change="refreshData"
    />
  </div>
</template>

<script>

import Enumerable from 'linq'
import groupAPI from '@/api/group'
import projectAPI from '@/api/project'
import ServiceCatalogGroup from './CatalogGroup'
import ConfirmStateChange from '@/views/Project/ConfirmStateChange'
import formatCurrency from '@/filters/formatCurrency'
import AttachFile from '@/views/Project/AttachFile'

export default {
  components: {
    ServiceCatalogGroup,
    ConfirmStateChange,
    AttachFile,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    canChangeServices: {
      type: Boolean,
      required: true,
    },
    canChangeLocking: {
      type: Boolean,
      required: true,
    },
    canShowServices: {
      type: Boolean,
      required: true,
    },
    canShowServicePrices: {
      type: Boolean,
      required: true,
    },
    canAttachFile: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      loading: false,
      groups: [],
      transitions: [],
      pricedServices: [],
      error: '',
    }
  },
  computed: {
    locked () {
      return this.project.p4p.services_locked
    },
    services () {
      return this.canShowServicePrices ? this.pricedServices : this.project.services
    },
    totalPrice () {
      return Enumerable.from(this.services).sum(service => service.price)
    },
    servicesByGroup () {
      return Enumerable.from(this.services)
        .groupBy(item => item.service.service_group_id)
        .select(group => {
          const serviceGroup = this.groups.find(g => g.id === group.key())
          return {
            ...serviceGroup,
            items: group.orderBy(item => item.service.name).toArray(),
          }
        })
        .orderBy(group => group.name)
        .toArray()
    },
    empty () {
      return this.services.length === 0
    },
  },
  beforeMount () {
    if (!this.canShowServices) {
      this.$router.replace({ name: 'show_project_info' })
    } else {
      this.load()
    }
  },
  methods: {
    changeStateID (transition) {
      this.$refs.confirmStateChange.toConfirm(transition)
    },
    refreshData () {
      this.$emit('reload-project')
      this.load()
    },
    lock () {
      // Lock services
      projectAPI.lockServices(this.project.id).then(resp => {
        this.$emit('reload-project')
      }).catch(error => {
        this.error = error.message
      })
    },
    unlock () {
      // Unlock services
      projectAPI.unlockServices(this.project.id).then(resp => {
        this.$emit('reload-project')
      }).catch(error => {
        this.error = error.message
      })
    },
    load () {
      this.loading = true
      const promises = [
        groupAPI.getServiceGroups(this.project.project_type.id),
        projectAPI.getProjectTransitions(this.project.id),
      ]
      if (this.canShowServicePrices) {
        promises.push(projectAPI.getServices(this.project.id))
      }

      Promise.all(promises).then(([groups, transitions, services]) => {
        this.groups = groups.data
        if (this.canShowServicePrices) {
          this.pricedServices = services.data
        }
        // get available named transitions that are for Draft stage
        this.transitions = transitions.data.filter(tr => tr.name && tr.src_state.name === 'Draft')
      }).catch(error => {
        this.error = error.message
      })

      this.loading = true
    },
    attachFile (typeID) {
      this.$refs.attachFile.show(typeID)
    },
    formatCurrency,
  },
}
</script>
