<template>
  <div>
    <ValidationObserver v-slot="{ invalid, handleSubmit }">
      <div class="mx-4">
        <!-- Selected Items per Service Group -->
        <div>
          <!-- Service Groups -->
          <ServiceCatalogGroup
            v-for="serviceGroup in servicesByGroup"
            :key="serviceGroup.id"
            :service-group="serviceGroup"
            :can-show-service-prices="canShowServicePrices"
          >
            <template #default="{ item }">
              <button
                class="btn btn-outline-danger my-auto"
                style="min-width: 102px; max-width: 102px;"
                @click="removeItem(item)"
              >
                Remove
              </button>
            </template>
          </ServiceCatalogGroup>
          <!-- Summary -->
          <div
            class="d-flex py-4"
          >
            <!-- Totals -->
            <div
              v-if="!empty"
              class="flex-grow-1"
            >
              <div v-if="canShowServicePrices">
                <!-- Price -->
                <div class="h4 font-weight-bold">
                  Total: {{ formatCurrency(totalPrice) }}
                </div>
              </div>
            </div>
            <!-- Browse Services Button -->
            <div
              class="d-flex align-items-center flex-column"
              :class="empty ? 'align-content-center p-3 w-100' : ''"
            >
              <button
                class="btn btn-info text-uppercase m-auto"
                @click="browseServices"
              >
                Browse Services
              </button>
              <div
                v-if="empty"
                class="text-gray-600 mt-3"
              >
                Please select pre-defined services to be added to this request!
              </div>
            </div>
          </div>
          <div
            v-if="error"
            class="alert alert-danger mb-4"
            role="alert"
          >
            {{ error }}
          </div>
          <button
            :disabled="invalid"
            class="btn btn-primary"
            @click="handleSubmit(update)"
          >
            Update
          </button>
          <button
            v-if="canAttachFile"
            class="btn btn-primary"
            @click="attachFile"
          >
            Add Attachment
          </button>
          <button
            class="btn btn-default"
            @click="$router.go(-1)"
          >
            Cancel
          </button>
        </div>
        <!-- Services Catalog Dialog -->
        <ServiceCatalogModal
          ref="servicesCatalogModal"
          :items="unselectedServices"
          :groups="groups"
          :show-prices="canShowServicePrices"
          @add-item="addItem"
        />
      </div>
    </ValidationObserver>
    <AttachFile
      ref="attachFile"
      :project="project"
    />
  </div>
</template>

<script>

import ServiceCatalogModal from './CatalogModal'
import Enumerable from 'linq'
import groupAPI from '@/api/group'
import projectAPI from '@/api/project'
import ServiceCatalogGroup from './CatalogGroup'
import AttachFile from '@/views/Project/AttachFile'
import { ValidationObserver } from 'vee-validate'
import formatCurrency from '@/filters/formatCurrency'

export default {
  components: {
    AttachFile,
    ServiceCatalogGroup,
    ServiceCatalogModal,
    ValidationObserver,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    canAttachFile: {
      type: Boolean,
      default: false,
    },
    canShowServices: {
      type: Boolean,
      required: true,
    },
    canChangeServices: {
      type: Boolean,
      required: true,
    },
    canShowServicePrices: {
      type: Boolean,
      required: true,
    },
    showCatalog: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      loading: false,
      groups: [],
      services: [],
      allServices: [],
      error: '',
    }
  },
  computed: {
    totalPrice () {
      return Enumerable.from(this.services).sum(service => {
        if (service.price === null || service.price === undefined) { return 0 }
        return service.price
      })
    },
    selectedServiceIDs () {
      return this.services.map(s => s.service.id)
    },
    unselectedServices () {
      return this.allServices.filter(s => !this.selectedServiceIDs.includes(s.service.id))
    },
    servicesByGroup () {
      return Enumerable.from(this.services)
        .groupBy(item => item.service.service_group_id)
        .select(group => {
          const serviceGroup = this.groups.find(g => g.id === group.key())
          return {
            ...serviceGroup,
            items: group.orderBy(item => item.service.name).toArray(),
          }
        })
        .orderBy(group => group.name)
        .toArray()
    },
    empty () {
      return this.services.length === 0
    },
  },
  beforeMount () {
    if (!this.canShowServices) {
      this.$router.replace({ name: 'show_project_info' })
    } else if (!this.canChangeServices) {
      this.$router.replace({ name: 'show_project_services' })
    } else {
      this.load()
    }
  },
  methods: {
    addItem (service) {
      this.services.push(service)
    },
    removeItem (item) {
      const index = this.services.findIndex(s => s.service.id === item.service.id)
      if (index >= 0) {
        this.services.splice(index, 1)
      }
    },
    browseServices () {
      this.$refs.servicesCatalogModal.show()
    },
    load () {
      this.loading = true
      const promises = [
        groupAPI.getServiceGroups(this.project.project_type.id),
      ]
      if (this.canShowServicePrices) {
        promises.push(projectAPI.getAvailableServices(this.project.id))
        promises.push(projectAPI.getServices(this.project.id))
      } else {
        promises.push(groupAPI.getShortServices(this.project.project_type.id))
      }

      Promise.all(promises).then(([groups, groupServices, projectServices]) => {
        this.groups = groups.data
        if (this.canShowServicePrices) {
          projectServices = projectServices.data
        } else {
          // clone project services so we can modify if needed
          projectServices = JSON.parse(JSON.stringify(this.project.services))
        }
        // filter out services without a group, which should only happen with old categories
        this.services = projectServices.filter(s => !!s.service.service_group_id)
        // create default project services, filtering out disabled (count === 0) or not in a group
        this.allServices = groupServices.data
          .filter(s => !(s.max === 0 || !s.service_group_id))
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(s => {
            return {
              service: s,
              price: this.getServicePrice(s, 1),
              slo: s.slo,
              count: 1,
            }
          })
        if (this.showCatalog) {
          this.browseServices()
        }
      }).catch(error => {
        this.error = error.message
      })
      this.loading = true
    },
    update () {
      projectAPI.updateServices(this.project.id, this.services).then(resp => {
        this.$emit('reload-project')
        this.$router.push({ name: 'show_project_services' })
      }).catch(error => {
        this.error = error.message
      })
    },
    attachFile (typeID) {
      this.$refs.attachFile.show(typeID)
    },
    getServicePrice (service, count) {
      if (count <= 0) {
        count = 0
      }
      // get default unit pricing
      let min = 0
      let unitPrice = service.price
      // find pricing with closest minimum count below or equal to the item count
      if (Array.isArray(service.prices) && count > 0) {
        service.prices.forEach(p => {
          if (p.min <= count && p.min > min) {
            min = p.min
            unitPrice = p.price
          }
        })
      }
      if (unitPrice === undefined) {
        unitPrice = null
      }
      if (unitPrice === null) {
        return null
      }
      return count * unitPrice
    },
    formatCurrency,
  },
}
</script>
