<template>
  <div>
    <div class="form-group">
      <label>Attachments</label>
      <table class="at-table">
        <tbody>
          <template v-for="(files, typeName) in value">
            <tr
              v-for="(file, index) in files"
              :key="typeName + '_' + index"
            >
              <td v-if="index === 0">
                {{ getTypeName(typeName) }}
              </td><td v-else />
              <td>
                <font-awesome-icon :icon="['far', 'file']" />
                {{ file.name }}
              </td>
              <td>
                <button
                  class="btn btn-danger btn-xs"
                  @click.prevent="deleteFile(typeName, index)"
                >
                  Delete
                </button>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <button
        class="btn btn-primary btn-xs"
        @click.prevent="$refs.attachmodal.show"
      >
        Add
      </button>
      <div
        v-if="attachmentTemplates && attachmentTemplates.length > 0"
        class="mt-2"
      >
        <div
          v-for="at in attachmentTemplates"
          :key="at.name"
        >
          <a
            v-if="at.template_url"
            target="_blank"
            rel="noopener noreferrer"
            :href="at.template_url"
          >
            Download {{ at.name }} Template
          </a>
          <a
            v-else-if="at.template_filename"
            :href="downloadURL(at)"
          >
            Download {{ at.name }} Template
          </a>
        </div>
      </div>
    </div>
    <AttachFormModal
      ref="attachmodal"
      :project="project"
      title="Add Attachment"
      :submit-promise="addFile"
      submit-title="Add"
    />
  </div>
</template>

<script>
import AttachFormModal from '@/views/Project/AttachFormModal'
import groupAPI from '@/api/group'

export default {
  components: {
    AttachFormModal,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    attachmentTypes: {
      type: Array,
      required: true,
    },
  },
  computed: {
    attachmentTemplates () {
      return this.attachmentTypes.filter(at => at.template_url || at.template_filename)
    },
  },
  methods: {
    downloadURL (at) {
      return process.env.VUE_APP_API_URL + groupAPI.attachmentTypeTemplateDownloadURL(at.id)
    },
    getTypeName (name) {
      const index = name.indexOf('_')
      if (index >= 0) {
        return name.substring(index + 1)
      }
      return name
    },
    cloneValue () {
      const newVal = {}
      Object.keys(this.value).forEach(val => {
        newVal[val] = this.value[val].slice()
      })
      return newVal
    },
    addFile (f) {
      const newVal = this.cloneValue()
      if (!newVal[f.type_name]) {
        newVal[f.type_name] = [f.file]
      } else {
        newVal[f.type_name].push(f.file)
      }
      this.$refs.attachmodal.cancel()
      this.$emit('input', newVal)
    },
    deleteFile (typeName, index) {
      const newVal = this.cloneValue()
      newVal[typeName].splice(index, 1)
      this.$emit('input', newVal)
    },
  },
}
</script>

<style scoped>
.at-table td {
  padding: 0px 10px 5px 5px;
}
</style>
