<template>
  <BaseFormCard
    submit-title="Submit"
    @submit="upload"
  >
    <BaseInput
      v-model="support.subject"
      :maxlength="64"
      title="Subject"
      name="subject"
      type="text"
      placeholder="Subject"
      required
      trim
    />
    <BaseSelect
      v-model="support.problem_type"
      :options="problem_types"
      title="Problem Type"
      name="problem_type"
      required
    />
    <BaseTextarea
      v-model="support.descr"
      :rows="5"
      title="Description"
      name="descr"
      required
      trim
    />
    <div class="form-group">
      <label>Attachments</label>
      <div v-if="support.files.length === 0">
        No Files Attached
      </div>
      <div
        v-for="(file, index) in support.files"
        :key="index"
      >
        <span class="support-files">
          <font-awesome-icon :icon="['far', 'file']" />
          {{ file.name }}
        </span>
        <button
          class="btn btn-danger btn-xs"
          @click.prevent="deleteFile(index)"
        >
          Delete
        </button>
      </div>
      <input
        ref="fileinput"
        type="file"
        multiple
        class="hidden"
        hidden
        @change="addFile"
      >
    </div>
    <div class="form-group">
      <button
        type="button"
        class="btn btn-primary"
        @click.prevent="selectFiles"
      >
        Add Files
      </button>
    </div>
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
/**
 * View to enter in support requests.
 */
import FieldError from '@/components/FieldError'
import supportAPI from '@/api/support'

export default {
  components: {
    FieldError,
  },
  data () {
    return {
      support: {
        subject: '',
        problem_type: '',
        descr: '',
        files: [],
      },
      error: '',
      problem_types: ['GUI - Dashboard', 'GUI - Admin', 'GUI - Project', 'GUI - Report', 'Other'],
    }
  },
  methods: {
    addFile (event) {
      for (const file of event.target.files) {
        this.support.files.push(file)
      }
      this.$refs.fileinput.value = ''
      this.error = ''
    },
    deleteFile (index) {
      this.support.files.splice(index, 1)
    },
    selectFiles () {
      this.$refs.fileinput.click()
    },
    upload () {
      const formData = new FormData()
      formData.append('subject', this.support.subject)
      formData.append('problem_type', this.support.problem_type)
      formData.append('descr', this.support.descr)

      for (const file of this.support.files) {
        formData.append(file.name, file)
      }
      supportAPI.createSupportRequest(formData)
        .then(response => {
          this.$parent.$toasted.success('Support case submitted')
          this.$router.push('/support')
        })
        .catch(error => {
          this.error = error.message
        })
    },
  },
}
</script>
