<template>
  <div>
    <h3>Project: {{ projectId }}</h3>

    <BaseCard>
      <template #header>
        Attachment
      </template>
      <template #default>
        <table
          v-if="at.id"
          class="info-table"
        >
          <tr>
            <td>Attachment Type</td>
            <td>{{ at.type_name }}</td>
          </tr>
          <tr>
            <td>Filename</td>
            <td>{{ at.filename }}</td>
          </tr>
          <tr>
            <td>Uploaded by</td>
            <td>{{ at.creator.name }}</td>
          </tr>
          <tr>
            <td>Uploaded on</td>
            <td>{{ formatDateTime(at.created_on) }}</td>
          </tr>
        </table>
        <br>
        <a
          v-if="at.id"
          :href="downloadLink"
          class="btn btn-primary"
        >
          Download
        </a>
      </template>
    </BaseCard>
    <FieldError v-model="error" />
  </div>
</template>

<script>
/**
 * View to display the details for a Project Attachemt, including download link
 */
import FieldError from '@/components/FieldError'
import projectAPI from '@/api/project'
import formatDateTime from '@/filters/formatDateTime'

export default {
  components: {
    FieldError,
  },
  props: {
    projectId: {
      type: [String, Number],
      required: true,
    },
    attachmentId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      error: '',
      at: {
        id: '',
        filename: '',
      },
    }
  },
  computed: {
    downloadLink () {
      return process.env.VUE_APP_API_URL + projectAPI.attachmentDownloadURL(this.attachmentId)
    },
  },
  beforeMount () {
    projectAPI.getAttachment(this.attachmentId).then(resp => {
      // window.location.href = this.downloadLink(this.attachmentId)
      this.at = resp.data
    }).catch(error => {
      this.error = error.message
    })
  },
  methods: {
    formatDateTime,
  },
}
</script>
