<template>
  <div class="mx-3">
    <FieldError
      v-if="!loaded"
      v-model="error"
    />
    <template v-else>
      <h4>Submit Quote</h4>
      <FormGroup
        v-if="panOwnerName"
        title="PAN Owner"
      >
        <p>{{ panOwnerName }}</p>
      </FormGroup>
      <FormGroup
        v-if="panCode"
        title="PAN Code"
      >
        <p>{{ panCode }}</p>
      </FormGroup>
      <div
        v-if="!hasServices"
        class="row"
      >
        <div class="col-md-6">
          <BaseForm
            submit-title="Submit"
            @submit="submit"
          >
            <BaseSelect
              v-if="!useServices"
              v-model="financials.category"
              :options="services"
              title="Category"
              name="category"
              track-by="id"
              label="name"
              placeholder="Select Category"
              required
            />
            <BaseTextarea
              v-model="financials.scope"
              :rows="10"
              title="Scope"
              name="scope"
              required
              trim
            />
            <BaseInputPrice
              v-model.number="financials.price"
              title="Price"
              name="price"
              required
            />
            <FieldError v-model="error" />
          </BaseForm>
        </div>
      </div>
      <div v-else>
        <div class="font-weight-bold mt-2 mb-2">
          Scope
        </div>
        <table class="mb-2">
          <tr
            v-for="s in projectServices"
            :key="s.id"
          >
            <td class="pr-3 align-top text-nowrap">
              {{ s.count }} x {{ s.service.name }}
            </td>
            <td class="pr-3 align-top">
              ( {{ s.service.descr }} )
              <div
                v-if="s.scope"
                class="rounded-lg bg-gray-200 px-4 py-1 mb-1"
              >
                <CatalogItemInfo
                  :info="s.scope"
                  disable-markdown
                />
              </div>
            </td>
            <td class="pr-3 align-top">
              {{ s.completion }} {{ s.completion ? 'days' : '' }}
            </td>
            <td class="pr-3 text-right align-top">
              {{ formatCurrency(s.price) }}
            </td>
            <td class="align-top">
              <button
                v-if="canQuoteService(s)"
                class="btn btn-sm btn-primary ml-2"
                @click="quoteService(s)"
              >
                Update
              </button>
            </td>
          </tr>
        </table>
        <BaseForm
          v-if="financials.price !== null"
          submit-title="Submit"
          @submit="submit"
        >
          <BaseInputPrice
            v-model.number="financials.price"
            title="Price"
            name="price"
            readonly
            required
          />
          <FieldError v-model="error" />
        </BaseForm>
      </div>
    </template>
    <ProjectFinancialsServiceModal
      ref="serviceModal"
      @service-update="getData"
    />
  </div>
</template>

<script>
/**
 * View to show financials for a project.
 */
import projectAPI from '@/api/project'
import groupAPI from '@/api/group'
import FieldError from '@/components/FieldError'
import ProjectFinancialsServiceModal from '@/views/Project/Financial/ProjectFinancialsServiceModal'
import CatalogItemInfo from '@/views/Project/Services/CatalogItemInfo'
import formatCurrency from '@/filters/formatCurrency'
import FormGroup from '@/components/forms/FormGroup'

export default {
  components: {
    CatalogItemInfo,
    ProjectFinancialsServiceModal,
    FieldError,
    FormGroup,
  },
  props: {
    /** project */
    project: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      financials: {
        scope: null,
        price: null,
        has_services: false,
        category: null,
      },
      loaded: false,
      services: [],
      projectServices: [],
      quotedState: {},
      error: '',
    }
  },
  computed: {
    useServices () {
      return this.project.project_type.use_subcats
    },
    hasServices () {
      return this.useServices && this.projectServices && this.projectServices.length > 0
    },
    panOwnerName () {
      if (!this.project.roles) {
        return ''
      }
      const po = this.project.roles.find(r => r.usr_role.name.includes('PAN Owner'))
      return po ? po.usr.name : ''
    },
    panCode () {
      return this.project.p4p.pan_code
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      const promises = [
        projectAPI.getFinancials(this.project.id),
        groupAPI.getProjectStates(this.project.project_type.id),
      ]
      if (this.useServices) {
        promises.push(projectAPI.getServices(this.project.id))
      } else {
        promises.push(groupAPI.getShortServices(this.project.project_type.id))
      }
      Promise.all(promises).then(([financials, states, services]) => {
        // clear category when using services
        if (this.useServices) {
          financials.data.category = null
        }
        this.financials = financials.data
        this.quotedState = states.data.find(s => s.name === 'Quoted')
        if (this.useServices) {
          // get project services
          this.projectServices = services.data
        } else {
          // get group categories/services
          this.services = services.data.filter(s => s.max !== 0)
        }
        this.loaded = true
      }).catch(error => {
        this.error = error.message
      })
    },
    submit () {
      if (!this.quotedState.id) {
        this.error = 'Quoted state not found'
        return
      }
      const financials = Object.assign({}, this.financials)
      financials.has_services = this.hasServices
      projectAPI.updateFinancials(this.project.id, financials).then(resp => {
        return projectAPI.updateState(this.project.id, this.quotedState)
      }).then(resp => {
        this.$emit('reload-project')
        this.$router.push({ name: 'project_financials' })
      }).catch(error => {
        this.error = error.message
      })
    },
    quoteService (s) {
      this.$refs.serviceModal.edit(s)
    },
    canQuoteService (s) {
      return s.price === null || s.price === undefined ||
        this.getServicePrice(s.service, s.count) === null
    },
    getServicePrice (service, count) {
      if (count <= 0) {
        count = 0
      }
      // get default unit pricing
      let min = 0
      let unitPrice = service.price
      // find pricing with closest minimum count below or equal to the item count
      if (Array.isArray(service.prices) && count > 0) {
        service.prices.forEach(p => {
          if (p.min <= count && p.min > min) {
            min = p.min
            unitPrice = p.price
          }
        })
      }
      if (unitPrice === undefined) {
        unitPrice = null
      }
      if (unitPrice === null) {
        return null
      }
      return count * unitPrice
    },
    formatCurrency,
  },
}
</script>

<style lang="scss" scoped>
table {
  tr {
    height: 30px;
  }
}
</style>
