
const UserList = () => import(/* webpackChunkName: "admin" */ '@/views/User/UserList')
const UserInfo = () => import(/* webpackChunkName: "admin" */ '@/views/User/UserInfo')
const UserCreate = () => import(/* webpackChunkName: "admin" */ '@/views/User/UserCreate')

const TeamList = () => import(/* webpackChunkName: "admin" */ '@/views/User/TeamList')
const TeamCreate = () => import(/* webpackChunkName: "admin" */ '@/views/User/TeamCreate')
const TeamUpdate = () => import(/* webpackChunkName: "admin" */ '@/views/User/TeamUpdate')
const TeamMemberList = () => import(/* webpackChunkName: "admin" */ '@/views/User/TeamMemberList')
const TeamMemberCreate = () => import(/* webpackChunkName: "admin" */ '@/views/User/TeamMemberCreate')
const TeamMemberUpdate = () => import(/* webpackChunkName: "admin" */ '@/views/User/TeamMemberUpdate')

const TeamSurveyQuestionList = () => import(/* webpackChunkName: "admin" */ '@/views/User/TeamSurveyQuestionList')
const TeamSurveyQuestionCreate = () => import(/* webpackChunkName: "admin" */ '@/views/User/TeamSurveyQuestionCreate')

const TeamSurveyResultList = () => import(/* webpackChunkName: "admin" */ '@/views/User/TeamSurveyResultList')

const HolidayList = () => import(/* webpackChunkName: "admin" */ '@/views/Admin/HolidayList')
const HolidayCreate = () => import(/* webpackChunkName: "admin" */ '@/views/Admin/HolidayCreate')

const teamTitle = 'Teams'

export default [
  {
    path: 'teams',
    name: 'team_list',
    component: TeamList,
    meta: {
      title: teamTitle,
      crumbs: [{ title: teamTitle }],
    },
  },
  {
    path: 'teams/create',
    name: 'team_create',
    component: TeamCreate,
    meta: {
      crumbs: [
        { route: 'team_list', title: teamTitle },
        { title: 'Create' },
      ],
    },
  },
  {
    path: 'teams/:id/update',
    name: 'team_update',
    props: true,
    component: TeamUpdate,
    meta: {
      crumbs: [
        { route: 'team_list', title: teamTitle },
        { getter: 'user/teamName' },
      ],
    },
  },
  {
    path: 'teams/:teamId/members',
    name: 'team_member_list',
    props: true,
    component: TeamMemberList,
    meta: {
      crumbs: [
        { route: 'team_list', title: teamTitle },
        { getter: 'user/teamName' },
      ],
    },
  },
  {
    path: 'teams/:teamId/members/create',
    name: 'team_member_create',
    props: true,
    component: TeamMemberCreate,
    meta: {
      crumbs: [
        { route: 'team_list', title: teamTitle },
        { getter: 'user/teamName' },
        { title: 'Add Member' },
      ],
    },
  },
  {
    path: 'teams/:teamId/members/:id/update',
    name: 'team_member_update',
    props: true,
    component: TeamMemberUpdate,
    meta: {
      crumbs: [
        { route: 'team_list', title: teamTitle },
        { getter: 'user/teamName' },
        { title: 'Update Member' },
      ],
    },
  },
  {
    path: 'teams/:teamId/survey_questions',
    name: 'team_survey_question_list',
    props: true,
    component: TeamSurveyQuestionList,
    meta: {
      crumbs: [
        { route: 'team_list', title: teamTitle },
        { getter: 'user/teamName' },
      ],
    },
  },
  {
    path: 'teams/:teamId/survey_questions/create',
    name: 'team_survey_question_create',
    props: true,
    component: TeamSurveyQuestionCreate,
    meta: {
      crumbs: [
        { route: 'team_list', title: teamTitle },
        { getter: 'user/teamName' },
        { title: 'Create Question' },
      ],
    },
  },
  {
    path: 'teams/:teamId/survey_questions/:id/update',
    name: 'team_survey_question_update',
    props: true,
    component: TeamSurveyQuestionCreate,
    meta: {
      crumbs: [
        { route: 'team_list', title: teamTitle },
        { getter: 'user/teamName' },
        { title: 'Update Question' },
      ],
    },
  },
  {
    path: 'teams/:teamId/survey_results',
    name: 'team_survey_result_list',
    props: true,
    component: TeamSurveyResultList,
    meta: {
      crumbs: [
        { route: 'team_list', title: teamTitle },
        { getter: 'user/teamName' },
      ],
    },
  },
  {
    path: 'users',
    name: 'user_list',
    component: UserList,
    meta: {
      title: 'Users',
      crumbs: [{ title: 'Users' }],
    },
  },
  {
    path: 'users/create',
    name: 'user_create',
    component: UserCreate,
    meta: {
      crumbs: [
        { route: 'user_list', title: 'Users' },
        { title: 'Create' },
      ],
    },
  },
  {
    path: 'users/:id',
    name: 'user_info',
    props: true,
    component: UserInfo,
    meta: {
      crumbs: [
        { route: 'user_list', title: 'Users' },
        { route: 'user_info', getter: 'user/userName' },
      ],
    },
  },
  {
    path: 'users/:id/update',
    name: 'user_update',
    props: true,
    component: UserCreate,
    meta: {
      crumbs: [
        { route: 'user_list', title: 'Users' },
        { route: 'user_info', getter: 'user/userName' },
        { title: 'Update' },
      ],
    },
  },
  {
    path: 'holidays',
    name: 'holiday_list',
    component: HolidayList,
    meta: {
      title: 'Holidays',
      crumbs: [{ title: 'Holidays' }],
    },
  },
  {
    path: 'holidays/create',
    name: 'holiday_create',
    component: HolidayCreate,
    meta: {
      crumbs: [
        { route: 'holiday_list', title: 'Holidays' },
        { title: 'Create' },
      ],
    },
  },
  {
    path: 'holidays/:id/update',
    name: 'holiday_update',
    props: true,
    component: HolidayCreate,
    meta: {
      crumbs: [
        { route: 'holiday_list', title: 'Holidays' },
        { title: 'Update' },
      ],
    },
  },
]
