<template>
  <CustomFilterModal
    :open.sync="open"
    :filter-active="filterActive"
    :auto-hide="false"
    @show="updateOptions"
    @update="update"
  >
    <form
      class="filter-form"
      @submit.prevent
    >
      <BaseInputDate
        v-model="from"
        title="From"
        name="from"
        :max="to"
      />
      <BaseInputDate
        v-model="to"
        title="To (exclusive)"
        name="to"
        :min="from"
      />
    </form>
  </CustomFilterModal>
</template>

<script>
/**
 * Modal to allow updating the User assigned to a Usr Role for a Project.
 */
import CustomFilterModal from '@/components/CustomFilterModal'

export default {
  components: {
    CustomFilterModal,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      open: false,
      from: '',
      to: '',
    }
  },
  computed: {
    fromQueryName () {
      return this.field.name + '_gte'
    },
    toQueryName () {
      return this.field.name + '_lt'
    },
    filterActive () {
      const q = Object.keys(this.$route.query)
      return q.includes(this.fromQueryName) || q.includes(this.toQueryName)
    },
  },
  methods: {
    updateOptions () {
      this.from = this.$route.query[this.field.name + '_gte']
      this.to = this.$route.query[this.field.name + '_lt']
    },
    update () {
      this.open = false
      const query = { ...this.$route.query }
      delete query.page
      if (!this.from) {
        delete query[this.fromQueryName]
      } else {
        query[this.fromQueryName] = this.from
      }
      if (!this.to) {
        delete query[this.toQueryName]
      } else {
        query[this.toQueryName] = this.to
      }
      this.$router.push({ path: this.$route.path, query: query })
    },
  },
}
</script>
