
import Project from '@/views/Project/Project'
import ProjectInfo from '@/views/Project/ShowProjectInfo'
import ProjectServices from '@/views/Project/Services/Show'
import ProjectServicesUpdate from '@/views/Project/Services/Update'
import ProjectTimeline from '@/views/Project/Timeline/ShowProjectTimeline'
import ProjectMilestones from '@/views/Project/Milestone/ShowProjectMilestones'
import ProjectBookings from '@/views/Project/Booking/ShowProjectBookings'
import ProjectFinancials from '@/views/Project/Financial/ProjectFinancials'
import ProjectFinancialsSubmit from '@/views/Project/Financial/ProjectFinancialsSubmit'
import ProjectList from '@/views/Project/ProjectList'
import ProjectCreate from '@/views/Project/ProjectCreate'
import ProjectAssignedMilestones from '@/views/Project/Milestone/ProjectAssignedMilestones'
import ProjectAttachment from '@/views/Project/ProjectAttachment'

const AIMList = () => import(/* webpackChunkName: "aims" */ '@/views/AIM/AIMList')
const AIMShow = () => import(/* webpackChunkName: "aims" */ '@/views/AIM/AIMShow')
const VersionList = () => import(/* webpackChunkName: "aims" */ '@/views/AIM/VersionList')
const VersionDiffShow = () => import(/* webpackChunkName: "aims" */ '@/views/AIM/VersionDiffShow')
const VersionShow = () => import(/* webpackChunkName: "aims" */ '@/views/AIM/VersionShow')
const ProjectBookingCreate = () => import(/* webpackChunkName: "bookings" */ '@/views/Booking/ProjectBookingCreate')

const projectPath = 'projects'
const projectTitle = 'Projects'
const createProjectTitle = 'New Project'

export default [
  {
    path: 'project/*',
    redirect: to => {
      const path = to.path.replace(/project/, projectPath)
      return { path: path, query: to.query }
    },
  },
  {
    path: projectPath,
    name: 'projects',
    component: ProjectList,
    meta: {
      title: projectTitle,
      crumbs: [{ title: projectTitle }],
    },
  },
  {
    path: projectPath + '/create',
    name: 'create_project',
    component: ProjectCreate,
    meta: {
      title: createProjectTitle,
      crumbs: [
        { route: 'projects', title: projectTitle },
        { title: 'Create' },
      ],
    },
  },
  {
    path: projectPath + '/:projectId',
    component: Project,
    props: true,
    meta: {
      crumbs: [
        { route: 'projects', title: projectTitle },
        { route: 'show_project_info', param: 'projectId' },
      ],
    },
    children: [
      {
        path: '',
        name: 'show_project_info',
        component: ProjectInfo,
        props: true,
      },
      // AIM
      {
        path: 'aims',
        name: 'list_aims',
        component: AIMList,
        props: true,
        meta: {
          crumbs: [{ title: 'AIMs' }],
        },
      },
      {
        path: 'aims/:aimId',
        component: AIMShow,
        props: true,
        children: [
          {
            path: '',
            name: 'list_aim_versions',
            component: VersionList,
            props: true,
            meta: {
              crumbs: [
                { route: 'list_aims', title: 'AIMs' },
                { param: 'aimId' },
              ],
            },
          },
          {
            path: 'versions/:number/sheet/:sheetNumber',
            name: 'list_aim_rules',
            component: VersionShow,
            props: true,
            meta: {
              shrink: true,
              crumbs: [
                { route: 'list_aims', title: 'AIMs' },
                { route: 'list_aim_versions', param: 'aimId' },
                { param: 'number' },
              ],
            },
          },
          {
            path: 'diff/:num1/:num2',
            name: 'aimsdiff',
            component: VersionDiffShow,
            props: true,
            meta: {
              shrink: true,
              crumbs: [
                { route: 'list_aims', title: 'AIMs' },
                { route: 'list_aim_versions', param: 'aimId' },
                { title: 'Compare' },
              ],
            },
          },
        ],
      },
      // services
      {
        path: 'services',
        name: 'show_project_services',
        component: ProjectServices,
        props: true,
        meta: {
          crumbs: [{ title: 'Services' }],
        },
      },
      {
        path: 'services/update',
        name: 'update_project_services',
        component: ProjectServicesUpdate,
        props: true,
        meta: {
          crumbs: [
            { route: 'show_project_services', title: 'Services' },
            { title: 'Update' },
          ],
        },
      },
      // timeline
      {
        path: 'timeline',
        name: 'show_project_timeline',
        component: ProjectTimeline,
        props: true,
        meta: {
          crumbs: [{ title: 'Timeline' }],
        },
      },
      // milestones
      {
        path: 'milestones',
        name: 'show_project_milestones',
        component: ProjectMilestones,
        props: true,
        meta: {
          crumbs: [{ title: 'Milestones' }],
        },
      },
      {
        path: 'milestones/update',
        name: 'update_project_milestones',
        props: true,
        component: ProjectAssignedMilestones,
        meta: {
          crumbs: [
            { route: 'show_project_milestones', title: 'Milestones' },
            { title: 'Update' },
          ],
        },
      },
      // booking
      {
        path: 'bookings/:bookingTypeId?',
        name: 'show_project_bookings',
        component: ProjectBookings,
        props: true,
        meta: {
          shrink: true,
          crumbs: [{ title: 'Bookings' }],
        },
      },
      {
        path: 'bookings/:bookingTypeId/create',
        name: 'project_booking_create',
        props: true,
        component: ProjectBookingCreate,
        meta: {
          crumbs: [{ title: 'Bookings' }],
        },
      },
      // financials
      {
        path: 'financials',
        name: 'project_financials',
        component: ProjectFinancials,
        props: true,
        meta: {
          crumbs: [{ title: 'Financials' }],
        },
      },
      {
        path: 'financials/submit',
        name: 'project_financials_submit',
        component: ProjectFinancialsSubmit,
        props: true,
        meta: {
          crumbs: [
            { route: 'project_financials', title: 'Financials' },
            { title: 'Submit' },
          ],
        },
      },
    ],
  },
  {
    path: projectPath + '/:projectId/attachment/:attachmentId',
    name: 'project_attachment',
    props: true,
    component: ProjectAttachment,
    meta: {
      crumbs: [
        { route: 'projects', title: projectTitle },
        { route: 'show_project_info', param: 'projectId' },
        { title: 'Attachment' },
      ],
    },
  },
  {
    path: projectPath + '/:projectId/update',
    name: 'update_project',
    props: true,
    component: ProjectCreate,
    meta: {
      crumbs: [
        { route: 'projects', title: projectTitle },
        { route: 'show_project_info', param: 'projectId' },
        { title: 'Update' },
      ],
    },
  },
]
