<template>
  <div>
    <ul class="timeline">
      <li class="timeline-comment-btn">
        <button
          v-if="canCreateUpdate"
          class="btn btn-primary btn-xs"
          @click="addComment"
        >
          Add Comment
        </button>
        <button
          v-if="canAttachFile"
          class="btn btn-primary btn-xs"
          @click="attachFile"
        >
          Add Attachment
        </button>
      </li>
      <!-- timeline time label -->
      <li
        v-if="showDateLabel"
        class="time-label"
      >
        <span class="bg-green">{{ formatDate(new Date) }}</span>
      </li>
      <!-- timeline item -->
      <!-- eslint-disable-next-line vue/require-v-for-key -->
      <li
        v-for="line in timeline"
        :class="{ 'time-label': line.project_state }"
      >
        <template v-if="line.project_state">
          <span class="bg-green">{{ formatDateTime(line.created_on) }}</span>
          <template v-if="line.project_state !== line.prev_state">
            <span class="bg-green">{{ line.project_state }}</span>
            <span>set by {{ line.creator_name }}</span>
          </template>
          <span v-else-if="line.on_hold">put on hold by {{ line.creator_name }}</span>
          <span v-else>hold removed by {{ line.creator_name }}</span>
          <pre
            v-if="line.comment"
            style="margin: 15px 15px 10px 60px;"
          ><div class="timeline-body">{{ line.comment }}</div></pre>
        </template>
        <template v-else>
          <font-awesome-icon
            icon="user"
            transform="shrink-8"
            class="bg-blue"
          />
          <div class="timeline-item">
            <span class="time">
              <font-awesome-icon :icon="['far', 'clock']" />
              {{ formatDateTime(line.created_on) }}
            </span>
            <template v-if="line.comment">
              <h3 class="timeline-header">
                {{ commentHeader(line) }}
              </h3>
              <pre><div class="timeline-body">{{ line.comment }}</div></pre>
            </template>
            <template v-else-if="line.attachment">
              <h3 class="timeline-header">
                {{ line.attachment_type }} uploaded by {{ line.creator_name }}
              </h3>
              <div class="timeline-body">
                <font-awesome-icon :icon="['far', 'file']" />
                <a :href="downloadLink(line.attachment_id)">
                  {{ line.attachment }}
                </a>
              </div>
            </template>
            <template v-else-if="line.milestone_name">
              <h3 class="timeline-header">
                Milestone '{{ line.milestone_name }}' completed by {{ line.creator_name }}
              </h3>
            </template>
          </div>
        </template>
      </li>
      <!-- END timeline item -->
    </ul>
    <CreateProjectUpdate
      ref="createUpdate"
      :project="project"
      @create-update="getData"
    />
    <AttachFile
      ref="attachFile"
      :project="project"
      @file-upload="getData"
    />
  </div>
</template>

<script>
/**
 * View to show a Project's Timeline.
 * The timeline includes
 *  - project updates/comments
 *  - state changes
 *  - milestone changes
 *  - attachment uploads
 */
import CreateProjectUpdate from './CreateProjectUpdate'
import AttachFile from '@/views/Project/AttachFile'
import projectAPI from '@/api/project'
import formatDateTime from '@/filters/formatDateTime'
import formatDate from '@/filters/formatDate'

export default {
  components: {
    AttachFile,
    CreateProjectUpdate,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    canCreateUpdate: {
      type: Boolean,
      required: true,
    },
    canAttachFile: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      timeline: [],
      error: '',
    }
  },
  computed: {
    showDateLabel () {
      // show top date label if first item is not a project state change
      return this.timeline && this.timeline.length > 0 && !this.timeline[0].project_state
    },
  },
  watch: {
    project: function () {
      this.getData()
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      this.error = ''
      projectAPI.getTimeline(this.project.id).then(timeline => {
        const tl = timeline.data
        let prevState
        tl.forEach(item => {
          if (item.project_state) {
            if (prevState) {
              item.prev_state = prevState
            }
            prevState = item.project_state
          }
        })
        tl.reverse()
        this.timeline = tl
      }).catch(error => {
        this.error = error.message
      })
    },
    commentHeader (data) {
      let h = 'Updated by ' + data.creator_name
      if (data.recipients && data.recipients.length > 0) {
        h = '[Email sent to ' + data.recipients.join(', ') + '] ' + h
      }
      return h
    },
    downloadLink (id) {
      return process.env.VUE_APP_API_URL + projectAPI.attachmentDownloadURL(id)
    },
    addComment () {
      this.$refs.createUpdate.show()
    },
    attachFile (typeID) {
      this.$refs.attachFile.show(typeID)
    },
    formatDateTime,
    formatDate,
  },
}
</script>

<style scoped>
.bg-blue,
.bg-green {
  color: #fff !important;
}
.bg-blue {
  background-color: #0073b7 !important;
}
.bg-green {
  background-color: #00a65a !important;
}
.timeline {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}
.timeline:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  background: #ddd;
  left: 31px;
  margin: 0;
  border-radius: 2px;
}
.timeline > .timeline-comment-btn {
  margin-right: 25px;
  position: absolute;
  right: 0;
  z-index: 1;
}
.timeline > li {
  position: relative;
  margin-right: 10px;
  margin-bottom: 15px;
}
.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}
.timeline > li:after {
  clear: both;
}
.timeline > li > .timeline-item {
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin-top: 0;
  background: #fff;
  color: #444;
  margin-left: 60px;
  margin-right: 15px;
  padding: 0;
  position: relative;
}
.timeline > li > .timeline-item > pre {
  white-space: pre-wrap;
}
.timeline > li > .timeline-item > .time {
  color: #999;
  float: right;
  padding: 10px;
  font-size: 12px;
}
.timeline > li > .timeline-item > .timeline-header {
  margin: 0;
  color: #555;
  border-bottom: 1px solid #f4f4f4;
  padding: 10px;
  font-size: 16px;
  line-height: 1.1;
}
.timeline > li > .timeline-item > .timeline-header > a {
  font-weight: 600;
}
.timeline > li > .timeline-item > .timeline-body,
.timeline > li > .timeline-item > .timeline-footer {
  padding: 10px;
}
.timeline > li > .fa,
.timeline > li > .fa-user,
.timeline > li > .glyphicon,
.timeline > li > .ion {
  width: 30px;
  height: 30px;
  font-size: 15px;
  line-height: 30px;
  position: absolute;
  color: #666;
  background: #d2d6de;
  border-radius: 50%;
  text-align: center;
  left: 18px;
  top: 0;
}
.timeline > .time-label > span {
  padding: 5px;
  display: inline-block;
  background-color: #fff;
  border-radius: 4px;
}
.timeline-inverse > li > .timeline-item {
  background: #f0f0f0;
  border: 1px solid #ddd;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.timeline-inverse > li > .timeline-item > .timeline-header {
  border-bottom-color: #ddd;
}
.timeline-footer a.btn {
  margin-right: 10px;
}
</style>
