<template>
  <ValidationObserver
    v-slot="{ handleSubmit }"
    disabled
  >
    <form @submit.prevent="handleSubmit(submit)">
      <slot />
      <FieldError v-model="error" />
      <div class="form-group">
        <slot name="buttons">
          <BaseFormButton
            type="submit"
            :is-loading="isLoading || loading"
          >
            {{ submitTitle }}
          </BaseFormButton>
          <BaseFormButton
            colour="default"
            @click.prevent="$emit('cancel')"
          >
            {{ cancelTitle }}
          </BaseFormButton>
        </slot>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import FieldError from '@/components/FieldError'
import { ValidationObserver } from 'vee-validate'

export default {
  components: {
    FieldError,
    ValidationObserver,
  },
  props: {
    submitTitle: {
      type: String,
      default: 'Submit',
    },
    cancelTitle: {
      type: String,
      default: 'Cancel',
    },
    submitPromise: {
      type: Function,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isLoading: false,
      error: '',
    }
  },
  methods: {
    submit () {
      this.error = ''
      this.$emit('submit')
      if (!this.submitPromise) {
        return
      }
      if (this.isLoading) {
        return
      }
      this.isLoading = true
      Promise.resolve(this.submitPromise()).then(resp => {
        this.isLoading = false
      }).catch(error => {
        this.error = error.message
        this.isLoading = false
      })
    },
  },
}
</script>
