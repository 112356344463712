var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-grow-1",staticStyle:{"min-height":"0"}},[(_vm.subTypes && _vm.subTypes.length > 0 )?_c('ListTemplate',{ref:"listTemplate",attrs:{"fields":_vm.fields,"update-action":false,"api-url":_vm.apiURL,"per-page":20,"default-filters":_vm.defaultFilters,"default-sort":"start,id","title":((_vm.bookingType.title) + " Bookings"),"csv-download":"bookings.csv","select-columns":"","select-columns-key":_vm.selectColumnsKey},scopedSlots:_vm._u([{key:"list-header",fn:function(){return [_vm._t("booking-header")]},proxy:true},{key:"project_id",fn:function(ref){
var rowData = ref.rowData;
return [(rowData.project_id)?_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: rowData.project_name,
          placement: 'top-center',
          classes: ['proj-name'],
          offset: '5',
        }),expression:"{\n          content: rowData.project_name,\n          placement: 'top-center',\n          classes: ['proj-name'],\n          offset: '5',\n        }"}],attrs:{"to":_vm.projectLink(rowData)}},[_vm._v("\n        "+_vm._s(rowData.project_id)+"\n      ")]):_c('div',[_vm._v("\n        "+_vm._s(rowData.project_name)+"\n      ")])]}},{key:"booking_status",fn:function(ref){
        var rowData = ref.rowData;
return [_c('span',[_vm._v("\n        "+_vm._s(rowData.booking_status)+"\n      ")]),_vm._v(" "),(_vm.canApprovePending(rowData))?_c('EditIcon',{staticClass:"table-icon",attrs:{"title":"Approve"},on:{"click":function($event){return _vm.approvePending(rowData)}}}):(_vm.canCancel(rowData))?_c('CancelIcon',{staticClass:"table-icon",attrs:{"title":"Cancel"},on:{"click":function($event){return _vm.confirmCancel(rowData)}}}):_vm._e()]}},{key:"review_status",fn:function(ref){
        var rowData = ref.rowData;
return [(!rowData.review_status || rowData.booking_status === 'pending')?_c('span',[_vm._v("N/A")]):_c('span',[_c('span',{staticClass:"clickable",on:{"click":function($event){return _vm.$refs.pbreviewlist.show(rowData.id)}}},[_vm._v("\n          "+_vm._s(rowData.review_status)+"\n          "),(rowData.review_count > 1)?[_vm._v("("+_vm._s(rowData.review_count)+")")]:_vm._e()],2),_vm._v(" "),(_vm.canReview(rowData))?_c('EditIcon',{staticClass:"table-icon",attrs:{"title":"Review"},on:{"click":function($event){return _vm.reviewBooking(rowData)}}}):_vm._e()],1)]}},{key:"child_review_status",fn:function(ref){
        var rowData = ref.rowData;
return [(!rowData.child_review_status || rowData.booking_status === 'pending')?_c('span',[_vm._v("N/A")]):_c('span',[_c('span',{staticClass:"clickable",on:{"click":function($event){return _vm.$refs.pbreviewlist.show(rowData.child_id)}}},[_vm._v("\n          "+_vm._s(rowData.child_review_status)+"\n          "),(rowData.child_review_count > 1)?[_vm._v("("+_vm._s(rowData.child_review_count)+")")]:_vm._e()],2),_vm._v(" "),(_vm.canReview(_vm.childBooking(rowData)))?_c('EditIcon',{staticClass:"table-icon",attrs:{"title":"Review"},on:{"click":function($event){_vm.reviewBooking(_vm.childBooking(rowData))}}}):_vm._e()],1)]}},_vm._l((_vm.customFields),function(f){return {key:f.name,fn:function(ref){
        var rowData = ref.rowData;
return [(_vm.isUrl(f.name, rowData))?_c('a',{key:("link_" + (f.name)),attrs:{"target":"_blank","rel":"noopener noreferrer","href":rowData.data[f.name],"title":rowData.data[f.name]}},[_c('LinkIcon',{staticClass:"link-icon",attrs:{"title":rowData.data[f.name]}})],1):_c('span',{key:("span_" + (f.name))},[_vm._v("\n        "+_vm._s(_vm.formatCustomData(rowData.data[f.name]))+"\n      ")]),_vm._v(" "),(_vm.canEditField(f.name, rowData))?_c('EditIcon',{key:f.name,staticClass:"table-icon",attrs:{"title":("Edit " + (f.title))},on:{"click":function($event){return _vm.editField(f.name, rowData)}}}):_vm._e()]}}})],null,true)}):_vm._e(),_vm._v(" "),_c('FieldError',{staticClass:"mt-2",model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}}),_vm._v(" "),_c('ProjectBookingStatus',{ref:"pbs",on:{"updated":_vm.refreshTable}}),_vm._v(" "),_c('ProjectBookingPropEdit',{ref:"pbprop",on:{"updated":_vm.refreshTable}}),_vm._v(" "),_c('ConfirmDialog',{ref:"dialog",on:{"confirmed":_vm.cancelBooking}}),_vm._v(" "),_c('ProjectBookingReviewTimeline',{ref:"pbreviewlist"}),_vm._v(" "),_c('ProjectBookingReview',{ref:"pbr",attrs:{"subtypes":_vm.subTypes},on:{"updated":_vm.refreshTable}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }