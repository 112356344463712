<template>
  <LoginForm
    submit-label="Change Password"
    :submit="resetPass"
    :disable-submit="disableSubmit"
    :error-msg="errorMsg"
  >
    <p>
      Change Account Password
    </p>
    <p
      v-if="expired"
      class="text-warning"
    >
      Password expired, please change
    </p>
    <BaseInput
      v-model="username"
      title="Username"
      name="username"
    />
    <BaseInputPassword
      v-model="oldPass"
      title="Old Password"
      name="oldPass"
    />
    <BaseInputPassword
      v-model="newPass"
      title="New Password"
      name="password"
      confirm
    />
  </LoginForm>
</template>

<script>
/**
 * view for resetting password for user account
 */
import LoginForm from './LoginForm'
import sessionApi from '@/api/session'

export default {
  components: {
    LoginForm,
  },
  props: {
    redirect: {
      type: String,
      default: '/',
    },
    user: {
      type: String,
      default: '',
    },
    expired: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      error: '',
      username: '',
      oldPass: '',
      newPass: '',
    }
  },
  computed: {
    disableSubmit () {
      return !this.username || !this.oldPass || !this.newPass
    },
  },
  beforeMount () {
    this.username = this.user
  },
  methods: {
    resetPass () {
      return sessionApi.changePassword(this.username, this.oldPass, this.newPass).then(response => {
        this.$router.replace({ name: 'login', params: { user: this.username, redirect: this.redirect } })
      })
    },
  },
}
</script>
