const Doc = () => import(/* webpackChunkName: "doc" */ '@/views/Doc')

export default [
  {
    path: 'userguide',
    name: 'userguide',
    component: Doc,
    meta: {
      crumbs: [{ title: 'User Guide' }],
    },
  },
]
