import axios from '@/axios/noredirect'

export default {
  getSession () { return axios.get('/session') },
  createSession (u) { return axios.post('/session', u) },
  deleteSession (u) { return axios.delete('/session') },

  changePassword (user, oldPass, newPass) { return axios.post('/user/password', { username: user, oldPass: oldPass, newPass: newPass }) },

  requestRecoveryEmail (e) { return axios.post('/account/recover', { email: e }) },
  recoverAccount (token, pass) { return axios.post('/account/recover', { token: token, password: pass }) },
}
