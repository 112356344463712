import router from '@/router'
import userAPI from '@/api/user'
import teamAPI from '@/api/team'
import sessionAPI from '@/api/session'

export default {
  namespaced: true,
  state: {
    team: {
      id: '',
      name: '',
    },
    user: {
      id: '',
      name: '',
    },
    currentUser: {
      roles: [],
      teams: [],
    },
  },
  mutations: {
    setTeam (state, team) {
      state.team = team
    },
    setUser (state, user) {
      state.user = user
    },
    setCurrentUser (state, user) {
      state.currentUser = user
      if (!state.currentUser.roles) {
        state.currentUser.roles = []
      }
      if (!state.currentUser.teams) {
        state.currentUser.teams = []
      }
    },
  },
  getters: {
    isAdmin (state) {
      // coerce undefined to a boolean
      return !!state.currentUser.is_admin
    },
    isGroupAdmin (state) {
      return projectTypeID => {
        return (
          state.currentUser.roles.findIndex(
            r => r.name === 'Admin' && r.project_type_id === projectTypeID,
          ) >= 0
        )
      }
    },
    isGroupAdminAny (state) {
      return state.currentUser.roles.findIndex(r => r.name === 'Admin') >= 0
    },
    isRole (state) {
      return roleId => {
        return state.currentUser.roles.findIndex(r => r.id === roleId) >= 0
      }
    },
    isManager (state) {
      return state.currentUser.teams.findIndex(t => t.is_manager) >= 0
    },
    isTeamManager (state) {
      return team => {
        return state.currentUser.teams.findIndex(t => t.is_manager && t.team_id === team.id) >= 0
      }
    },
    teamName (state, getters, rootState, rootGetters) {
      return state.team.name || state.team.id
    },
    userName (state, getters, rootState, rootGetters) {
      return state.user.name || state.user.id
    },
  },
  actions: {
    getCurrentUser ({ commit }) {
      return sessionAPI.getSession().then(response => {
        commit('setCurrentUser', response.data)
        return response.data
      })
    },
    getTeam ({ commit }, teamID) {
      commit('setTeam', { id: '', name: '' })
      return teamAPI.getTeam(teamID).then(response => {
        commit('setTeam', response.data)
        return response.data
      })
    },
    getUser ({ commit }, userID) {
      commit('setUser', { id: '', name: '' })
      return userAPI.getUser(userID).then(response => {
        commit('setUser', response.data)
        return response.data
      })
    },
    logOut ({ commit }) {
      return sessionAPI.deleteSession().then(response => {
        router.push('/login', () => {
          commit('setCurrentUser', {})
        })
        return response
      })
    },
  },
}
