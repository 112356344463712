<template>
  <Modal
    ref="modal"
    :title="title"
  >
    <BaseForm
      submit-title="Submit"
      @submit="save"
      @cancel="cancel"
    >
      <BaseTextarea
        v-model="comment"
        :rows="4"
        title="Comment"
        name="comment"
        required
        trim
      />
      <FieldError v-model="error" />
    </BaseForm>
  </Modal>
</template>

<script>
/**
 * Modal to change a Projects state.
 * Emits a 'state-change' even with project record when state is changed.
 */
import Modal from '@/components/Modal'
import FieldError from '@/components/FieldError'
import projectAPI from '@/api/project'

export default {
  components: {
    Modal,
    FieldError,
  },
  data () {
    return {
      error: '',
      comment: null,
      project: {},
    }
  },
  computed: {
    title () {
      return this.project.on_hold ? 'Remove Project Hold' : 'Put Project On Hold'
    },
  },
  methods: {
    reset () {
      this.error = ''
      this.comment = null
      this.project = {}
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    edit (project) {
      this.reset()
      this.project = project
      this.$refs.modal.show()
    },
    save () {
      projectAPI.updateOnHold(this.project.id, this.comment, !this.project.on_hold).then(resp => {
        this.$emit('on-hold-update', !this.project.on_hold)
        this.cancel()
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
