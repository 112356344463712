import axios from '@/axios/default'

export default {
  projectURL (projID) { return '/projects/' + projID },
  projectPropertiesURL (projID) { return this.projectURL(projID) + '/properties' },
  projectPropertyURL (projID, propName) { return this.projectURL(projID) + '/properties/' + propName },
  updateProperty (projID, propName, props) { return axios.put(this.projectPropertyURL(projID, propName), { value: props }) },
  projectP4PPropURL (projID, name) { return this.projectURL(projID) + '/' + name },
  updateP4PProp (projID, name, value) {
    return axios.put(this.projectP4PPropURL(projID, name), Object.fromEntries([[name, value]]))
  },

  stateURL (projID) { return this.projectURL(projID) + '/state' },
  updateState (projID, state) { return axios.put(this.stateURL(projID), state) },

  deliveryDateURL (projID) { return this.projectURL(projID) + '/delivery_date' },
  updateDeliveryDate (projID, d) { return axios.put(this.deliveryDateURL(projID), { delivery_date: d }) },

  financialsURL (projID) { return this.projectURL(projID) + '/financials' },
  getFinancials (projID) { return axios.get(this.financialsURL(projID)) },
  updateFinancials (projID, f) { return axios.put(this.financialsURL(projID), f) },

  quotesURL (projID) { return this.projectURL(projID) + '/quotes' },
  quoteURL (projID, quoteID) { return this.quotesURL(projID) + '/' + quoteID },
  getQuotes (projID) { return axios.get(this.quotesURL(projID)) },
  createQuote (projID, quote) { return axios.post(this.quotesURL(projID), quote) },
  updateQuote (projID, quote) { return axios.put(this.quoteURL(projID, quote.id), quote) },

  servicesLockURL (projID) { return this.projectURL(projID) + '/services_lock' },
  lockServices (projID) { return axios.put(this.servicesLockURL(projID), { services_locked: true }) },
  unlockServices (projID) { return axios.put(this.servicesLockURL(projID), { services_locked: false }) },

  projectUpdateURL (projID) { return this.projectURL(projID) + '/update' },
  createProjectUpdate (projID, comment, recipients) { return axios.post(this.projectUpdateURL(projID), { comment: comment, recipients: recipients }) },

  projectBookingsURL (projID) { return this.projectURL(projID) + '/bookings' },
  getProjectBookings (projID) { return axios.get(this.projectBookingsURL(projID)) },

  teamURL (projID) { return this.projectURL(projID) + '/team' },
  updateTeam (projID, team) { return axios.put(this.teamURL(projID), { team: team }) },

  onHoldURL (projID) { return this.projectURL(projID) + '/on_hold' },
  updateOnHold (projID, comment, onHold) { return axios.put(this.onHoldURL(projID), { comment: comment, on_hold: onHold }) },

  projectRolesURL (projID) { return this.projectURL(projID) + '/roles' },
  getProjectRoles (projID) { return axios.get(this.projectRolesURL(projID)) },
  projectRoleURL (projID, roleID) { return this.projectRolesURL(projID) + '/' + roleID },
  updateRoleUser (projID, roleID, user) { return axios.put(this.projectRoleURL(projID, roleID), { usr: user }) },

  getProjectMilestones (projID) { return axios.get(this.projectURL(projID) + '/milestones') },
  updateAssignedMilestones (projID, data) { return axios.put(this.projectURL(projID) + '/milestones', data) },
  updateAssignedMilestone (projID, milestoneID, am) { return axios.put(this.projectURL(projID) + '/milestones/' + milestoneID, am) },

  attachmentsURL (projID) { return this.projectURL(projID) + '/attachments' },
  attachmentURL (aID) { return '/attachments/' + aID },
  getAttachments (projID) { return axios.get(this.projectURL(projID) + '/attachments') },
  getFinancialAttachments (projID) { return axios.get(this.projectURL(projID) + '/financial_attachments') },
  getAttachment (aID) { return axios.get(this.attachmentURL(aID)) },
  uploadAttachment (projID, data) { return axios.post(this.projectURL(projID) + '/attachments', data) },
  attachmentDownloadURL (aID) { return this.attachmentURL(aID) + '/download' },

  attachmentTypesURL (projID) { return this.projectURL(projID) + '/attachment_types' },
  getAttachmentTypes (projID) { return axios.get(this.attachmentTypesURL(projID)) },

  getServices (projID) { return axios.get(this.projectURL(projID) + '/services') },
  updateServices (projID, data) { return axios.put(this.projectURL(projID) + '/services', data) },
  updateServiceQuote (s) { return axios.put(this.projectURL(s.project_id) + '/services/' + s.id + '/quote', s) },
  getAvailableServices (projID) { return axios.get(this.projectURL(projID) + '/available_services') },

  timelineURL (projID) { return this.projectURL(projID) + '/timeline' },
  getTimeline (projID) { return axios.get(this.timelineURL(projID)) },

  getProjectPermissions (projID) { return axios.get(this.projectURL(projID) + '/permissions') },
  getProjectRolePermissions (projID) { return axios.get(this.projectURL(projID) + '/role_permissions') },
  getProjectTransitions (projID) { return axios.get(this.projectURL(projID) + '/transitions') },

  getProject (projID) { return axios.get(this.projectURL(projID)) },
  createProject (proj) { return axios.post('projects', proj) },
  updateProject (proj) { return axios.put(this.projectURL(proj.id), proj) },
  deleteProject (projID) { return axios.delete(this.projectURL(projID)) },

  projectSurveyURL (projID) { return this.projectURL(projID) + '/survey' },
  getProjectSurvey (projID) { return axios.get(this.projectSurveyURL(projID)) },
  createProjectSurvey (projID, results) { return axios.post(this.projectSurveyURL(projID), { results: results }) },

  getGroupProjects (groupId, state) {
    const params = { project_type_id: groupId, project_state_name: state }
    return axios.get('projects', { params: params }).then(p => {
      return Promise.resolve(p.data)
    })
  },

  getSchema (projID) { return axios.get(this.projectURL(projID) + '/schema') },
}
