<template>
  <VPopover
    :open="open"
    :auto-hide="autoHide"
    trigger="manual"
    v-on="$listeners"
  >
    <FilterIcon
      :class="{ 'filter-active': filterActive }"
      class="clickable"
      title=""
      @click="$emit('update:open', true)"
    />
    <template #popover>
      <slot />
      <div class="filter-buttons">
        <button
          class="btn btn-primary"
          @click.prevent="$emit('update')"
        >
          OK
        </button>
        <button
          class="btn btn-default"
          @click.prevent="$emit('update:open', false)"
        >
          Cancel
        </button>
      </div>
    </template>
  </VPopover>
</template>

<script>
/**
 * Modal to allow updating the User assigned to a Usr Role for a Project.
 */
import FilterIcon from 'vue-material-design-icons/Filter.vue'
import { VPopover } from 'v-tooltip'

export default {
  components: {
    VPopover,
    FilterIcon,
  },
  props: {
    autoHide: {
      type: Boolean,
      default: true,
    },
    filterActive: {
      type: Boolean,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    '$route': function () { this.$emit('update:open', false) },
  },
}
</script>

<style scoped>
.v-popover {
  display: inline;
}
.filter-buttons {
  padding-top: 1em !important;
  text-align: center;
}
.filter-icon {
  margin-left: 2px;
}
.filter-icon:not(:hover) {
  color: rgb(179, 179, 179);
}
.filter-active.filter-icon:not(:hover) {
  color: #17a2b8;
}
</style>
