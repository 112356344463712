<template>
  <nav class="navbar navbar-expand-sm navbar-dark shadow fixed-top">
    <router-link
      class="navbar-brand"
      to="/"
    >
      Motion
    </router-link>

    <ul class="navbar-nav">
      <li class="nav-item dropdown">
        <a
          class="nav-link dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          href="#"
        >
          <font-awesome-icon icon="user-circle" />
          {{ currentUser.name }}
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <router-link
            :to="{ name: 'userguide' }"
            class="dropdown-item"
          >
            <font-awesome-icon
              icon="book"
              fixed-width
            />
            User Guide
          </router-link>
          <router-link
            :to="{ name: 'support' }"
            class="dropdown-item"
          >
            <font-awesome-icon
              icon="life-ring"
              fixed-width
            />
            Support
          </router-link>
          <div class="dropdown-divider" />
          <router-link
            v-if="currentUser.canChangePassword"
            :to="{ name: 'password_change', params: { user: currentUser.ad_account } }"
            class="dropdown-item"
          >
            <KeyFillIcon />
            Change Password
          </router-link>
          <a
            class="dropdown-item"
            href="#"
            @click.prevent="logOut"
          >
            <font-awesome-icon
              icon="sign-out-alt"
              fixed-width
            />
            Logout
          </a>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import KeyFillIcon from 'bootstrap-icons/icons/key-fill.svg?inline'
import { mapState } from 'vuex'

export default {
  components: {
    KeyFillIcon,
  },
  computed: {
    ...mapState('user', ['currentUser']),
  },
  methods: {
    logOut () {
      this.$store.dispatch('user/logOut')
        .then(response => {
          console.log('user logged out')
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  height: $navbar-height;
  background-color: $navbar-background-color;
}
.navbar-brand {
  color: #fff;
  margin-left: 1em;
  font-size: 1.5em;
}
.navbar-nav {
  margin-left: auto;

  .nav-link,
  .nav-link:hover {
    color: #fff;
  }
}
</style>
