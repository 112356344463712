<template>
  <div
    v-if="value"
    class="alert alert-danger"
  >
    {{ value }}
    <button
      type="button"
      class="close"
      @click.prevent="dismiss"
    >
      &times;
    </button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  methods: {
    dismiss () {
      this.$emit('input', '')
    },
  },
}
</script>
