import groupAPI from '@/api/group'

export default {
  namespaced: true,
  state: {
    group: {
      id: '',
      name: '',
      states: [],
    },
    role: {
      id: '',
      name: '',
    },
  },
  mutations: {
    setGroup (state, group) {
      state.group = group
    },
    setRole (state, role) {
      state.role = role
    },
  },
  getters: {
    groupName (state, getters, rootState, rootGetters) {
      return state.group.name || state.group.id
    },
    roleName (state, getters, rootState, rootGetters) {
      return state.role.name || state.role.id
    },
  },
  actions: {
    getGroup ({ commit }, groupID) {
      commit('setGroup', { id: '', name: '' })
      return groupAPI.getGroup(groupID).then(group => {
        commit('setGroup', group.data)
        return group.data
      })
    },
    getRole ({ commit }, [groupID, roleID]) {
      commit('setRole', { id: '', name: '' })
      return groupAPI.getGroupRole(roleID).then(response => {
        commit('setRole', response.data)
        return response.data
      })
    },
  },
}
