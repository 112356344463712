<template>
  <div id="wrapper">
    <TheNavbar class="noprint" />
    <TheSidebar class="noprint" />
    <TheLevelbar class="noprint" />
    <div
      id="page-wrapper"
      class="d-flex flex-column"
    >
      <router-view />
      <TheFooter class="noprint" />
    </div>
  </div>
</template>

<script>
import TheNavbar from '@/components/layout/TheNavbar'
import TheSidebar from '@/components/layout/TheSidebar'
import TheLevelbar from '@/components/layout/TheLevelbar'
import TheFooter from '@/components/layout/TheFooter'
import axios from '@/axios/default'
import store from '@/store'

export default {
  name: 'Main',
  components: {
    TheNavbar,
    TheSidebar,
    TheLevelbar,
    TheFooter,
  },
  beforeRouteEnter (to, from, next) {
    // get user details before entering main app.
    Promise.all([
      axios.get('version'),
      store.dispatch('user/getCurrentUser'),
    ]).then(([version, user]) => {
      // Reload app on login if frontend and backend versions differ
      if (
        from.name === 'login' &&
        version.data.version !== process.env.VUE_APP_VERSION &&
        process.env.VUE_APP_VERSION !== 'development'
      ) {
        next(vm => {
          // reload window after new instance is created so we don't reload login page
          window.location.reload(true)
        })
      } else {
        next()
      }
    }).catch(error => {
      next({
        name: 'login',
        params: { redirect: to.fullPath, errorMsg: error.message },
      })
    })
  },
}
</script>

<style lang="scss" scoped>
#page-wrapper {
  margin-top: $content-top;
  margin-left: $sidebar-width;
  padding: 20px;
  background-color: white;
  height: calc(100vh - #{$content-top});
  overflow-y: auto;
}

@media print {
  #page-wrapper {
    margin: 0;
    overflow-y: visible;
  }
}
</style>
