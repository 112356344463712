<template>
  <LoginForm
    submit-label="Change Password"
    :submit="resetPass"
  >
    <p>
      Reset Account Password
    </p>
    <input
      v-model="password"
      type="password"
      class="form-control form-control-lg"
      placeholder="Password"
    >
    <input
      v-model="password_confirm"
      type="password"
      class="form-control form-control-lg"
      placeholder="Confirm Password"
    >
  </LoginForm>
</template>

<script>
/**
 * view for resetting password for user account
 */
import LoginForm from './LoginForm'
import sessionApi from '@/api/session'

export default {
  components: {
    LoginForm,
  },
  data () {
    return {
      error: '',
      password: '',
      password_confirm: '',
    }
  },
  methods: {
    /* use token and new password to reset account password */
    resetPass () {
      if (this.password !== this.password_confirm) {
        return Promise.reject(new Error('Password Mismatch'))
      }
      if (this.password === '') {
        return Promise.reject(new Error('Password Empty'))
      }
      const token = this.$route.query.token
      return sessionApi.recoverAccount(token, this.password).then(response => {
        this.$router.push('/')
      })
    },
  },
}
</script>
