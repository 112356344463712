<template>
  <div class="vh-100">
    <LoginForm
      submit-label="Sign In"
      :disable-submit="!account.name || !account.password"
      :submit="login"
      :error-msg="errorMsg"
    >
      <input
        v-model.trim="account.name"
        class="form-control form-control-lg"
        type="text"
        placeholder="User ID"
        autofocus
        required
      >
      <input
        v-model="account.password"
        class="form-control form-control-lg"
        type="password"
        placeholder="Password"
        required
      >
    </LoginForm>
    <div
      v-if="isIE"
      class="alert alert-danger text-center fixed-bottom mb-0"
      role="alert"
    >
      Warning: Internet Explorer is no longer supported - please use Chrome, Firefox or Edge instead
    </div>
  </div>
</template>

<script>
/**
 * Account Login view
 */
import LoginForm from './LoginForm'
import sessionApi from '@/api/session'

export default {
  components: {
    LoginForm,
  },
  props: {
    redirect: {
      type: String,
      default: '/',
    },
    user: {
      type: String,
      default: '',
    },
    errorMsg: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      account: { name: '', password: '' },
    }
  },
  computed: {
    isIE () {
      return !!document.documentMode
    },
  },
  beforeMount () {
    this.account.name = this.user
  },
  methods: {
    /* login by creating new session with api */
    login () {
      return sessionApi.createSession(this.account).then(response => {
        this.$router.replace(this.redirect)
      }).catch(error => {
        if (error.message === 'password expired') {
          this.$router.replace({ name: 'password_change', params: { user: this.account.name, redirect: this.redirect, expired: true } })
        } else {
          throw error
        }
      }).finally(() => {
        this.account.password = ''
      })
    },
  },
}
</script>
