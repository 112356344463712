<template>
  <Modal
    ref="modal"
    title="Confirm State Change"
  >
    <p
      v-if="error"
      class="text-danger"
    >
      {{ error }}
    </p>
    <template v-if="notifications.length > 0">
      <p>{{ notifications[0] }}</p>
      <div class="d-flex justify-content-center">
        <button
          class="btn btn-primary"
          @click.prevent="clearNotification"
        >
          Ok
        </button>
      </div>
    </template>
    <template v-else>
      <label>Are you sure you want to '{{ transition.name }}'</label><br>
      <button
        class="btn btn-primary"
        @click.prevent="changeState"
      >
        Yes
      </button>
      <button
        class="btn btn-default"
        @click.prevent="cancel"
      >
        No
      </button>
    </template>
  </Modal>
</template>

<script>
/**
 * Dialog with Yes/No to confirm a project state change.
 * Emits a 'state-change' event with the new state when change is successful.
 */
import Modal from '@/components/Modal'
import projectAPI from '@/api/project'

export default {
  components: {
    Modal,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      transition: {},
      notifications: [],
      error: '',
    }
  },
  methods: {
    reset () {
      this.transition = {}
      this.notifications = []
      this.error = ''
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    clearNotification () {
      this.notifications.shift()
    },
    changeState () {
      projectAPI.updateState(this.project.id, this.transition.dst_state).then(resp => {
        this.$emit('state-change', this.transition.dst_state)
        this.cancel()
      }).catch(error => {
        this.error = error.message
      })
    },
    toConfirm (transition) {
      this.reset()
      this.transition = transition

      if (this.project.services) {
        const notifications = this.project.services.filter(s =>
          s.service.notification && s.service.notification_states && s.service.notification_states.includes(transition.dst_state.name),
        ).map(s => s.service.notification)
        // remove duplicates
        this.notifications = notifications.filter((v, i, a) => a.indexOf(v) === i)
      }
      this.$refs.modal.show()
    },
  },
}
</script>
