<template>
  <Modal
    ref="modal"
    title="Update Project Milestone"
  >
    <BaseForm
      submit-title="Save"
      @submit="save"
      @cancel="cancel"
    >
      <BaseInputCheckbox
        v-if="milestone.is_check"
        v-model="checked"
        :title="milestone.name"
        name="milestone_checkbox"
      />
      <BaseInputDate
        v-else-if="milestone.is_date"
        v-model="m_date"
        :title="milestone.name"
        name="m_date"
      />
      <BaseTextarea
        v-else-if="milestone.is_text"
        v-model="m_text"
        :title="milestone.name"
        name="m_text"
        trim
      />
      <FieldError v-model="error" />
    </BaseForm>
  </Modal>
</template>

<script>
/**
 * Modal to edit the status of a Milestone for a project.
 */
import Modal from '@/components/Modal'
import FieldError from '@/components/FieldError'
import projectAPI from '@/api/project'

export default {
  components: {
    Modal,
    FieldError,
  },
  data () {
    return {
      error: '',
      checked: false,
      m_date: '',
      m_text: '',
      milestone: { is_check: '', is_date: '', is_text: '', name: '' },
      assigned_milestone: {},
    }
  },
  methods: {
    reset () {
      this.error = ''
      this.checked = false
      this.m_date = ''
      this.m_text = ''
      this.milestone = { is_check: '', is_date: '', is_text: '', name: '' }
      this.assigned_milestone = {}
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    /**
     * @description open modal and edit (assigned) milestone
     * @param {Object} milestone - milestone details to edit (assigned milestone)
     */
    edit (milestone) {
      this.reset()
      this.assigned_milestone = milestone
      this.checked = milestone.checked
      this.m_date = milestone.m_date
      this.m_text = milestone.m_text
      this.milestone = milestone.milestone
      this.$refs.modal.show()
    },
    save () {
      const am = {}
      if (this.milestone.is_check) {
        am.checked = this.checked
      } else if (this.milestone.is_date && this.m_date) {
        am.m_date = this.m_date
      } else if (this.milestone.is_text && this.m_text) {
        am.m_text = this.m_text
      }
      projectAPI.updateAssignedMilestone(this.assigned_milestone.project_id, this.milestone.id, am).then(resp => {
        this.assigned_milestone.checked = resp.data.checked
        this.assigned_milestone.m_date = resp.data.m_date
        this.assigned_milestone.m_text = resp.data.m_text
        this.cancel()
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
