<template>
  <ListTemplate
    api-url="projects"
    :per-page="20"
    :fields="fields"
    :default-filters="defaultFilters"
    :update-action="false"
    :delete-action="false"
    default-sort="project_id desc"
    :title="$route.meta.title"
    track-by="project_id"
    csv-download="projects.csv"
    :append-params="appendParams"
    select-columns
  >
    <template #list-header>
      <div style="width: 30%;">
        <h3 class="mb-2">
          {{ $route.meta.title }}
        </h3>
        <BaseInput
          v-model="search"
          name="search"
          title=""
          class="mb-0"
          placeholder="Filter by name or id"
          trim
        >
          <template #prepend>
            <SearchIcon />
          </template>
        </BaseInput>
      </div>
    </template>
    <template #services_locked="props">
      <span v-if="props.rowData.services_locked">
        <LockIcon title="Services Locked" />
      </span>
    </template>
    <template #project_state_name="props">
      <span class="text-nowrap">{{ props.rowData.project_state_name }}</span>
      <span
        v-if="props.rowData.on_hold"
        class="text-nowrap"
      >(on hold)</span>
      <span
        v-else-if="!props.rowData.project_state_is_closed"
        class="text-nowrap"
      >{{ daysInfo(props.rowData.project_state_days) }}</span>
    </template>
    <template #services="props">
      <div v-if="props.rowData.services">
        <span
          v-for="s in props.rowData.services"
          :key="s"
          class="text-nowrap d-inline-block comma-list"
        >{{ s }}</span>
      </div>
    </template>
  </ListTemplate>
</template>

<script>
/**
 * View to list Projects.
 */
import ListTemplate from '@/components/ListTemplate'
import LockIcon from 'bootstrap-icons/icons/lock-fill.svg?inline'
import SearchIcon from 'bootstrap-icons/icons/search.svg?inline'
import groupAPI from '@/api/group'
import teamAPI from '@/api/team'
import userAPI from '@/api/user'
import formatDate from '@/filters/formatDate'

export default {
  components: {
    LockIcon,
    SearchIcon,
    ListTemplate,
  },
  data () {
    return {
      fields: [
        {
          name: 'project_id',
          title: 'ID',
          sortField: 'project_id',
          filterType: Number,
          visible: true,
          isIndex: true,
          linkToIndex: true,
        },
        {
          name: 'project_name',
          title: 'Name',
          sortField: 'project_name',
          filterType: String,
          filterField: 'project_name_like',
          visible: true,
        },
        {
          name: 'requester_name',
          title: 'Requester',
          sortField: 'requester_name',
          filterType: String,
          filterOptions: userAPI.getUserNames,
          visible: true,
        },
        {
          name: 'pan_owner_name',
          title: 'PAN Owner',
          sortField: 'pan_owner_name',
          filterType: String,
          filterOptions: userAPI.getUserNames,
          visible: false,
        },
        {
          name: 'pan_code',
          title: 'PAN Code',
          sortField: 'pan_code',
          filterType: String,
          visible: false,
        },
        {
          name: 'project_type_name',
          title: 'Delivery Group',
          sortField: 'project_type_name',
          filterType: String,
          filterOptions: groupAPI.getGroupNames,
          dataClass: 'text-nowrap',
          visible: true,
        },
        {
          name: 'team_name',
          title: 'Team',
          sortField: 'team_name',
          filterType: String,
          filterOptions: teamAPI.getTeamNames,
          visible: true,
        },
        {
          name: 'tech_lead_name',
          title: 'Tech Lead',
          sortField: 'tech_lead_name',
          filterType: String,
          filterOptions: userAPI.getUserNames,
          visible: true,
        },
        {
          name: 'project_manager_name',
          title: 'Project Manager',
          sortField: 'project_manager_name',
          filterType: String,
          filterOptions: userAPI.getUserNames,
          visible: true,
        },
        {
          name: 'services',
          title: 'Services',
          filterType: String,
          filterField: 'services_includes',
          visible: false,
        },
        {
          name: 'project_state_name',
          title: 'Status',
          filterField: 'project_state_name',
          sortField: 'project_state_date',
          filterType: String,
          filterOptions: groupAPI.getStateNames,
          visible: true,
        },
        {
          name: 'services_locked',
          title: 'Lock',
          filterType: String,
          filterOptions: ['True', 'False'],
          filterSingleOnly: true,
          dataClass: 'text-center',
          visible: true,
        },
        {
          name: 'submit_date',
          title: 'Submit Date',
          sortField: 'submit_date',
          visible: true,
          dataClass: 'text-nowrap',
          formatter: this.formatSubmitDate,
        },
        {
          name: 'created_on',
          title: 'Created On',
          filterType: Date,
          visible: false,
          dataClass: 'text-nowrap',
          formatter: this.formatSubmitDate,
        },
      ],
      defaultFilters: [
        {
          // ignore closed states by default unless state or id set
          params: { project_state_is_closed: false },
          fields: ['project_state_name', 'project_id', 'created_on', 'search'],
        },
      ],
      search: '',
    }
  },
  computed: {
    appendParams () { return this.search ? { search: this.search } : {} },
  },
  methods: {
    formatSubmitDate (value) {
      return this.formatDate(value, 'YYYY-MM-DD')
    },
    daysInfo (value) {
      return '(' + value + ' day' + (value === 1 ? ')' : 's)')
    },
    formatDate,
  },
}
</script>

<style lang="scss" scoped>
.comma-list:after {
  content: ",\00a0";
  display: inline-block;
}
.comma-list:last-child::after
{
  content: "";
}
</style>
