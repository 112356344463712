<template>
  <div>
    <div>
      <div>
        <p
          v-if="error"
          class="text-danger"
        >
          {{ error }}
        </p>
        <table class="info-table">
          <tr>
            <td>Project Name</td>
            <td>{{ project.name }}</td>
            <td />
          </tr>
          <tr>
            <td>Delivery Group</td>
            <td>{{ project.project_type.name }}</td>
            <td />
          </tr>
          <tr>
            <td>Team</td>
            <td>
              {{ project.team ? project.team : 'Unassigned' }}
            </td>
            <td>
              <span
                v-if="canEditTeam"
                class="edit-icon"
                title="Assign Team"
                @click="editTeam"
              >
                <EditIcon title="Assign Team" />
              </span>
            </td>
          </tr>
          <tr
            v-for="r in project.roles"
            :key="'role-' + r.usr_role.id"
          >
            <td>{{ r.usr_role.name }}</td>
            <td>
              <template v-if="r.usr">
                {{ r.usr.name }}
              </template>
              <template v-else>
                Unassigned
              </template>
            </td>
            <td>
              <span
                v-if="r.can_edit"
                :title="'Assign ' + r.usr_role.name"
                class="edit-icon"
                @click="editRole(r)"
              >
                <EditIcon :title="'Assign ' + r.usr_role.name" />
              </span>
            </td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              <span :class="{ 'text-danger': project.project_state.is_start }">
                {{ project.project_state.name }}
              </span>
              <span v-if="project.on_hold">( on hold )</span>
            </td>
            <td>
              <span
                v-if="transitions.length > 0 && !project.on_hold"
                class="edit-icon"
                title="Change State"
                @click="changeState"
              >
                <EditIcon title="Change State" />
              </span>
            </td>
          </tr>
          <tr
            v-for="prop in properties"
            :key="prop.fullname"
          >
            <td>{{ prop.schema.title }}</td>
            <td>
              <ProjectInfoProperty
                :property="prop.base[prop.name]"
                :schema="prop.schema"
              />
            </td>
            <td>
              <span
                v-if="!prop.schema.readOnly"
                class="edit-icon"
                :title="`Edit ${prop.schema.title}`"
                @click="editProp(prop)"
              >
                <EditIcon :title="`Edit ${prop.schema.title}`" />
              </span>
            </td>
          </tr>
          <template v-if="project.p4p">
            <tr>
              <td>Clarity Code</td>
              <td>{{ project.p4p.clarity_code }}</td>
              <td />
            </tr>
            <tr>
              <td>PAN Code / OPEX Cost Centre</td>
              <td>
                {{ project.p4p.pan_code }}
              </td>
              <td>
                <span
                  v-if="canEditPanCode"
                  class="edit-icon"
                  title="Change Pan Code"
                  @click="editPanCode"
                >
                  <EditIcon title="Change Work Completion Date" />
                </span>
              </td>
            </tr>
            <tr>
              <td>Release ID</td>
              <td>{{ project.p4p.release_id }}</td>
              <td />
            </tr>
            <tr>
              <td>Work Required</td>
              <td>
                <pre>{{ project.p4p.work_required }}</pre>
              </td>
              <td />
            </tr>
            <tr>
              <td>Work Completion Date</td>
              <td>
                {{ formatDate(project.p4p.delivery_date) }}
              </td>
              <td>
                <span
                  v-if="canEditDeliveryDate"
                  class="edit-icon"
                  title="Change Work Completion Date"
                  @click="editDeliveryDate"
                >
                  <EditIcon title="Change Work Completion Date" />
                </span>
              </td>
            </tr>
          </template>
          <tr
            v-for="(at, typeName) in attachments"
            :key="typeName"
          >
            <td>{{ typeName }}</td>
            <td>
              <a
                v-for="a in at"
                :key="at.id + '_' + a.id"
                :href="downloadLink(a.id)"
                class="link-list"
              >{{ a.filename }}</a>
            </td>
            <td />
          </tr>
        </table>
      </div>
      <div
        v-if="canEdit || canDelete || canAttachFile || namedTransitions.length > 0"
        class="project-panel-footer btn-block"
      >
        <router-link
          v-if="canEdit"
          :to="{ name: 'update_project' }"
          class="btn btn-primary"
          tag="button"
        >
          Edit
        </router-link>
        <button
          v-if="canDelete"
          class="btn btn-primary"
          @click.prevent="confirmDelete"
        >
          Delete
        </button>
        <button
          v-if="canAttachFile"
          class="btn btn-primary"
          @click="attachFile"
        >
          Add Attachment
        </button>
        <template v-for="tr in namedTransitions">
          <span :key="'span' + tr.dst_state.id" />
          <button
            :key="tr.dst_state.id"
            class="btn btn-primary"
            @click="changeStateID(tr)"
          >
            {{ tr.name }}
          </button>
        </template>
      </div>
    </div>
    <ProjectTeamUpdate
      ref="teamUpdate"
      @team-update="refreshData"
    />
    <ProjectRoleUpdate
      ref="roleUpdate"
      @role-update="refreshData"
    />
    <ProjectPropertyUpdate ref="propertyUpdate" />
    <ProjectP4PPropUpdate
      ref="p4pPropUpdate"
      :project="project"
      @prop-update="refreshData"
    />
    <ProjectStateUpdate
      ref="stateUpdate"
      :project="project"
      @state-change="refreshData"
    />
    <AttachFile
      ref="attachFile"
      :project="project"
      @file-upload="refreshData"
    />
    <ProjectDeliveryDateUpdate ref="deliveryDateUpdate" />
    <ConfirmStateChange
      ref="confirmStateChange"
      :project="project"
      @state-change="refreshData"
    />
    <ProjectSurvey
      ref="survey"
      :project="project"
    />
    <ConfirmDialog
      ref="dialog"
      @confirmed="deleteProject"
    />
  </div>
</template>

<script>
/**
 * View to Show Project Information.
 */
import EditIcon from 'vue-material-design-icons/SquareEditOutline.vue'
import projectAPI from '@/api/project'
import ProjectInfoProperty from './ProjectInfoProperty'
import ProjectTeamUpdate from './ProjectTeamUpdate'
import ProjectRoleUpdate from './ProjectRoleUpdate'
import ProjectPropertyUpdate from './ProjectPropertyUpdate'
import ProjectP4PPropUpdate from './ProjectP4PPropUpdate'
import ProjectStateUpdate from './ProjectStateUpdate'
import AttachFile from './AttachFile'
import ProjectSurvey from './ProjectSurvey'
import ProjectDeliveryDateUpdate from './ProjectDeliveryDateUpdate'
import ConfirmStateChange from './ConfirmStateChange'
import ConfirmDialog from '@/components/ConfirmDialog'
import formatDate from '@/filters/formatDate'

export default {
  components: {
    EditIcon,
    ProjectInfoProperty,
    ProjectTeamUpdate,
    ProjectRoleUpdate,
    ProjectPropertyUpdate,
    ProjectP4PPropUpdate,
    ProjectStateUpdate,
    AttachFile,
    ProjectSurvey,
    ProjectDeliveryDateUpdate,
    ConfirmDialog,
    ConfirmStateChange,
  },
  props: {
    // Project
    project: {
      type: Object,
      required: true,
    },
    permissions: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      attachments: {},
      transitions: [],
      schema: { properties: {} },
      error: '',
    }
  },
  computed: {
    canEdit () {
      return this.canDo('PUT', 'project')
    },
    canEditTeam () {
      return this.canDo('PUT', 'team')
    },
    canEditDeliveryDate () {
      return this.canDo('PUT', 'delivery_date')
    },
    canEditPanCode () {
      return this.canDo('PUT', 'pan_code')
    },
    canAttachFile () {
      return this.canDo('POST', 'attachments')
    },
    canDelete () {
      return this.canDo('DELETE', 'project')
    },
    canCreateSurvey () {
      return this.canDo('POST', 'survey')
    },
    namedTransitions () {
      if (this.project.on_hold) {
        return []
      }
      return this.transitions.filter(tr => tr.name && !tr.comment_required)
    },
    properties () {
      return this.getObjectProps(this.schema, this.project)
    },
  },
  watch: {
    project: function () {
      this.getData()
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getObjectProps (schema, data) {
      if (data === undefined || data === null) {
        data = {}
      }
      let props = schema['ui:order'] || []
      if (props.length === 0) {
        props = Object.keys(schema.properties || {}).sort()
      }
      return props.map(p => {
        const propSchema = schema.properties[p]
        if (propSchema.type === 'object') {
          // get nested props
          return this.getObjectProps(propSchema, data[p]).map(op => {
            return { ...op, fullname: p + '.' + op.fullname }
          })
        }
        return {
          name: p,
          schema: propSchema,
          base: data,
          fullname: p,
          required: schema.required && schema.required.includes(p),
        }
      }).flat()
    },
    getDataPromise () {
      this.error = ''
      return Promise.all([
        projectAPI.getProjectTransitions(this.project.id),
        projectAPI.getAttachments(this.project.id),
        projectAPI.getSchema(this.project.id),
      ]).then(([transitions, attachments, schema]) => {
        // group attachments by type name
        this.attachments = attachments.data.reduce(function (atlist, at) {
          if (!atlist[at.type_name]) {
            atlist[at.type_name] = [at]
          } else {
            // filter out older files with same name and type
            atlist[at.type_name] = atlist[at.type_name].filter(a => a.filename !== at.filename)
            atlist[at.type_name].push(at)
          }
          return atlist
        }, {})
        this.transitions = transitions.data
        this.schema = schema.data
        if (this.canCreateSurvey) {
          this.$refs.survey.start(this.project)
        }
      })
    },
    getData () {
      this.getDataPromise().catch(error => {
        this.error = error.message
      })
    },
    refreshData () {
      // Get parent Project view to reload project data.
      // TODO: optimize better to remove duplication of requests.
      this.$emit('reload-project')

      this.getDataPromise().catch(error => {
        if (error.request.status === 403) {
          // status change changed project viewing permissions so go back
          this.$router.go(-1)
        } else {
          this.error = error.message
        }
      })
    },
    downloadLink (id) {
      return process.env.VUE_APP_API_URL + projectAPI.attachmentDownloadURL(id)
    },
    editTeam () {
      this.$refs.teamUpdate.edit(this.project)
    },
    editProp (prop) {
      this.$refs.propertyUpdate.edit(this.project, prop)
    },
    editRole (role) {
      this.$refs.roleUpdate.edit(this.project, role)
    },
    changeState () {
      this.$refs.stateUpdate.edit(this.transitions)
    },
    editDeliveryDate () {
      this.$refs.deliveryDateUpdate.edit(this.project)
    },
    editPanCode () {
      const field = {
        name: 'pan_code',
        required: true,
        schema: {
          type: 'string',
          title: 'Pan Code',
        },
      }
      this.$refs.p4pPropUpdate.edit(field)
    },
    attachFile (typeID) {
      this.$refs.attachFile.show(typeID)
    },
    changeStateID (transition) {
      this.$refs.confirmStateChange.toConfirm(transition)
    },
    confirmDelete () {
      this.error = ''
      this.$refs.dialog.confirm(
        'Confirm Delete',
        'Are you sure you want to delete the current Project?',
      )
    },
    deleteProject () {
      projectAPI.deleteProject(this.project.id).then(resp => {
        this.$router.push({ name: 'projects' })
      }).catch(error => {
        this.error = error.message
      })
    },
    canDo (method, resource) {
      return this.permissions.findIndex(p => (p.method === method) && (p.resource === resource)) >= 0
    },
    formatDate,
  },
}
</script>

<style scoped>
/* comma separated list */
.link-list:after {
  content: ",\00a0";
  display: inline-block;
  text-decoration: underline;
}
.link-list:last-child::after
{
  content: "";
}
/* for links don't show underline on :after part
   bug in IE requires underline be set, then unset for it to work */
.link-list:after, .link-list:hover:after {
  text-decoration: none;
}
.project-panel-footer {
  background-color: #f5f5f5;
  padding: 10px 15px;
  margin-top: 15px;
}
/* make column with edit icon minimum width */
td:nth-child(3) {
  width: 1%;
}
</style>
