const Dashboard = () => import(/* webpackChunkName: "reports" */ '@/views/Report/Dashboard')
const WeekReport = () => import(/* webpackChunkName: "reports" */ '@/views/Report/ByWeek')
const YearReport = () => import(/* webpackChunkName: "reports" */ '@/views/Report/Year')
const PlatformsReport = () => import(/* webpackChunkName: "reports" */ '@/views/Report/Platforms')
const GeneralFinancialOverview = () => import(/* webpackChunkName: "reports" */ '@/views/Report/GeneralFinancialOverview')
const ServicesFinancialOverview = () => import(/* webpackChunkName: "reports" */ '@/views/Report/ServicesFinancialOverview')
const ProjectsDuration = () => import(/* webpackChunkName: "reports" */ '@/views/Report/ProjectsDuration')
const ProjectsDurationTrend = () => import(/* webpackChunkName: "reports" */ '@/views/Report/ProjectsDurationTrend')
const CRReport = () => import(/* webpackChunkName: "reports" */ '@/views/Report/CRReport')

export default [
  {
    path: 'reports/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard',
      crumbs: [{ title: 'Dashboard' }],
    },
  },
  {
    path: 'reports/week',
    name: 'week_report',
    component: WeekReport,
    meta: {
      title: 'Week',
      crumbs: [{ title: 'Week Report' }],
    },
  },
  {
    path: 'reports/year',
    name: 'year_report',
    component: YearReport,
    meta: {
      title: 'Year',
      crumbs: [{ title: 'Year Report' }],
    },
  },
  {
    path: 'reports/platforms',
    name: 'platforms_summary',
    component: PlatformsReport,
    meta: {
      title: 'Platforms',
      crumbs: [{ title: 'Platforms Summary' }],
    },
  },
  {
    path: 'reports/services_financial',
    name: 'services_financial_overview',
    component: ServicesFinancialOverview,
    meta: {
      title: 'Services',
      crumbs: [{ title: 'Services Financial Overview' }],
    },
  },
  {
    path: 'reports/general/financial',
    name: 'general_financial_overview',
    component: GeneralFinancialOverview,
    meta: {
      title: 'Financial',
      crumbs: [{ title: 'General Financial Overview' }],
    },
  },
  {
    path: 'reports/projects_duration',
    name: 'projects_duration_report',
    component: ProjectsDuration,
    meta: {
      title: 'Projects Status Statistics',
      crumbs: [{ title: 'Projects Status Statistics Report' }],
    },
  },
  {
    path: 'reports/projects_duration_trend',
    name: 'projects_duration_trend_report',
    component: ProjectsDurationTrend,
    meta: {
      title: 'Projects Status Trend',
      crumbs: [{ title: 'Projects Status Monthly Trend Report' }],
    },
  },
  {
    path: 'reports/cr_report',
    name: 'cr_report',
    component: CRReport,
    meta: {
      title: 'CR Report',
      crumbs: [{ title: 'CR Report' }],
    },
  },
]
