<template>
  <div
    class="d-flex flex-column flex-grow-1"
    style="min-height: 0px;"
  >
    <div
      v-if="title || createAction || csvDownload"
      class="mb-3 d-flex flex-row justify-content-between"
    >
      <slot name="list-header">
        <h3 class="mb-0">
          {{ title }}
        </h3>
      </slot>
      <div>
        <div
          v-if="createAction || csvDownload || selectColumns"
          class="noprint"
        >
          <router-link
            v-if="createAction"
            to="create"
            class="btn btn-primary text-nowrap"
            tag="button"
            append
          >
            <PlusCircleIcon title="" />
            {{ createTitle }}
          </router-link>
          <div
            v-if="csvDownload || selectColumns"
            class="btn-group ml-2"
          >
            <button
              v-if="csvDownload"
              class="btn btn-outline-primary text-nowrap"
              @click="downloadCSV"
            >
              <DownloadIcon title="" />
              Download
            </button>
            <button
              v-if="selectColumns"
              class="btn btn-outline-primary text-nowrap p-0"
            >
              <ListTemplateFields
                v-model="userFields"
                :fields="fields"
                :fields-key="selectColumnsKey"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <CustomVuetable
      ref="customtable"
      v-bind="$attrs"
      :api-url="apiUrl"
      :fields="innerFields"
      :overflow="overflow"
      :track-by="indexField"
      :params="appendParams"
      v-on="$listeners"
      @csv-error="csvError"
    >
      <template
        v-for="sl in customSlots"
        #[sl]="props"
      >
        <slot
          :row-data="props.rowData"
          :row-index="props.rowIndex"
          :row-field="props.rowField"
          :name="sl"
        />
      </template>
      <template
        v-for="f in customFields"
        #[f.name]="props"
      >
        <template v-if="f.rowFormatter">
          <span
            :key="f.name"
            v-text="f.rowFormatter(props.rowData)"
          />
        </template>
        <router-link
          v-else-if="f.linkToIndex"
          :key="f.name"
          :to="String(props.rowData[indexField])"
          append
          v-text="props.rowData[f.name]"
        />
        <template v-else-if="f.linkTo">
          <template v-for="lf in [{ name: f.name, link: f.linkTo(props.rowData) }]">
            <component
              :is="lf.link ? 'router-link' : 'span'"
              :key="lf.name"
              :to="lf.link"
              v-text="props.rowData[lf.name]"
            />
          </template>
        </template>
      </template>
      <template #actions="props">
        <div class="custom-actions">
          <slot
            :row-data="props.rowData"
            :row-index="props.rowIndex"
            :row-field="props.rowField"
            name="actions"
          />
          <router-link
            v-if="canUpdateRow(props.rowData)"
            :to="props.rowData[indexField] + '/update'"
            class="btn btn-icon btn-primary"
            tag="button"
            title="Edit"
            append
          >
            <EditIcon title="Edit" />
          </router-link>
          <button
            v-if="canDeleteRow(props.rowData)"
            class="btn btn-icon btn-primary"
            title="Delete"
            @click.prevent="confirmDelete(props.rowData)"
          >
            <TrashIcon title="Delete" />
          </button>
        </div>
      </template>
      <template #detailLink="props">
        <span
          style="font-size: 1rem;"
          class="text-primary clickable"
        >
          <InfoIcon
            title="Detail"
            @click.prevent="$refs.detailModal.show(fields, props.rowData, apiUrl)"
          />
        </span>
      </template>
    </CustomVuetable>
    <ConfirmDialog
      ref="dialog"
      @confirmed="deleteRow"
    />
    <ListTemplateDetailModal ref="detailModal" />
  </div>
</template>

<script>
/**
 * View to List Teams.
 */
import CustomVuetable from '@/components/CustomVuetable'
import ConfirmDialog from '@/components/ConfirmDialog'
import ListTemplateFields from '@/components/ListTemplateFields'
import ListTemplateDetailModal from '@/components/ListTemplateDetailModal'
import EditIcon from 'vue-material-design-icons/SquareEditOutline.vue'
import TrashIcon from 'vue-material-design-icons/TrashCanOutline.vue'
import InfoIcon from 'vue-material-design-icons/InformationOutline.vue'
import PlusCircleIcon from 'vue-material-design-icons/PlusCircle'
import DownloadIcon from 'vue-material-design-icons/Download'
import axios from '@/axios/default'

export default {
  components: {
    EditIcon,
    TrashIcon,
    InfoIcon,
    PlusCircleIcon,
    DownloadIcon,
    ListTemplateFields,
    ListTemplateDetailModal,
    CustomVuetable,
    ConfirmDialog,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    createTitle: {
      type: String,
      default: 'Create',
    },
    apiUrl: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    createAction: {
      type: Boolean,
      default: true,
    },
    updateAction: {
      type: [Boolean, Function],
      default: true,
    },
    deleteAction: {
      type: [Boolean, Function],
      default: true,
    },
    deletePromise: {
      type: Function,
      default: null,
    },
    titleField: {
      type: String,
      default: 'name',
    },
    csvDownload: {
      type: String,
      default: '',
    },
    overflow: {
      type: Boolean,
      default: true,
    },
    selectColumns: {
      type: Boolean,
      default: false,
    },
    selectColumnsKey: {
      type: String,
      default: '',
    },
    appendParams: {
      type: Object,
      default () {
        return {}
      },
    },
  },
  data () {
    return {
      userFields: [],
    }
  },
  computed: {
    innerFields () {
      if (this.selectColumns && this.userFields.length > 0) {
        const userFields = this.fields.map(f => { return { ...f, visible: this.userFields.includes(f.name) } })
        if (userFields.findIndex(f => f.visible) >= 0) {
          return userFields
        }
      }
      return this.fields
    },
    indexField () {
      const i = this.innerFields.find(f => f.isIndex)
      return i ? i.name : 'id'
    },
    customFields () {
      return this.innerFields.filter(f => f.linkTo || f.rowFormatter || f.linkToIndex)
    },
    customSlots () {
      return Object.keys(this.$scopedSlots).filter(sl => sl !== 'actions' &&
        this.customFields.findIndex(f => f.name === sl) < 0)
    },
  },
  methods: {
    confirmDelete (rowData) {
      let title = 'this'
      if (this.titleField && rowData[this.titleField]) {
        title = '\'' + rowData[this.titleField] + '\''
      }
      this.$refs.dialog.confirm(
        'Confirm Delete',
        'Are you sure you want to delete ' + title + '?',
        rowData,
      )
    },
    canUpdateRow (rowData) {
      if (this.updateAction instanceof Function) {
        return this.updateAction(rowData)
      }
      return this.updateAction
    },
    canDeleteRow (rowData) {
      if (this.deleteAction instanceof Function) {
        return this.deleteAction(rowData)
      }
      return this.deleteAction
    },
    defaultDelete (rowData) {
      return axios.delete(this.apiUrl + '/' + rowData[this.indexField])
    },
    deleteRow (rowData) {
      let title = ''
      if (this.titleField && rowData[this.titleField]) {
        title = rowData[this.titleField]
      }
      let promise = this.deletePromise
      if (promise === null) {
        promise = this.defaultDelete
      }
      promise(rowData).then(resp => {
        this.$refs.customtable.$refs.vuetable.refresh()
        this.$parent.$toasted.success(title + ' deleted')
      }).catch(error => {
        this.$parent.$toasted.error(error + '. ' + title + ' delete failed.')
      })
    },
    refresh () {
      this.$refs.detailModal.refresh()
      this.$refs.customtable.$refs.vuetable.refresh()
    },
    downloadCSV () {
      this.$refs.customtable.downloadCSV(this.csvDownload)
    },
    csvError (e) {
      this.$parent.$toasted.error('CSV download failed: ' + e.message)
    },
  },
}
</script>
