<template>
  <div class="container container-table">
    <div class="row vertical-10p">
      <div class="container">
        <div class="text-center col-sm-6 col-sm-offset-3">
          <h1>You are lost.</h1>
          <h4>This page doesn't exist.</h4>
          Take me back to
          <router-link to="/">
            Motion
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
}
</script>
