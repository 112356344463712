<template>
  <Modal
    ref="modal"
    :title="`Update ${property.schema.title}`"
  >
    <BaseForm
      submit-title="Save"
      @submit="save"
      @cancel="cancel"
    >
      <SchemaPropertyField
        v-model="projectProp"
        :name="property.name"
        :schema="property.schema"
        :required="property.required"
      />
      <FieldError v-model="error" />
    </BaseForm>
  </Modal>
</template>

<script>
/**
 * View to update specific properties for a Project.
 */
import Modal from '@/components/Modal'
import SchemaPropertyField from '@/components/SchemaPropertyField'
import FieldError from '@/components/FieldError'
import projectAPI from '@/api/project'

export default {
  components: {
    Modal,
    FieldError,
    SchemaPropertyField,
  },
  data () {
    return {
      error: '',
      project: { id: '', properties: {} },
      property: { name: '', required: true, schema: { title: '' } },
      projectProp: '',
    }
  },
  methods: {
    reset () {
      this.error = ''
      this.project = { id: '', properties: {} }
      this.property = { name: '', required: true, schema: { title: '' } }
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    edit (project, property) {
      this.reset()
      this.project = project
      this.property = property
      if (!this.project.properties) {
        this.projectProp = ''
      } else {
        this.projectProp = this.project.properties[property.name]
      }
      if (Array.isArray(this.projectProp)) {
        this.projectProp = this.projectProp.slice()
      }
      this.$refs.modal.show()
    },
    save () {
      projectAPI.updateProperty(this.project.id, this.property.name, this.projectProp).then(resp => {
        this.$set(this.project.properties, this.property.name, this.projectProp)
        this.cancel()
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
