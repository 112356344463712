<template>
  <ValidationProvider
    v-slot="{ errors }"
    :rules="rules"
    :name="`${item.service.name} Quantity`"
    immediate
  >
    <div
      class="d-flex rounded-lg bg-gray-200 px-2 py-2 mb-1"
      :class="expanded ? 'align-items-start' : ' align-items-center'"
    >
      <!-- Icon -->
      <img
        v-if="item.service.service_icon_id"
        :src="iconURL(item.service.service_icon_id)"
        class="bg-white rounded-pill"
        style="cursor: pointer; height: 42px; width: 42px;"
        @click="expanded = !expanded"
      >
      <div
        class="px-3 pointer"
        style="font-size: 1.2em; line-height: 42px;"
        @click="expanded = !expanded"
      >
        <ChevronDownIcon
          v-if="expanded"
          title=""
        />
        <ChevronRightIcon
          v-else
          title=""
        />
      </div>
      <!-- Details -->
      <div
        class="flex-grow-1 mr-3 pointer"
        :class="{ 'py-2': expanded }"
      >
        <!-- Description -->
        <div
          class="text-gray-700 user-select-none"
          @click="expanded = !expanded"
        >
          {{ item.service.descr }}
        </div>
        <!-- Full Details -->
        <div v-show="expanded">
          <!-- Name -->
          <div class="text-gray-500">
            {{ item.service.name }}
          </div>
          <div
            v-if="item.slo"
            class="text-gray-600 mt-2"
          >
            Target Delivery:  {{ item.slo }} days
          </div>
          <!-- Warning -->
          <div
            v-if="item.service.warning"
            class="alert alert-info mt-3 mb-0"
            role="alert"
          >
            <span style="font-size: 18px;">&#128712;</span>&nbsp;&nbsp;{{ item.service.warning }}
          </div>
          <!-- Additional Info -->
          <CatalogItemInfo
            v-if="item.scope || item.service.info"
            title="Deliverable"
            :info="item.scope || item.service.info"
            :disable-markdown="!!item.scope"
          />
        </div>
      </div>
      <!-- Price -->
      <div
        v-show="showPrice"
        class="mr-3 text-right"
        style="line-height: 42px;"
      >
        {{ formatCurrency(item.price) }}
      </div>
      <!-- Quantity -->
      <div
        v-show="showQuantity"
        class="mr-3 d-flex flex-column align-items-center justify-content-center"
        style="height: 42px;"
      >
        <div
          class="font-weight-bold text-info"
          style="font-size: 10px; line-height: 10px; padding-bottom: 2px;"
        >
          QTY
        </div>
        <input
          v-model.number="item.count"
          type="number"
          class="form-control form-control-sm"
          :class="{ 'invalid-quantity': errors[0] }"
          style="width: 50px; text-align: center;"
          :min="1"
          :max="max"
          :readonly="readOnly || item.service.max === 1 && item.count === 1 ? 'readonly' : null"
          @input="updatePrice($event.target.value)"
        >
      </div>
      <!-- Item Actions -->
      <div
        class="d-flex"
        style="height: 42px;"
      >
        <slot />
      </div>
    </div>
    <div
      class="invalid-feedback"
      style="display: block;"
    >
      {{ errors[0] }}
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import CatalogItemInfo from './CatalogItemInfo'
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue'
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue'
import groupApi from '@/api/group'

// TODO: Implement validation for quantity field
export default {
  components: {
    ChevronDownIcon,
    ChevronRightIcon,
    CatalogItemInfo,
    ValidationProvider,
  },
  props: {
    item: {
      required: true,
      type: Object,
    },
    showQuantity: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showPrice: {
      type: Boolean,
      default: false,
    },
    groupMax: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      expanded: false,
    }
  },
  computed: {
    max () {
      let m = this.item.service.max
      if (this.groupMax > 0) {
        if (m > 0) {
          m = Math.min(m, this.groupMax)
        } else {
          m = this.groupMax
        }
      }
      return m
    },
    rules () {
      const r = { required: true, min_value: 1 }
      if (this.max > 0) { r.max_value = this.max }
      return r
    },
  },
  methods: {
    iconURL (id) {
      if (id) {
        return '/api' + groupApi.serviceIconURL(this.item.service.project_type_id, id)
      }
      return null
    },
    formatCurrency (value) {
      if (value == null) return 'Pending Quote'
      if (!value) return '$0.00'
      return '$' + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    },
    getServicePrice (service, count) {
      if (count <= 0) {
        count = 0
      }
      // get default unit pricing
      let min = 0
      let unitPrice = service.price
      // find pricing with closest minimum count below or equal to the item count
      if (Array.isArray(service.prices) && count > 0) {
        service.prices.forEach(p => {
          if (p.min <= count && p.min > min) {
            min = p.min
            unitPrice = p.price
          }
        })
      }
      if (unitPrice === undefined) {
        unitPrice = null
      }
      if (unitPrice === null) {
        return null
      }
      return count * unitPrice
    },
    updatePrice (count) {
      this.item.price = this.getServicePrice(this.item.service, count)
    },
  },
}
</script>

<style scoped>
  .invalid-quantity {
    border-color: #dc3545;
  }
</style>
