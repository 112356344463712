<template>
  <button
    class="btn form-button position-relative mr-2"
    :class="`btn-${colour}`"
    :disabled="isLoading || disabled"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <span
      class="d-inline-flex w-100 h-100 justify-content-center align-items-center position-absolute"
      :class="{ 'invisible': !isLoading }"
      style="left: 0; top: 0;"
    >
      <div class="spinner-border spinner-border-sm" />
    </span>
    <span :class="{ 'invisible': isLoading }">
      <slot />
    </span>
  </button>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    colour: {
      type: String,
      default: 'primary',
    },
  },
}
</script>

<style scoped>
.form-button {
  min-width: 5em;
}
</style>
