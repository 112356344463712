<template>
  <li class="nav-item">
    <a
      :href="'#' + name"
      class="nav-link"
      data-toggle="collapse"
    >
      <font-awesome-icon
        v-if="icon"
        :icon="icon"
        fixed-width
      />
      {{ title }}
      <ChevronDownIcon title="" />
      <ChevronLeftIcon title="" />
    </a>
    <ul
      :id="name"
      class="nav collapse"
      :data-parent="'#' + parent"
    >
      <slot />
    </ul>
  </li>
</template>

<script>
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue'
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue'

export default {
  components: {
    ChevronLeftIcon,
    ChevronDownIcon,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
    parent: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped>
.chevron-left-icon,
.chevron-down-icon {
  bottom: 0;
  font-size: 1.25em;
  float: right;
}
.nav-link[data-toggle][aria-expanded=true] .chevron-left-icon {
  display: none;
}
.nav-link[data-toggle]:not([aria-expanded=true]) .chevron-down-icon {
  display: none;
}
</style>
