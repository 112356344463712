import axios from '@/axios/default'
import moment from 'moment'

export default {
  holidaysURL () { return '/holidays' },
  getHolidays () { return axios.get('/holidays') },
  createHoliday (h) { return axios.post('/holidays', h) },

  holidayURL (id) { return '/holidays/' + id },
  getHoliday (id) { return axios.get(this.holidayURL(id)) },
  updateHoliday (h) { return axios.put(this.holidayURL(h.id), h) },
  deleteHoliday (h) { return axios.delete(this.holidayURL(h.id)) },

  getHolidaysBetween (from, to) {
    const fromStr = moment(from).format('YYYY-MM-DD')
    const toStr = moment(to).format('YYYY-MM-DD')
    const url = '/holidays?sort=date&date_gte=' + fromStr + '&date_lte=' + toStr
    return axios.get(url)
  },
}
