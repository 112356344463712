<template>
  <Fragment>
    <Vuetable
      ref="vuetable"
      :api-url="apiUrl"
      :fields="fields"
      :track-by="trackBy"
      :append-params="appendParams"
      :per-page="perPage"
      :css="css"
      :http-options="httpOptions"
      multi-sort
      class="mb-3 flex-shrink-1 custom-vuetable-wrapper"
      :class="{ 'overflow-auto': overflow }"
      @vuetable:load-error="loadError"
      @vuetable:load-success="loadSuccess"
      @vuetable:pagination-data="onPaginationData"
      @vuetable:cell-clicked="onCellClicked"
    >
      <template #tableHeader>
        <CustomHeaderRows />
      </template>
      <template
        v-for="sl in Object.keys($scopedSlots)"
        #[sl]="props"
      >
        <slot
          :row-data="props.rowData"
          :row-index="props.rowIndex"
          :row-field="props.rowField"
          :name="sl"
        />
      </template>
    </Vuetable>
    <div class="pagination-container d-flex justify-content-between">
      <p
        v-if="error"
        class="text-danger"
      >
        {{ error }}
      </p>
      <template v-else>
        <VuetablePaginationInfo
          ref="paginationInfo"
          :css="css.pagination"
          class="text-nowrap mr-3"
        />
        <VuetablePagination
          ref="pagination"
          :css="css.pagination"
          class="noprint"
          @vuetable-pagination:change-page="onChangePage"
        />
      </template>
    </div>
  </Fragment>
</template>

<script>
/**
 * A customized Vuetable component which includes pagination components.
 */
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from '@/components/CustomVuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import CustomHeaderRows from '@/components/CustomHeaderRows'
import PapaParse from 'papaparse'
import Download from 'downloadjs'
import axios from '@/axios/default'
import { Fragment } from 'vue-fragment'
import debounce from 'lodash/debounce'

export default {
  components: {
    Fragment,
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    CustomHeaderRows,
  },
  props: {
    /** Vuetable fields prop */
    fields: {
      type: Array,
      required: true,
    },
    /** Vuetable apiUrl prop */
    apiUrl: {
      type: String,
      default: '',
    },
    /** Vuetable trackBy prop */
    trackBy: {
      type: String,
      default: 'id',
    },
    /** Vuetable perPage prop */
    perPage: {
      type: Number,
      default: 10,
    },
    defaultSort: {
      type: String,
      default: '',
    },
    // list of filters to apply if certain fields aren't set
    defaultFilters: {
      type: Array,
      default () {
        return []
      },
    },
    /** url params always set in api query */
    params: {
      type: Object,
      default () {
        return {}
      },
    },
    overflow: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      appendParams: {},
      error: '',
      httpOptions: {
        baseURL: process.env.VUE_APP_API_URL,
        withCredentials: true,
        transformResponse: this.transformResponse,
      },
      css: {
        tableClass: 'custom-vuetable table sticky-table-header',
        loadingClass: 'loading',
        pagination: {
          infoClass: '',
        },
      },
    }
  },
  watch: {
    '$route': function () { this.refreshParams() },
    'params': function () { this.debouncedRefreshParams() },
  },
  beforeMount () {
    this.updateParams()
  },
  created: function () {
    this.debouncedRefreshParams = debounce(this.refreshParams, 500)
  },
  methods: {
    updateParams () {
      const query = { ...this.$route.query }
      // add default params
      Object.assign(query, this.params)
      // add default sort
      if (!query.sort && this.defaultSort) {
        query.sort = this.defaultSort
      }
      // Add any filter params if needed.
      const keys = Object.keys(query)
      this.defaultFilters.forEach(filt => {
        // if all of the fields in the filter are not set, add filter params to query
        const fields = filt.fields.filter(f => keys.includes(f))
        if (fields.length === 0) {
          Object.assign(query, filt.params)
        }
      })
      this.appendParams = query
    },
    refreshParams () {
      this.updateParams()
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    onChangePage (page) {
      const cur = this.$route.query.page || 1
      if (page === 'next') {
        page = parseInt(cur) + 1
      } else if (page === 'prev') {
        page = cur - 1
      }
      this.$router.push({ path: this.$route.path, query: { ...this.$route.query, page: page } })
    },
    onCellClicked (event) {
      this.$emit('vuetable:cell-clicked', event)
    },
    loadError (error) {
      const response = error.response
      if (response) {
        if (response.data && response.data.error) {
          error.message = response.data.error
        } else if (response.statusText) {
          error.message = response.statusText
        }
        /* redirect to login if api authentication failed */
        if (response.status === 401) {
          this.$router.push({
            name: 'login',
            params: { redirect: this.$route.fullPath, errorMsg: error.message },
          })
        }
      }
      this.error = error.message
    },
    loadSuccess () {
      this.error = ''
    },
    /**
     * converts api request call result data to the format required by vuetable
     */
    transformResponse (data, headers) {
      if (typeof data === 'string') {
        try {
          data = JSON.parse(data)
        } catch (e) {
          return data
        }
      }
      let pag
      if (headers['pagination-limit']) {
        // pagination in headers
        pag = {
          total: parseInt(headers['pagination-count']),
          per_page: parseInt(headers['pagination-limit']),
          current_page: parseInt(headers['pagination-page']),
        }
      } else if (!data.pagination) {
        return data
      } else {
        // pagination already in response body
        pag = data.pagination
        pag.current_page = pag.page
        data = data.data
      }
      pag.last_page = Math.ceil(pag.total / pag.per_page)
      pag.from = 1 + (pag.current_page - 1) * pag.per_page
      pag.to = pag.from + pag.per_page - 1
      if (pag.to > pag.total) {
        pag.to = pag.total
      }
      data = {
        links: { pagination: pag },
        data: data,
      }
      return data
    },
    getNestedData (data, name) {
      let d = data
      const fields = name.split('.')
      while (fields.length > 0 && d) {
        const f = fields.shift()
        d = d[f]
      }
      return d
    },
    downloadCSV (filename) {
      const params = { ...this.appendParams }
      // remove pagination parameters
      delete params.page
      delete params.per_page
      axios.get(this.apiUrl, { params: params }).then(resp => {
        const fields = this.fields
        const header = fields.map(f => f.title)
        let data = resp.data
        if (data && data.data && Array.isArray(data.data)) {
          // result is stored in data field in response body
          data = data.data
        }
        const newData = data.map(d => fields.map(f => {
          if (Object.prototype.hasOwnProperty.call(f, 'csvRowFormatter')) {
            return f.csvRowFormatter(d)
          } else if (Object.prototype.hasOwnProperty.call(f, 'formatter')) {
            return f.formatter(d[f.name])
          } else if (Object.prototype.hasOwnProperty.call(f, 'rowFormatter')) {
            return f.rowFormatter(d)
          } else if (f.name.includes('.')) {
            return this.getNestedData(d, f.name)
          }
          return d[f.name]
        }))
        const csv = PapaParse.unparse({ fields: header, data: newData })
        Download(csv, filename, 'application/csv')
        this.$emit('csv-success')
      }).catch(error => {
        this.$emit('csv-error', error)
      })
    },
  },
}
</script>
