<template>
  <ValidationProvider
    v-slot="{ errors }"
    :rules="calcRules"
    :name="title"
    slim
  >
    <FormGroup
      :help="readonly ? '' : help"
      :title="title"
      :error="errors[0]"
      :required="required && !readonly"
      :horizontal="horizontal"
    >
      <pre v-if="readonly">{{ value }}</pre>
      <textarea
        v-else
        ref="textarea"
        v-model="innerValue"
        :name="name"
        :rows="rows"
        class="form-control"
        :class="{ 'is-invalid': errors[0] }"
        :disabled="disabled"
        @blur="innerValue = trimValue"
      />
    </FormGroup>
  </ValidationProvider>
</template>

<script>
import FormGroup from './FormGroup'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    FormGroup,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    rules: {
      type: Object,
      default () {
        return {}
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    rows: {
      type: Number,
      default: 4,
    },
    help: {
      type: String,
      default: '',
    },
    trim: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      innerValue: '',
    }
  },
  computed: {
    trimValue () {
      if (this.trim && (typeof this.innerValue === 'string' || this.innerValue instanceof String)) {
        // trim string
        const tv = this.innerValue.trim()
        // split into lines then trim spaces from end of lines
        const lines = tv.split(/\r?\n/).map(l => l.trimEnd())
        return lines.join('\n')
      }
      return this.innerValue
    },
    calcRules () {
      if (this.readonly) {
        return {}
      }
      return Object.assign({ required: this.required }, this.rules)
    },
  },
  watch: {
    value: {
      immediate: true,
      handler: function (newVal) {
        if (!this.trim || this.trimValue !== newVal) {
          this.innerValue = newVal
        }
      },
    },
    trimValue (newVal) {
      if (this.value !== newVal) {
        this.$emit('input', newVal)
      }
    },
  },
  methods: {
    focus () {
      this.$refs.textarea.focus()
    },
  },
}
</script>
