let routes = []

if (process.env.VUE_APP_FEATURE_PEERINGSTATS === 'true') {
  const PeeringStatsList = () => import(/* webpackChunkName: "peering" */ '@/views/Peering/PeeringStatsList')
  const PeeringRouterList = () => import(/* webpackChunkName: "peering" */ '@/views/Peering/PeeringRouterList')
  const PeeringRouterUpdate = () => import(/* webpackChunkName: "peering" */ '@/views/Peering/PeeringRouterUpdate')
  const PeeringNetworkList = () => import(/* webpackChunkName: "peering" */ '@/views/Peering/PeeringNetworkList')
  const PeeringNetworkUpdate = () => import(/* webpackChunkName: "peering" */ '@/views/Peering/PeeringNetworkUpdate')

  routes = [
    {
      path: 'peering_stats',
      name: 'peering_stats',
      props: true,
      component: PeeringStatsList,
      meta: {
        crumbs: [{ title: 'Peering' }],
        sidebar: {
          title: 'Peering',
          icon: 'share-alt',
        },
      },
    },
    {
      path: 'peering_routers',
      name: 'peering_router_list',
      props: true,
      component: PeeringRouterList,
    },
    {
      path: 'peering_routers/:routerId/update',
      name: 'peering_router_update',
      props: true,
      component: PeeringRouterUpdate,
    },
    {
      path: 'peering_networks',
      name: 'peering_network_list',
      props: true,
      component: PeeringNetworkList,
    },
    {
      path: 'peering_networks/:networkId/update',
      name: 'peering_network_update',
      props: true,
      component: PeeringNetworkUpdate,
    },
  ]
}

export default routes
