let routes = []

if (process.env.VUE_APP_FEATURE_CRDASHBOARD === 'true') {
  const CRDashboard = () => import(/* webpackChunkName: "crdashboard" */ '@/views/CRDashboard/CRDashboard')
  const CRCreate = () => import(/* webpackChunkName: "crdashboard" */ '@/views/CRDashboard/CRCreate')

  routes = [
    {
      path: 'cr_dashboard',
      name: 'cr_dashboard',
      props: true,
      component: CRDashboard,
      meta: {
        crumbs: [{ title: 'CR Dashboard' }],
        sidebar: {
          title: 'CR Dashboard',
          icon: 'list-alt',
        },
      },
    },
    {
      path: 'cr_dashboard/create',
      name: 'cr_create',
      props: true,
      component: CRCreate,
      meta: {
        crumbs: [
          { route: 'cr_dashboard', title: 'CR Dashboard' },
          { title: 'Create' },
        ],
      },
    },
  ]
}

export default routes
