<template>
  <div class="d-flex justify-content-end mt-2">
    <div class="small">
      Motion Version {{ version }} ©2021
      <a
        href="https://www.l2x.tech/"
        class="text-decoration-none"
      >L2X</a>. All rights reserved.
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    version () {
      // remove 'v' prefix
      let ver = process.env.VUE_APP_VERSION
      if (ver.startsWith('v')) {
        ver = ver.substring(1)
      } else if (ver.startsWith(process.env.VUE_APP_BRAND + '-v')) {
        ver = ver.substring(process.env.VUE_APP_BRAND.length + 2)
      } else if (ver.length > 8 && this.isHex(ver)) {
        return ver.substring(0, 8)
      }
      return ver
    },
  },
  methods: {
    isHex (s) {
      return /^[0-9A-Fa-f]+$/.test(s)
    },
  },
}
</script>
