<template>
  <nav
    v-show="tablePagination && tablePagination.last_page > 1"
    class="vuetable-pagination"
  >
    <ul class="pagination">
      <li :class="['page-item', { 'disabled': isOnFirstPage }]">
        <a
          class="page-link"
          href=""
          @click.prevent="loadPage(1)"
        >
          <ChevronDoubleLeftIcon title="" />
        </a>
      </li>

      <li :class="['page-item', { 'disabled': isOnFirstPage }]">
        <a
          class="page-link"
          href=""
          @click.prevent="loadPage('prev')"
        >
          <ChevronLeftIcon title="" />
        </a>
      </li>

      <template v-if="notEnoughPages">
        <li
          v-for="n in totalPage"
          :key="n"
          :class="['page-item', { 'active': isCurrentPage(n) }]"
        >
          <a
            class="page-link"
            href=""
            @click.prevent="loadPage(n)"
          >
            {{ n }}
          </a>
        </li>
      </template>
      <template v-else>
        <li
          v-for="n in windowSize"
          :key="n"
          :class="['page-item', { 'active': isCurrentPage(windowStart + n - 1) }]"
        >
          <a
            class="page-link"
            href=""
            @click.prevent="loadPage(windowStart + n - 1)"
          >
            {{ windowStart + n - 1 }}
          </a>
        </li>
      </template>

      <li :class="['page-item', { 'disabled': isOnLastPage }]">
        <a
          class="page-link"
          href=""
          @click.prevent="loadPage('next')"
        >
          <ChevronRightIcon title="" />
        </a>
      </li>
      <li :class="['page-item', { 'disabled': isOnLastPage }]">
        <a
          class="page-link"
          href=""
          @click.prevent="loadPage(totalPage)"
        >
          <ChevronDoubleRightIcon title="" />
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import PaginationMixin from 'vuetable-2/src/components/VuetablePaginationMixin'
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue'
import ChevronDoubleLeftIcon from 'vue-material-design-icons/ChevronDoubleLeft.vue'
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue'
import ChevronDoubleRightIcon from 'vue-material-design-icons/ChevronDoubleRight.vue'

export default {
  components: {
    ChevronLeftIcon,
    ChevronDoubleLeftIcon,
    ChevronRightIcon,
    ChevronDoubleRightIcon,
  },
  mixins: [PaginationMixin],
}
</script>

<style scoped>
.page-link {
  height: 2.5em;
  font-size: 0.9em;
  padding: 0.5em 1em;
}
.pagination {
  margin: 0;
}
</style>
