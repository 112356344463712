<template>
  <div>
    <h3 v-if="heading">
      {{ heading }}
    </h3>
    <div class="row">
      <div class="col-md-6">
        <BaseCard>
          <BaseForm
            :submit-title="submitTitle"
            :submit-promise="submitPromise"
            @cancel="goBack"
            v-on="$listeners"
          >
            <slot />
          </BaseForm>
        </BaseCard>
      </div>
      <div
        v-if="hasInfoSlot"
        class="col-md-6"
      >
        <slot name="info" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      default: '',
    },
    submitTitle: {
      type: String,
      required: true,
    },
    submitPromise: {
      type: Function,
      default: null,
    },
  },
  computed: {
    hasInfoSlot () {
      return !!this.$slots.info
    },
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
  },
}
</script>
