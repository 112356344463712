import axios from '@/axios/default'

export default {
  bookingTypesURL () { return '/booking_types' },
  getBookingTypes () { return axios.get('/booking_types') },
  getBookingType (id) { return axios.get('/booking_types/' + id) },
  getBookingSubtype (id) { return axios.get('/booking_subtypes/' + id) },
  getBookingSubtypes (id) { return axios.get('/booking_types/' + id + '/subtypes') },
  getAllBookingSubtypes () { return axios.get('/booking_subtypes') },
  getReviewStatuses (id) { return axios.get('/booking_types/' + id + '/review_statuses') },

  bookingSubtypesURL (groupID) { return '/project_types/' + groupID + '/booking_subtypes' },
  updateBookingSubtype (groupID, subtype) { return axios.put('/project_types/' + groupID + '/booking_subtypes/' + subtype.id, subtype) },

  getBookings (typeID, year, week) { return axios.get('/booking_types/' + typeID + '/bookings/' + year + '/' + week) },
  createBooking (booking) { return axios.post('/bookings', booking) },
  getBookingReviews (bookingID) { return axios.get('/bookings/' + bookingID + '/reviews', { params: { sort: 'created_on desc' } }) },
  createBookingReview (bookingID, status, comment) { return axios.post('/bookings/' + bookingID + '/reviews', { status: status, comment: comment }) },
  updateBookingStatus (bookingID, status) { return axios.put('/bookings/' + bookingID + '/status', { status: status }) },
  updateBookingProp (bookingID, name, value) { return axios.put(`/bookings/${bookingID}/${name}`, { value: value }) },

  searchCRs (params) { return axios.get('/crs', { params: params }) },
  createCR (cr) { return axios.post('/crs', cr) },
  getCRData (id) { return axios.get('/crs/' + id) },
  getCRActivities (id) { return axios.get('/crs/' + id + '/activities') },
  updateCR (cr) { return axios.put('/crs/' + cr.id, cr) },
  updateCRstatus (id, status) { return axios.put('/crs/' + id + '/status', status) },
  updateCrStartDate (id, startDate) { return axios.put('/crs/' + id + '/start_date', { start_date: startDate }) },
  updateCrEndDate (id, endDate) { return axios.put('/crs/' + id + '/end_date', { end_date: endDate }) },
  updateCrExecSummary (id, summ) { return axios.put('/crs/' + id + '/executive_summary', { executive_summary: summ }) },
  updateCrNextUpdate (id, updateTime, updateDetails) { return axios.put('/crs/' + id + '/next_update', { next_update_time: updateTime, next_update_details: updateDetails }) },
  updateCrRecipients (id, recipients) { return axios.put('/crs/' + id + '/recipients', { recipients: recipients }) },
  updateCrRole (id, role, usrId) { return axios.put('/crs/' + id + '/roles/' + role, { usrId }) },
  uploadCrAttachment (id, file) {
    const formData = new FormData()
    formData.append(file.name, file, file.name)
    return axios.post('/cr_activities/' + id + '/attachment', formData)
  },
  crAttachmentDownloadURL (id) { return '/cr_activities/' + id + '/attachment_download' },
  deleteCrActivity (crId, activityId) { return axios.delete('/crs/' + crId + '/activities/' + activityId) },
  createCrActivity (crId, activity) { return axios.post('/crs/' + crId + '/activities', activity) },
  sendPostCrReport (crId) { return axios.post('/crs/' + crId + '/send_post_cr_report') },
  searchCrProjects (search) {
    const params = { per_page: 300 }
    if (search) {
      params.search = search
    }
    return axios.get('cr_projects', { params: params })
  },
}
