<template>
  <div class="form-group">
    <div :class="horizontal ? 'row align-items-center' : ''">
      <label
        v-if="title"
        :class="[{ required: required }, horizontal ? 'col-form-label col-md-3' : '']"
      >{{ title }}</label>
      <div :class="{ 'col-md-9': horizontal }">
        <slot />
      </div>
    </div>
    <div :class="{ row: horizontal }">
      <div
        v-if="error"
        class="invalid-feedback"
        :class="horizontal ? 'col-md-9 offset-md-3' : ''"
      >
        {{ error }}
      </div>
      <span
        v-else-if="help"
        class="small"
        :class="horizontal ? 'col-md-9 offset-md-3' : ''"
      >
        {{ help }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    help: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.invalid-feedback {
  display: block;
}
label.required:after {
    content: '*';
}
</style>
