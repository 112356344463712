import { render, staticRenderFns } from "./BaseFormButton.vue?vue&type=template&id=83040fc2&scoped=true&"
import script from "./BaseFormButton.vue?vue&type=script&lang=js&"
export * from "./BaseFormButton.vue?vue&type=script&lang=js&"
import style0 from "./BaseFormButton.vue?vue&type=style&index=0&id=83040fc2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83040fc2",
  null
  
)

export default component.exports