<template>
  <Modal
    ref="modal"
    title="Change State"
  >
    <div v-if="notifications.length > 0">
      <p>{{ notifications[0] }}</p>
      <div class="d-flex justify-content-center">
        <button
          class="btn btn-primary"
          @click.prevent="acknowledgeNotification"
        >
          Ok
        </button>
      </div>
    </div>
    <BaseForm
      v-else
      submit-title="Save"
      @submit="submit"
      @cancel="cancel"
    >
      <BaseInput
        :value="projectStateName"
        title="Current Status"
        name="project_state"
        readonly
      />
      <BaseSelect
        v-show="transitions.length > 0"
        v-model="transition"
        :options="transitions"
        title="New Status"
        name="transition"
        track-by="id"
        label="dst_state_name"
        required
      />
      <BaseInput
        v-if="transitions.length === 0"
        value="No states"
        title="New Status"
        name="transition"
        readonly
      />
      <BaseTextarea
        v-if="commentRequired"
        v-model="comment"
        :rows="4"
        title="Comment"
        name="comment"
        required
        trim
      />
      <FieldError v-model="error" />
    </BaseForm>
  </Modal>
</template>

<script>
/**
 * Modal to change a Projects state.
 * Emits a 'state-change' even with project record when state is changed.
 */
import Modal from '@/components/Modal'
import FieldError from '@/components/FieldError'
import projectAPI from '@/api/project'

export default {
  components: {
    Modal,
    FieldError,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      error: '',
      comment: null,
      notifications: [],
      transition: null,
      transitions: [],
    }
  },
  computed: {
    commentRequired () {
      return this.transition && this.transition.comment_required
    },
    projectStateName () {
      if (this.project.project_state) {
        return this.project.project_state.name
      }
      return ''
    },
  },
  methods: {
    reset () {
      this.error = ''
      this.comment = null
      this.notifications = []
      this.transition = null
      this.transitions = []
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    edit (transitions) {
      this.reset()
      // add dst_state.name to base transition object so we can use it as a label in the select
      transitions = transitions.map(t => { return { ...t, dst_state_name: t.dst_state.name } })
      // sort by dest state name
      this.transitions = transitions.sort((a, b) => a.dst_state_name > b.dst_state_name)
      if (this.transitions.length <= 0) {
        return
      }
      this.$refs.modal.show()
    },
    save () {
      // clear comment if its not required
      if (!this.transition.comment_required) {
        this.comment = null
      }
      // create next state object
      const nextState = Object.assign({ comment: this.comment }, this.transition.dst_state)
      projectAPI.updateState(this.project.id, nextState).then(resp => {
        /** project state changed */
        this.$emit('state-change', this.transition.dst_state)
        this.cancel()
      }).catch(error => {
        this.error = error.message
      })
    },
    acknowledgeNotification () {
      if (this.notifications.length > 1) {
        this.notifications.shift()
      } else {
        this.notifications = []
        this.save()
      }
    },
    submit () {
      if (!this.transition) {
        return
      }
      if (this.project.services) {
        const notifications = this.project.services.filter(s =>
          s.service.notification && s.service.notification_states && s.service.notification_states.includes(this.transition.dst_state.name),
        ).map(s => s.service.notification)
        // remove duplicates
        this.notifications = notifications.filter((v, i, a) => a.indexOf(v) === i)
      }
      if (this.notifications.length === 0) {
        this.save()
      }
    },
  },
}
</script>
