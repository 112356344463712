<template>
  <nav
    class="levelbar"
    aria-label="breadcrumb"
  >
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link to="/">
          <font-awesome-icon icon="home" />
          Home
        </router-link>
      </li>
      <template v-for="(r, index) in routes">
        <li
          v-if="index === (routes.length - 1) || !r.name"
          :key="index"
          class="breadcrumb-item active"
          v-text="r.title"
        />
        <li
          v-else
          :key="index"
          class="breadcrumb-item"
        >
          <router-link
            :to="{ name: r.name }"
            v-text="r.title"
          />
        </li>
      </template>
    </ol>
  </nav>
</template>

<script>
/**
 * A component that displays breadcrumbs from the current list of matched routes.
 */
export default {
  computed: {
    /**
     * @description list of routes for breadcrumb
     * @returns {Array}
     */
    routes () {
      const rs = []
      for (const r of this.$route.matched) {
        if (r.meta.crumbs) {
          for (const bc of r.meta.crumbs) {
            let title = bc.title
            const name = bc.route || r.name
            if (bc.param) {
              // get name from url parameter
              title = this.$route.params[bc.param]
            } else if (bc.getter) {
              title = this.$store.getters[bc.getter]
            }
            rs.push({ title: title, name: name })
          }
        }
      }
      return rs
    },
  },
}
</script>

<style lang="scss" scoped>
.levelbar {
  position: fixed;
  top: $navbar-height;
  left: $sidebar-width;
  width: calc(100% - #{$sidebar-width});
  height: $levelbar-height;
  .breadcrumb {
    padding: 10px 18px;
    background-color: #eee;
  }
  // $zindex-fixed - 1
  z-index: 1029;
}
li a {
  text-decoration: none;
}
</style>
