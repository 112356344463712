<template>
  <FormGroup
    :title="horizontal ? title : ''"
    :help="disabled ? '' : help"
    :horizontal="horizontal"
  >
    <div class="custom-control custom-checkbox">
      <input
        :id="name"
        v-model="innerValue"
        :name="name"
        :disabled="disabled"
        class="custom-control-input"
        type="checkbox"
      >
      <label
        class="custom-control-label"
        :for="name"
      >
        {{ horizontal ? '' : title }}
      </label>
    </div>
  </FormGroup>
</template>

<script>
import FormGroup from './FormGroup'

export default {
  components: {
    FormGroup,
  },
  props: {
    value: {
      type: [Boolean, String],
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    help: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerValue: {
      get () { return this.value },
      set (checked) { this.$emit('input', checked) },
    },
  },
}
</script>
