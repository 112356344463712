<template>
  <ValidationProvider
    v-slot="{ errors }"
    :rules="calcRules"
    :name="title"
    slim
  >
    <FormGroup
      :title="title"
      :help="disabled ? '' : help"
      :error="errors[0]"
      :horizontal="horizontal"
    >
      <input
        v-model.number="innerValue"
        :name="name"
        :disabled="disabled"
        :min="min"
        :max="max"
        :step="step"
        class="custom-range"
        type="range"
      >
    </FormGroup>
  </ValidationProvider>
</template>

<script>
import FormGroup from './FormGroup'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    FormGroup,
    ValidationProvider,
  },
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    rules: {
      type: Object,
      default () {
        return {}
      },
    },
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    help: {
      type: String,
      default: '',
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 1,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerValue: {
      get () { return this.value },
      set (n) { this.$emit('input', n) },
    },
    calcRules () {
      const r = Object.assign({ required: this.required, integer: true }, this.rules)
      if (this.min !== null && this.min !== undefined) {
        if (this.max !== null && this.max !== undefined) {
          r.between = { min: this.min, max: this.max }
        } else {
          r.min_value = this.min
        }
      } else if (this.max !== null && this.max !== undefined) {
        r.max_value = this.max
      }
      return r
    },
  },
}
</script>
