const ProjectBookingList = () => import(/* webpackChunkName: "bookings" */ '@/views/Booking/ProjectBookingList')
const ProjectBookingCreate = () => import(/* webpackChunkName: "bookings" */ '@/views/Booking/ProjectBookingCreate')

export default [
  {
    path: 'booking_types/:bookingTypeId/bookings',
    name: 'bookings',
    props: true,
    component: ProjectBookingList,
    meta: {
      crumbs: [{ title: 'Review Bookings' }],
    },
  },
  {
    path: 'booking_types/:bookingTypeId/bookings/create',
    name: 'booking_create',
    props: true,
    component: ProjectBookingCreate,
    meta: {
      crumbs: [{ title: 'Review Bookings' }],
    },
  },
]
