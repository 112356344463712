<template>
  <Modal
    ref="modal"
    title="Review History"
    :extra-large="true"
    :scrollable="true"
  >
    <div class="review-timeline">
      <div class="row review-timeline-header">
        <div class="col-md-2">
          Date
        </div>
        <div class="col-md-2">
          Status
        </div>
        <div class="col-md-2">
          User
        </div>
        <div class="col">
          Comment
        </div>
      </div>
      <div
        v-for="r in reviews"
        :key="r.id"
        class="row"
      >
        <div class="col-md-2">
          {{ formatDateTime(r.created_on) }}
        </div>
        <div class="col-md-2">
          {{ r.status.name }}
        </div>
        <div class="col-md-2">
          {{ r.creator.name }}
        </div>
        <div class="col">
          {{ r.comment }}
        </div>
      </div>
      <div
        v-if="loading"
        class="text-center"
      >
        <div
          class="spinner-border"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div
        v-if="error"
        class="alert alert-danger"
      >
        {{ error }}
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
import bookingAPI from '@/api/booking'
import formatDateTime from '@/filters/formatDateTime'

export default {
  components: {
    Modal,
  },
  data () {
    return {
      loading: false,
      error: '',
      reviews: [],
    }
  },
  methods: {
    reset () {
      this.loading = false
      this.error = ''
      this.reviews = []
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    show (bookingId) {
      this.reset()
      const timeout = setTimeout(() => { this.loading = true }, 500)
      bookingAPI.getBookingReviews(bookingId).then(resp => {
        clearTimeout(timeout)
        this.reviews = resp.data
        this.loading = false
      }).catch(error => {
        clearTimeout(timeout)
        this.loading = false
        this.error = error.message
      })
      this.$refs.modal.show()
    },
    formatDateTime,
  },
}
</script>

<style lang="scss" scoped>
.review-timeline {
  .row {
    margin-bottom: 10px;
  }
  .review-timeline-header {
    font-weight: 700;
  }
}
.modal ::v-deep .modal-content {
  min-height: calc(100vh - 3.5rem);
}
</style>
