<template>
  <ValidationProvider
    v-slot="{ errors }"
    :rules="calcRules"
    :name="title"
    slim
  >
    <FormGroup
      :title="title"
      :help="readonly || disabled ? '' : help"
      :error="errors[0]"
      :required="required && !readonly && !disabled"
      :horizontal="horizontal"
    >
      <div
        class="input-group"
        :class="{ 'is-invalid': errors[0] }"
      >
        <input
          v-if="readonly"
          :value="formatCurrency(value)"
          type="text"
          readonly
          class="form-control-plaintext"
        >
        <template v-else>
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input
            v-model="innerValue"
            :name="name"
            :placeholder="placeholder"
            :disabled="disabled"
            :readonly="readonly"
            :class="{ 'is-invalid': errors[0] }"
            :min="min"
            class="form-control"
            type="number"
          >
          <div class="input-group-append">
            <span class="input-group-text">.00</span>
          </div>
        </template>
      </div>
    </FormGroup>
  </ValidationProvider>
</template>

<script>
import FormGroup from './FormGroup'
import formatCurrency from '@/filters/formatCurrency'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    FormGroup,
    ValidationProvider,
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    rules: {
      type: Object,
      default () {
        return {}
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    help: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    innerValue: {
      get () { return this.value },
      set (n) { n === '' ? this.$emit('input', null) : this.$emit('input', n) },
    },
    calcRules () {
      if (this.readonly) {
        return {}
      }
      return Object.assign({ required: this.required, integer: true }, this.rules)
    },
  },
  methods: {
    formatCurrency,
  },
}
</script>
