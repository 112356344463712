<template>
  <div
    v-if="show"
    class="modal in"
    tabindex="-1"
    role="dialog"
  >
    <div
      class="modal-dialog"
      :class="customClass"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            {{ title }}
          </h4>
          <button
            type="button"
            class="close"
            @click.prevent="$emit('update:show', false)"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * A Modal component
 */
export default {
  props: {
    /** title of modal */
    title: {
      type: String,
      required: true,
    },
    large: {
      type: Boolean,
      default: false,
    },
    extraLarge: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      required: true,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    customClass () {
      return {
        'modal-lg': this.large,
        'modal-xl': this.extraLarge,
        'modal-dialog-scrollable': this.scrollable,
      }
    },
  },
}
</script>

<style scoped>
.modal.in {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
