<template>
  <Modal
    ref="modal"
    title="Update Work Completion Date"
  >
    <BaseForm
      submit-title="Save"
      @submit="save"
      @cancel="cancel"
    >
      <BaseInputDate
        v-model="delivery_date"
        title="Work Completion Date"
        help="This is requested date only. Based on the requirements, the actual delivery date will be proposed with the quote."
        name="delivery_date"
        required
      />
      <FieldError v-model="error" />
    </BaseForm>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
import FieldError from '@/components/FieldError'
import projectAPI from '@/api/project'

export default {
  components: {
    Modal,
    FieldError,
  },
  data () {
    return {
      error: '',
      project: {},
      delivery_date: '',
    }
  },
  methods: {
    reset () {
      this.error = ''
      this.project = {}
      this.delivery_date = ''
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    edit (project) {
      this.reset()
      this.project = project
      this.delivery_date = project.p4p.delivery_date
      this.$refs.modal.show()
    },
    save () {
      projectAPI.updateDeliveryDate(this.project.id, this.delivery_date).then(resp => {
        this.project.p4p.delivery_date = this.delivery_date
        this.cancel()
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
