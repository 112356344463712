<template>
  <FormGroup
    v-show="hasAttachments"
    title="Attachments"
  >
    <table>
      <tr
        v-for="(at, typeName) in groupedAttachments"
        :key="typeName"
      >
        <td class="align-top">
          {{ typeName }}
        </td>
        <td class="align-top pl-3">
          <div
            v-for="a in at"
            :key="at.id + '_' + a.id"
          >
            <a
              :href="downloadLink(a.id)"
            >{{ a.filename }}</a>
          </div>
        </td>
        <td />
      </tr>
    </table>
  </FormGroup>
</template>

<script>
import FormGroup from '@/components/forms/FormGroup'
import projectAPI from '@/api/project'

export default {
  components: {
    FormGroup,
  },
  props: {
    attachments: {
      type: Array,
      required: true,
    },
  },
  computed: {
    groupedAttachments () {
      // group attachments by type name
      return this.attachments.reduce(function (atlist, at) {
        if (!atlist[at.type_name]) {
          atlist[at.type_name] = [at]
        } else {
          // filter out older files with same name and type
          atlist[at.type_name] = atlist[at.type_name].filter(a => a.filename !== at.filename)
          atlist[at.type_name].push(at)
        }
        return atlist
      }, {})
    },
    hasAttachments () {
      return Object.keys(this.groupedAttachments).length > 0
    },
  },
  methods: {
    downloadLink (id) {
      return process.env.VUE_APP_API_URL + projectAPI.attachmentDownloadURL(id)
    },
  },
}
</script>
