<template>
  <Modal
    ref="modal"
    title="Add Comment"
  >
    <BaseForm
      submit-title="Save"
      @submit="save"
      @cancel="cancel"
    >
      <BaseTextarea
        v-model="comment"
        :rows="4"
        title="Comment"
        name="comment"
        required
        trim
      />
      <template v-if="roles.length > 0">
        <BaseInputCheckbox
          v-model="emailNotification"
          title="Email Notification"
          name="emailNotification"
        />
        <BaseSelect
          v-if="emailNotification"
          v-model="recipients"
          :options="roles"
          name="recipients"
          placeholder="Select roles"
          multiple
          required
        />
      </template>
      <FieldError v-model="error" />
    </BaseForm>
  </Modal>
</template>

<script>
/**
 * Modal to add a comment to a project timeline (project update).
 * Emit's a 'create-update' event when successful.
 */
import Modal from '@/components/Modal'
import FieldError from '@/components/FieldError'
import projectAPI from '@/api/project'
import groupAPI from '@/api/group'

export default {
  components: {
    FieldError,
    Modal,
  },
  props: {
    /** project for comment */
    project: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      error: '',
      comment: '',
      emailNotification: false,
      groupRoles: [],
      recipients: [],
    }
  },
  computed: {
    roles () {
      if (!this.project || !this.project.roles) {
        return []
      }
      // get list of project role names
      const rs = this.project.roles.filter(r => r.usr).map(r => r.usr_role.name)
      if (this.project.team) {
        rs.push('Team Manager')
      }
      return rs.concat(this.groupRoles)
    },
  },
  beforeMount () {
    // TODO: also check on route update?
    this.getGroupRoles()
  },
  methods: {
    reset () {
      this.error = ''
      this.comment = ''
      this.emailNotification = false
      this.recipients = []
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    /** open modal to add comment to project */
    show () {
      this.reset()
      this.$refs.modal.show()
    },
    getGroupRoles () {
      if (!this.project || !this.project.project_type) {
        return
      }
      const ptID = this.project.project_type.id
      groupAPI.getGroupRoles(ptID).then(resp => {
        this.groupRoles = resp.data.filter(r => !r.is_project_role && r.allow_project_update_email).map(r => r.name)
      }).catch(error => {
        this.error = error.message
      })
    },
    save () {
      if (!this.emailNotification) {
        this.recipients = []
      }
      projectAPI.createProjectUpdate(this.project.id, this.comment, this.recipients).then(resp => {
        this.$emit('create-update')
        this.cancel()
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
