<template>
  <VPopover
    :offset="6"
    popover-wrapper-class="popover-wrapper"
  >
    <div style="padding: 0.375rem 0.75rem;">
      <FormatColumnsIcon title="" />
      Columns
    </div>
    <template #popover>
      <div>
        <div class="pb-1 px-1">
          <a
            href="#"
            @click.prevent="selectAll"
          >Select all</a>
          <a
            href="#"
            class="float-right"
            @click.prevent="reset"
          >Reset</a>
        </div>
        <div
          class="filter-options overflow-auto p-1"
          style="max-height: 270px;"
        >
          <div
            v-for="f in userFields"
            :key="f.name"
            class="d-flex filter-option"
          >
            <div class="custom-control custom-checkbox d-inline-block">
              <input
                :id="`column-filter-${f.name}`"
                :checked="f.visible"
                type="checkbox"
                class="custom-control-input"
                :disabled="f.visible && selectedCount === 1"
                @input="toggleField(f.name)"
              >
              <label
                class="custom-control-label user-select-none font-weight-normal text-nowrap"
                :for="`column-filter-${f.name}`"
              />
            </div>
            <div class="w-100 d-inline-block">
              {{ f.shortTitle || f.title || f.name }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </VPopover>
</template>

<script>
import { VPopover } from 'v-tooltip'
import FormatColumnsIcon from 'vue-material-design-icons/FormatColumns.vue'

export default {
  components: {
    VPopover,
    FormatColumnsIcon,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    fieldsKey: {
      type: String,
      default: '',
    },
  },
  computed: {
    innerValue: {
      get () { return this.value },
      set (n) { this.$emit('input', n) },
    },
    // localStorage field name for visible fields
    localFieldName () {
      if (this.fieldsKey) {
        return `${this.$route.name}:${this.fieldsKey}:visibleFields`
      }
      return `${this.$route.name}:visibleFields`
    },
    defaultFields () {
      // fix visible field in fields
      return this.fields.map(f => {
        return { ...f, visible: !!f.visible || f.visible === undefined }
      })
    },
    userFields () {
      const fields = this.fields.map(f => { return { ...f, visible: this.value.includes(f.name) } })
      if (fields.findIndex(f => f.visible) >= 0) {
        return fields
      }
      return this.defaultFields
    },
    selectedCount () {
      return this.userFields.filter(f => f.visible).length
    },
  },
  watch: {
    localFieldName: {
      handler: function () {
        this.innerValue = this.getFieldsFromStorage()
      },
      immediate: true,
    },
  },
  methods: {
    saveUserFields (fields) {
      // only save name and visible flag
      if (fields.length > 0) {
        localStorage.setItem(this.localFieldName, JSON.stringify(fields))
      } else {
        localStorage.removeItem(this.localFieldName)
      }
      this.innerValue = fields
    },
    reset () {
      this.saveUserFields([])
    },
    selectAll () {
      const fields = this.fields.map(f => f.name)
      this.saveUserFields(fields)
    },
    toggleField (name) {
      const fields = this.userFields.filter(f => f.visible).map(f => f.name)
      const i = fields.findIndex(f => f === name)
      if (i >= 0) {
        fields.splice(i, 1)
      } else {
        fields.push(name)
      }
      this.saveUserFields(fields)
    },
    getFieldsFromStorage () {
      let fields = localStorage.getItem(this.localFieldName)
      try {
        fields = JSON.parse(fields)
      } catch (e) {
        return []
      }
      return Array.isArray(fields) ? fields : []
    },
  },
}
</script>

<style lang="scss" scoped>
// hack to  hide label
.custom-control {
   padding-left: 0px;
   .custom-control-label {
     width: 24px;
     &::before,
     &::after {
       left: 0;
     }
   }
}
</style>
