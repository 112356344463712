let routes = []

if (process.env.VUE_APP_FEATURE_INVENTORY === 'true') {
  const Inventory = () => import(/* webpackChunkName: "inventory" */ '@/views/Inventory/Inventory')
  const CreateServer = () => import(/* webpackChunkName: "inventory" */ '@/views/Inventory/CreateServer')
  const ShowServer = () => import(/* webpackChunkName: "inventory" */ '@/views/Inventory/Server')
  const UpdateServer = () => import(/* webpackChunkName: "inventory" */ '@/views/Inventory/UpdateServer')

  routes = [
    {
      path: 'inventory/servers',
      name: 'inventory',
      component: Inventory,
      meta: {
        crumbs: [{ title: 'Inventory' }],
        sidebar: {
          title: 'Inventory',
          icon: 'server',
        },
      },
    },
    {
      path: 'inventory/servers/create',
      name: 'create_server',
      component: CreateServer,
      meta: {
        crumbs: [
          { route: 'inventory', title: 'Inventory' },
          { title: 'Create Server' },
        ],
      },
    },
    {
      path: 'inventory/servers/:serverId/update',
      name: 'update_server',
      component: UpdateServer,
      props: true,
      meta: {
        crumbs: [{ title: 'Update Server' }],
      },
    },
    {
      path: 'inventory/servers/:serverId',
      props: true,
      name: 'show_server',
      component: ShowServer,
      meta: {
        crumbs: [{ title: 'Server' }],
      },
    },
  ]
}

export default routes
