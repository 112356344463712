<template>
  <Modal
    ref="modal"
    title="Additional Project Quote"
  >
    <BaseForm
      submit-title="Create"
      @submit="create"
      @cancel="cancel"
    >
      <BaseTextarea
        v-model="quote.scope"
        :rows="10"
        title="Scope"
        name="scope"
        required
        trim
      />
      <BaseInputPrice
        v-model.number="quote.price"
        title="Price"
        name="price"
        :min="null"
        required
      />
      <FieldError v-model="error" />
    </BaseForm>
  </Modal>
</template>

<script>
/**
 * Modal to add a comment to a project timeline (project update).
 * Emit's a 'create-update' event when successful.
 */
import Modal from '@/components/Modal'
import FieldError from '@/components/FieldError'
import projectAPI from '@/api/project'

export default {
  components: {
    FieldError,
    Modal,
  },
  props: {
    /** project for comment */
    project: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      quote: {
        scope: '',
        price: '',
      },
      error: '',
    }
  },
  methods: {
    reset () {
      this.error = ''
      this.quote = { scope: '', price: '' }
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    show () {
      this.reset()
      this.$refs.modal.show()
    },
    create () {
      projectAPI.createQuote(this.project.id, this.quote).then(resp => {
        this.$emit('created', resp.data)
        this.cancel()
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
