import moment from 'moment'

export default function (value, fmt = 'Do MMM YYYY') {
  if (value) {
    let m
    if (typeof value === 'string') {
      m = moment(value, 'YYYY-MM-DD')
    } else if (value instanceof Date) {
      m = moment(value)
    }
    if (m && m.isValid) {
      return m.format(fmt)
    }
  }
}
