<template>
  <div
    :class="type ? `border-${type}` : ''"
    class="card"
  >
    <div
      v-if="hasHeader"
      :class="type ? [
        `border-${type}`,
        `bg-${type}`,
        type !== 'light' ? 'text-white' : '',
      ] : ''"
      class="card-header"
    >
      <slot name="header" />
    </div>
    <div class="card-body">
      <slot />
    </div>
    <div
      v-if="hasFooter"
      class="card-footer"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasHeader () {
      return !!this.$slots.header
    },
    hasFooter () {
      return !!this.$slots.footer
    },
  },
}
</script>
