import axios from '@/axios/default'

export default {
  groupsURL () { return '/project_types' },
  getGroups () { return axios.get('/project_types') },
  createGroup (g) { return axios.post('/project_types', g) },
  getGroup (id) { return axios.get('/project_types/' + id) },
  updateGroup (g) { return axios.put('/project_types/' + g.id, g) },
  deleteGroup (g) { return axios.delete('/project_types/' + g.id) },

  getGroupNames () {
    return axios.get('/project_types').then(r => {
      return Promise.resolve(r.data.map(g => g.name))
    })
  },

  getProjectStates (id) { return axios.get('/project_types/' + id + '/project_states') },

  getStateNames () {
    return axios.get('/project_states').then(r => {
      const names = r.data.map(s => s.name).filter((v, i, a) => a.indexOf(v) === i)
      names.sort()
      return Promise.resolve(names)
    })
  },

  propertiesURL (groupID) { return '/project_types/' + groupID + '/properties' },
  propertyURL (groupID, propID) { return '/project_types/' + groupID + '/properties/' + propID },
  getProperties (groupID) { return axios.get(this.propertiesURL(groupID)) },
  createProperty (groupID, prop) { return axios.post(this.propertiesURL(groupID), prop) },
  getProperty (groupID, propID) { return axios.get(this.propertyURL(groupID, propID)) },
  updateProperty (groupID, prop) { return axios.put(this.propertyURL(groupID, prop.id), prop) },
  deleteProperty (groupID, prop) { return axios.delete(this.propertyURL(groupID, prop.id)) },

  getNewProjectSchema (groupID) { return axios.get('/project_types/' + groupID + '/new_project_schema') },

  propertyOptionsURL (groupID) { return '/project_types/' + groupID + '/property_options' },
  propertyOptionURL (groupID, propID) { return '/project_types/' + groupID + '/property_options/' + propID },
  getPropertyOptions (groupID) { return axios.get(this.propertyOptionsURL(groupID), { params: { sort: 'ord,name' } }) },
  createPropertyOption (groupID, prop) { return axios.post(this.propertyOptionsURL(groupID), prop) },
  getPropertyOption (groupID, propID) { return axios.get(this.propertyOptionURL(groupID, propID)) },
  updatePropertyOption (groupID, prop) { return axios.put(this.propertyOptionURL(groupID, prop.id), prop) },
  deletePropertyOption (groupID, prop) { return axios.delete(this.propertyOptionURL(groupID, prop.id), prop) },

  attachmentTypesURL (groupID) { return '/project_types/' + groupID + '/attachment_types' },
  attachmentTypeURL (atID) { return '/attachment_types/' + atID },
  getAttachmentTypes (groupID) { return axios.get(this.attachmentTypesURL(groupID), { params: { sort: 'ord,name' } }) },
  createAttachmentType (groupID, at) { return axios.post(this.attachmentTypesURL(groupID), at) },
  getAttachmentType (atID) { return axios.get(this.attachmentTypeURL(atID)) },
  updateAttachmentType (at) { return axios.put(this.attachmentTypeURL(at.id), at) },

  attachmentTypeTemplateURL (groupID, atID) { return '/project_types/' + groupID + '/attachment_types/' + atID + '/template' },
  uploadAttachmentTypeTemplate (groupID, atID, file) {
    const formData = new FormData()
    formData.append(file.name, file, file.name)
    return axios.post(this.attachmentTypeTemplateURL(groupID, atID), formData)
  },
  deleteAttachmentTypeTemplate (groupID, atID) { return axios.delete(this.attachmentTypeTemplateURL(groupID, atID)) },
  attachmentTypeTemplateDownloadURL (atID) { return '/attachment_types/' + atID + '/template' },

  serviceGroupsURL (groupID) { return '/project_types/' + groupID + '/service_groups' },
  serviceGroupURL (groupID, sgID) { return '/project_types/' + groupID + '/service_groups/' + sgID },
  getServiceGroups (groupID) { return axios.get(this.serviceGroupsURL(groupID)) },
  createServiceGroup (groupID, sg) { return axios.post(this.serviceGroupsURL(groupID), sg) },
  getServiceGroup (groupID, sgID) { return axios.get(this.serviceGroupURL(groupID, sgID)) },
  updateServiceGroup (groupID, sg) { return axios.put(this.serviceGroupURL(groupID, sg.id), sg) },
  deleteServiceGroup (groupID, sg) { return axios.delete(this.serviceGroupURL(groupID, sg.id)) },

  servicesURL (groupID) { return '/project_types/' + groupID + '/services' },
  serviceURL (groupID, sID) { return '/project_types/' + groupID + '/services/' + sID },
  getServices (groupID) { return axios.get(this.servicesURL(groupID)) },
  createService (groupID, s) { return axios.post(this.servicesURL(groupID), s) },
  getService (groupID, sID) { return axios.get(this.serviceURL(groupID, sID)) },
  updateService (groupID, s) { return axios.put(this.serviceURL(groupID, s.id), s) },
  deleteService (groupID, s) { return axios.delete(this.serviceURL(groupID, s.id)) },

  shortServicesURL (groupID) { return '/project_types/' + groupID + '/short_services' },
  getShortServices (groupID) { return axios.get(this.shortServicesURL(groupID)) },

  serviceIconsURL (groupID) { return '/project_types/' + groupID + '/service_icons' },
  serviceIconURL (groupID, ID) { return '/project_types/' + groupID + '/service_icons/' + ID },
  getServiceIcons (groupID) { return axios.get(this.serviceIconsURL(groupID)) },
  createServiceIcon (groupID, file) {
    const formData = new FormData()
    formData.append(file.name, file, file.name)
    return axios.post(this.serviceIconsURL(groupID), formData)
  },
  updateServiceIcon (groupID, ID, file) {
    const formData = new FormData()
    formData.append(file.name, file, file.name)
    return axios.put(this.serviceIconURL(groupID, ID), formData)
  },

  serviceAttachmentsURL (serviceId) { return '/services/' + serviceId + '/attachments' },
  serviceAttachmentURL (serviceId, attachmentTypeId) { return '/services/' + serviceId + '/attachments/' + attachmentTypeId },
  getServiceAttachment (serviceId, attachmentTypeId) { return axios.get(this.serviceAttachmentURL(serviceId, attachmentTypeId)) },
  updateServiceAttachment (serviceId, attachmentTypeId, states) { return axios.put(this.serviceAttachmentURL(serviceId, attachmentTypeId), { project_states: states }) },

  emailTemplatesURL (groupID) { return '/project_types/' + groupID + '/email_templates' },
  emailTemplateURL (groupID, tID) { return '/project_types/' + groupID + '/email_templates/' + tID },
  getEmailTemplates (groupID) { return axios.get(this.emailTemplatesURL(groupID)) },
  createEmailTemplate (groupID, t) { return axios.post(this.emailTemplatesURL(groupID), t) },
  getEmailTemplate (groupID, tID) { return axios.get(this.emailTemplateURL(groupID, tID)) },
  updateEmailTemplate (groupID, t) { return axios.put(this.emailTemplateURL(groupID, t.id), t) },
  deleteEmailTemplate (groupID, t) { return axios.delete(this.emailTemplateURL(groupID, t.id)) },

  milestonesURL (groupID) { return '/project_types/' + groupID + '/milestones' },
  milestoneURL (groupID, mID) { return '/project_types/' + groupID + '/milestones/' + mID },
  getMilestones (groupID) { return axios.get(this.milestonesURL(groupID)) },
  createMilestone (groupID, m) { return axios.post(this.milestonesURL(groupID), m) },
  getMilestone (groupID, mID) { return axios.get(this.milestoneURL(groupID, mID)) },
  updateMilestone (groupID, m) { return axios.put(this.milestoneURL(groupID, m.id), m) },
  deleteMilestone (groupID, m) { return axios.delete(this.milestoneURL(groupID, m.id)) },

  milestoneGroupsURL (groupID) { return '/project_types/' + groupID + '/milestone_groups' },
  milestoneGroupURL (groupID, mID) { return '/project_types/' + groupID + '/milestone_groups/' + mID },
  getMilestoneGroups (groupID) { return axios.get(this.milestoneGroupsURL(groupID)) },
  createMilestoneGroup (groupID, m) { return axios.post(this.milestoneGroupsURL(groupID), m) },
  getMilestoneGroup (groupID, mID) { return axios.get(this.milestoneGroupURL(groupID, mID)) },
  updateMilestoneGroup (groupID, m) { return axios.put(this.milestoneGroupURL(groupID, m.id), m) },
  deleteMilestoneGroup (groupID, m) { return axios.delete(this.milestoneGroupURL(groupID, m.id)) },

  projectPermissionsURL (groupID) { return '/project_types/' + groupID + '/project_permissions' },
  projectPermissionURL (groupID, tID) { return '/project_types/' + groupID + '/project_permissions/' + tID },
  getProjectPermissions (groupID) { return axios.get(this.projectPermissionsURL(groupID)) },
  createProjectPermission (groupID, t) { return axios.post(this.projectPermissionsURL(groupID), t) },
  getProjectPermission (groupID, tID) { return axios.get(this.projectPermissionURL(groupID, tID)) },
  updateProjectPermission (groupID, t) { return axios.put(this.projectPermissionURL(groupID, t.id), t) },
  deleteProjectPermission (groupID, t) { return axios.delete(this.projectPermissionURL(groupID, t.id)) },

  projectStateEmailsURL (groupID) { return '/project_types/' + groupID + '/project_state_emails' },
  projectStateEmailURL (groupID, tID) { return '/project_types/' + groupID + '/project_state_emails/' + tID },
  getProjectStateEmails (groupID) { return axios.get(this.projectStateEmailsURL(groupID)) },
  createProjectStateEmail (groupID, t) { return axios.post(this.projectStateEmailsURL(groupID), t) },
  getProjectStateEmail (groupID, tID) { return axios.get(this.projectStateEmailURL(groupID, tID)) },
  updateProjectStateEmail (groupID, t) { return axios.put(this.projectStateEmailURL(groupID, t.id), t) },
  deleteProjectStateEmail (groupID, t) { return axios.delete(this.projectStateEmailURL(groupID, t.id)) },

  projectChangeEmailsURL (groupID) { return '/project_types/' + groupID + '/project_change_emails' },
  projectChangeEmailURL (groupID, tID) { return '/project_types/' + groupID + '/project_change_emails/' + tID },
  getProjectChangeEmails (groupID) { return axios.get(this.projectChangeEmailsURL(groupID)) },
  createProjectChangeEmail (groupID, t) { return axios.post(this.projectChangeEmailsURL(groupID), t) },
  deleteProjectChangeEmail (groupID, t) { return axios.delete(this.projectChangeEmailURL(groupID, t.id)) },

  groupRolesURL (groupID) { return '/project_types/' + groupID + '/user_roles' },
  getGroupRoles (groupID) { return axios.get('/project_types/' + groupID + '/user_roles') },
  createGroupRole (groupID, role) { return axios.post('/project_types/' + groupID + '/user_roles', role) },
  getGroupRole (roleID) { return axios.get('/user_roles/' + roleID) },
  updateGroupRole (role) { return axios.put('/user_roles/' + role.id, role) },
  deleteGroupRole (groupID, role) { return axios.delete('/project_types/' + groupID + '/user_roles/' + role.id) },

  groupRoleUsersURL (groupID, roleID) { return '/project_types/' + groupID + '/user_roles/' + roleID + '/users' },
  groupRoleUserURL (groupID, roleID, usrID) { return '/project_types/' + groupID + '/user_roles/' + roleID + '/users/' + usrID },
  getGroupRoleUsers (groupID, roleID) { return axios.get(this.groupRoleUsersURL(groupID, roleID)) },
  createGroupRoleUser (groupID, roleID, user) { return axios.post(this.groupRoleUsersURL(groupID, roleID), user) },
  deleteGroupRoleUser (groupID, roleID, user) { return axios.delete(this.groupRoleUserURL(groupID, roleID, user.id)) },

  projectRoleRulesURL (groupID, roleID) { return '/project_types/' + groupID + '/user_roles/' + roleID + '/rules' },
  projectRoleRuleURL (groupID, roleID, ruleID) { return '/project_types/' + groupID + '/user_roles/' + roleID + '/rules/' + ruleID },
  getProjectRoleRules (groupID, roleID) { return axios.get(this.projectRoleRulesURL(groupID, roleID)) },
  createProjectRoleRule (groupID, roleID, rule) { return axios.post(this.projectRoleRulesURL(groupID, roleID), rule) },
  getProjectRoleRule (groupID, roleID, ruleID) { return axios.get(this.projectRoleRuleURL(groupID, roleID, ruleID)) },
  updateProjectRoleRule (groupID, roleID, rule) { return axios.put(this.projectRoleRuleURL(groupID, roleID, rule.id), rule) },
  deleteProjectRoleRule (groupID, roleID, rule) { return axios.delete(this.projectRoleRuleURL(groupID, roleID, rule.id)) },

  transitionsURL (groupID) { return '/project_types/' + groupID + '/state_transitions' },
  transitionURL (groupID, tID) { return '/project_types/' + groupID + '/state_transitions/' + tID },
  getTransitions (groupID) { return axios.get(this.transitionsURL(groupID)) },
  createTransition (groupID, t) { return axios.post(this.transitionsURL(groupID), t) },
  getTransition (groupID, tID) { return axios.get(this.transitionURL(groupID, tID)) },
  updateTransition (groupID, t) { return axios.put(this.transitionURL(groupID, t.id), t) },
  deleteTransition (groupID, t) { return axios.delete(this.transitionURL(groupID, t.id)) },
}
