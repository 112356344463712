<template>
  <Modal
    ref="modal"
    title="Submit Review"
  >
    <BaseForm
      submit-title="Submit"
      @submit="save"
      @cancel="cancel"
    >
      <BaseSelect
        v-model="newStatus"
        :options="statuses"
        title="Review Status"
        name="newStatus"
        track-by="id"
        label="name"
        required
      />
      <SchemaPropertyField
        v-for="f in fields"
        :key="f.name"
        v-model="data[f.name]"
        :name="f.name"
        :schema="f.schema"
        :required="f.required"
      />
      <BaseTextarea
        v-model="comment"
        title="Comment"
        name="comment"
        :required="commentRequired"
        trim
      />
      <FieldError v-model="error" />
    </BaseForm>
  </Modal>
</template>

<script>
/**
 * Modal to change the Sites assigned to a Project.
 */
import Modal from '@/components/Modal'
import SchemaPropertyField from '@/components/SchemaPropertyField'
import FieldError from '@/components/FieldError'
import bookingAPI from '@/api/booking'

export default {
  components: {
    Modal,
    SchemaPropertyField,
    FieldError,
  },
  props: {
    subtypes: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      error: '',
      data: {},
      booking: {
        id: '',
        booking_subtype_id: '',
        review_next_statuses: [],
      },
      statuses: [],
      newStatus: '',
      comment: null,
    }
  },
  computed: {
    commentRequired () { return Boolean(this.newStatus && this.newStatus.comment_required) },
    fields () {
      const subType = this.subtypes.find(st => st.id === this.booking.booking_subtype_id)
      if (!this.newStatus || !this.newStatus.required_props || !subType || !subType.schema || !subType.schema.properties) {
        return []
      }
      return Object.keys(subType.schema.properties)
        .filter(p => this.newStatus.required_props.includes(p))
        .map(p => {
          return {
            name: p,
            schema: subType.schema.properties[p],
            required: true,
          }
        })
    },
  },
  methods: {
    reset () {
      this.data = {}
      this.error = ''
      this.newStatus = ''
      this.statuses = []
      this.booking = { id: '', booking_subtype_id: '', review_next_statuses: [] }
      this.comment = null
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    edit (booking, statuses) {
      this.reset()
      this.statuses = statuses
      this.booking = booking
      this.$refs.modal.show()
    },
    save () {
      const promises = this.fields.map(f => bookingAPI.updateBookingProp(this.booking.id, f.name, this.data[f.name]))
      Promise.all(promises).then(resp => {
        return bookingAPI.createBookingReview(this.booking.id, this.newStatus, this.comment)
      }).then(resp => {
        this.$emit('updated')
        this.cancel()
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
