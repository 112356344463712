<template>
  <Modal
    ref="modal"
    title="Detail"
    large
    scrollable
  >
    <table class="list-item-table">
      <tr
        v-for="f in showFields"
        :key="f.name"
      >
        <td>
          {{ f.title }}
        </td>
        <td>
          {{ f.data }}
          <span
            v-if="f.editFunc"
            class="edit-icon"
            :title="`Edit ${f.title}`"
            @click="f.editFunc"
          >
            <EditIcon :title="`Edit ${f.title}`" />
          </span>
        </td>
      </tr>
    </table>
    <FieldError v-model="error" />
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
import EditIcon from 'vue-material-design-icons/SquareEditOutline.vue'
import FieldError from '@/components/FieldError'
import axios from '@/axios/default'

export default {
  components: {
    FieldError,
    EditIcon,
    Modal,
  },
  data () {
    return {
      error: '',
      apiUrl: '',
      rowData: {},
      fields: [],
    }
  },
  computed: {
    showFields () {
      return this.fields
        .filter(f => f.title && f.title !== '')
        .map(f => {
          return {
            name: f.name,
            title: f.title,
            data: this.showField(f),
            editFunc: f.editFunc && f.editFunc(f, this.rowData),
          }
        })
    },
  },
  methods: {
    show (fields, rowData, apiUrl) {
      this.error = ''
      this.apiUrl = apiUrl
      this.fields = fields
      this.rowData = rowData
      this.$refs.modal.show()
    },
    getNestedData (data, name) {
      let d = data
      const fields = name.split('.')
      while (fields.length > 0 && d) {
        const f = fields.shift()
        d = d[f]
      }
      return d
    },
    showField (f) {
      let d
      if (Object.prototype.hasOwnProperty.call(f, 'formatter')) {
        d = f.formatter(this.rowData[f.name])
      } else if (Object.prototype.hasOwnProperty.call(f, 'rowFormatter')) {
        d = f.rowFormatter(this.rowData)
      } else if (Object.prototype.hasOwnProperty.call(f, 'csvRowFormatter')) {
        d = f.csvRowFormatter(this.rowData)
      } else if (f.name.includes('.')) {
        return this.getNestedData(this.rowData, f.name)
      } else {
        d = this.rowData[f.name]
      }
      return Array.isArray(d) ? d.join(', ') : d
    },
    refresh () {
      if (!this.apiUrl || !this.rowData.id || !this.$refs.modal.showModal) {
        return
      }
      const url = this.apiUrl + '/' + this.rowData.id
      axios.get(url).then(resp => {
        this.rowData = resp.data
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>

<style lang="scss">
.list-item-table {
  width: 100%;
  tr {
    border-width: 0 0 1px 0;
    border-color: $gray-300;
    border-style: solid;
  }
  tr:hover {
    background-color:rgb(244, 244, 244);
    // box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.2);
    // border-left-width: 1px;
    // border-right-width: 1px;
  }
  tr:nth-child(1) {
    border-top-width: 1px;
  }
  tr td:nth-child(1) {
    text-align: right;
    width: 30%;
    font-weight: 600;
    color: $gray-800;
  }
  td {
    color: $gray-600;
    padding: 5px;
  }
  pre {
    white-space: pre-wrap;
  }
}
</style>
