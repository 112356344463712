<template>
  <ValidationProvider
    v-slot="{ errors }"
    :rules="calcRules"
    :name="title"
    slim
  >
    <FormGroup
      :title="title"
      :help="readonly || disabled ? '' : help"
      :error="errors[0]"
      :required="required && !readonly"
      :horizontal="horizontal"
    >
      <input
        v-if="readonly"
        :value="formatDate(value)"
        type="text"
        readonly
        class="form-control-plaintext"
      >
      <Datepicker
        v-else
        v-model="innerValue"
        :name="name"
        :clear-button="!disabled && !required"
        :disabled-dates="disabledDatesAll"
        :disabled="disabled"
        :format="displayDate"
        :placeholder="defaultPlaceholder"
        :monday-first="mondayFirst"
        :input-class="{ 'is-invalid': errors[0] }"
        :class="{ 'vdp-align-right': alignRight }"
        bootstrap-styling
      />
    </FormGroup>
  </ValidationProvider>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import FormGroup from './FormGroup'
import { ValidationProvider } from 'vee-validate'
import formatDate from '@/filters/formatDate'

export default {
  components: {
    Datepicker,
    ValidationProvider,
    FormGroup,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    rules: {
      type: Object,
      default () {
        return {}
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    // The earliest acceptable date
    min: {
      type: String,
      default: '',
    },
    // The latest acceptable date
    max: {
      type: String,
      default: '',
    },
    help: {
      type: String,
      default: '',
    },
    displayFormat: {
      type: [String, Function],
      default: 'DD / MM / YYYY',
    },
    mondayFirst: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    // Array of Date objects for disabled dates.
    disabledDates: {
      type: Array,
      default: null,
    },
    // Array of integers (0-6 as in Date.getDay()) for days of the week disabled.
    disabledDays: {
      type: Array,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerValue: {
      get () { return this.strToDate(this.value) },
      set (date) { this.$emit('input', this.dateToStr(date)) },
    },
    defaultPlaceholder () {
      if (this.placeholder === null) {
        // use displayFormat as placeholder if placeholder hasn't been set.
        if (typeof this.displayFormat === 'string' || this.displayFormat instanceof String) {
          return this.displayFormat
        }
        return ''
      }
      return this.placeholder
    },
    disabledDatesAll () {
      const d = {}
      if (this.min) {
        d.to = moment(this.min, 'YYYY-MM-DD').toDate()
      }
      if (this.max) {
        d.from = moment(this.max, 'YYYY-MM-DD').toDate()
      }
      if (this.disabledDays) {
        d.days = this.disabledDays
      }
      if (this.disabledDates) {
        d.dates = this.disabledDates
      }
      return d
    },
    calcRules () {
      if (this.readonly) {
        return {}
      }
      return Object.assign({ required: this.required }, this.rules)
    },
  },
  methods: {
    strToDate (s) {
      const m = moment(s, 'YYYY-MM-DD')
      return m.isValid() ? m.toDate() : null
    },
    dateToStr (date) {
      if (!date) {
        return null
      }
      return moment(date).format('YYYY-MM-DD') || null
    },
    displayDate (date) {
      if (!date) {
        return null
      }
      if (this.displayFormat instanceof Function) {
        return this.displayFormat(date)
      } else {
        return moment(date).format(this.displayFormat) || null
      }
    },
    formatDate,
  },
}
</script>

<style scoped>
.vdp-datepicker >>> .form-control {
  background-color: white;
}
.vdp-datepicker >>> .vdp-datepicker__clear-button .input-group-text {
  font-size: 1.5em;
  padding: 0 0.7em;
}
.vdp-datepicker.vdp-align-right >>> .vdp-datepicker__calendar {
  right: 0px;
}
</style>
