<template>
  <Modal
    ref="modal"
    :title="'Update ' + role.usr_role.name"
  >
    <BaseForm
      submit-title="Save"
      @submit="save"
      @cancel="cancel"
    >
      <FieldRoleUser
        v-model="user"
        :role="role.usr_role"
        :team="project.team"
        required
      />
      <FieldError v-model="error" />
    </BaseForm>
  </Modal>
</template>

<script>
/**
 * Modal to allow updating the User assigned to a Usr Role for a Project.
 * Emits a 'role-update' event when successful.
 */
import FieldRoleUser from '@/components/FieldRoleUser'
import FieldError from '@/components/FieldError'
import Modal from '@/components/Modal'
import projectAPI from '@/api/project'

export default {
  components: {
    FieldRoleUser,
    FieldError,
    Modal,
  },
  data () {
    return {
      role: {
        project_id: '',
        usr: { id: '', name: '' },
        usr_role: {
          name: '',
          is_team_usr: false,
        },
      },
      error: '',
      project: { id: '', name: '' },
      user: null,
      users: [],
    }
  },
  methods: {
    reset () {
      this.error = ''
      this.user = null
      this.project = { id: '', name: '' }
      this.users = []
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    /**
     * @description open modal
     * @param {Object} project - project
     * @param {Object} role - usr role
     */
    edit (project, role) {
      this.reset()
      this.project = project
      this.role = role
      this.user = role.usr
      this.$refs.modal.show()
    },
    save () {
      projectAPI.updateRoleUser(this.project.id, this.role.usr_role.id, this.user).then(resp => {
        this.$emit('role-update')
        this.cancel()
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
