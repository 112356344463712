<template>
  <Modal
    ref="modal"
    title="Update Quote"
    large
  >
    <BaseForm
      submit-title="Save"
      @submit="save"
      @cancel="cancel"
    >
      <BaseTextarea
        v-model="service.scope"
        :rows="10"
        title="Scope"
        name="scope"
        required
        trim
      />
      <BaseInputPrice
        v-model.number="service.price"
        title="Price"
        name="price"
        required
      />
      <BaseInputNumber
        v-model.number="service.slo"
        title="SLO"
        name="slo"
        required
      />
      <FieldError v-model="error" />
    </BaseForm>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
import FieldError from '@/components/FieldError'
import projectAPI from '@/api/project'

export default {
  components: {
    Modal,
    FieldError,
  },
  data () {
    return {
      error: '',
      service: {
        price: null,
        slo: null,
        scope: '',
      },
    }
  },
  methods: {
    reset () {
      this.error = ''
      this.service = { price: null, slo: null, scope: '' }
      this.price = null
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    edit (service) {
      this.reset()
      this.service = Object.assign({}, service)
      this.$refs.modal.show()
    },
    save () {
      projectAPI.updateServiceQuote(this.service).then(resp => {
        this.$emit('service-update')
        this.cancel()
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
