<template>
  <AttachFormModal
    ref="modal"
    :project="project"
    title="Upload File Attachment"
    :submit-promise="upload"
    submit-title="Upload"
    :financial-types="financialTypes"
  />
</template>

<script>
/**
 * Modal to attach a file to a project.
 * Emit's a 'file-upload' event when successful.
 */
import AttachFormModal from '@/views/Project/AttachFormModal'
import projectAPI from '@/api/project'

export default {
  components: {
    AttachFormModal,
  },
  props: {
    // project to attach file to
    project: {
      type: Object,
      required: true,
    },
    financialTypes: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      uploading: false,
      error: '',
    }
  },
  methods: {
    /**
     * @description open modal and attachments
     * @param {Object} typeID - id for group to which get possible attachment types from
     */
    show (typeID) {
      this.error = ''
      this.$refs.modal.show(typeID)
    },
    async upload (attach) {
      const formData = new FormData()
      // set file name to work around IE including full path in attachment upload
      formData.append(attach.type_name, attach.file, attach.file.name)
      await projectAPI.uploadAttachment(this.project.id, formData)
      this.$emit('file-upload')
    },
  },
}
</script>
