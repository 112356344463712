<template>
  <BaseSelect
    :value="value"
    :options="users"
    :loading="loading"
    :title="role.name"
    :name="fieldName"
    :help="role.info"
    :required="required"
    track-by="id"
    label="name"
    @input="v => $emit('input', v)"
  />
</template>

<script>
import teamAPI from '@/api/team'
import userAPI from '@/api/user'

export default {
  props: {
    // eslint-disable-next-line
    value: {
      type: Object,
    },
    role: {
      type: Object,
      required: true,
    },
    team: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      error: '',
      users: [],
      loading: false,
    }
  },
  computed: {
    fieldName () {
      return this.role.name.toLowerCase().replace(/ /g, '_')
    },
  },
  beforeMount () {
    this.getUsers()
  },
  methods: {
    getUsers () {
      this.error = ''
      let promise
      if (this.role.is_team_usr) {
        if (!this.team) {
          this.error = 'Team Not Set!'
          return
        }
        promise = teamAPI.getTeamUsers(this.team)
      } else {
        promise = userAPI.getUsers()
      }
      this.loading = true
      promise.then(resp => {
        this.users = resp.data
        this.loading = false
      }).catch(error => {
        this.error = error.message
        this.loading = false
      })
    },
  },
}
</script>
