<template>
  <div
    class="custom-control custom-radio"
    :class="{ 'custom-control-inline': inline }"
  >
    <input
      :id="id"
      :name="name"
      class="custom-control-input"
      type="radio"
      :checked="isChecked"
      :value="value"
      @change="update"
    >
    <label
      class="custom-control-label"
      :for="id"
    >
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    // eslint-disable-next-line
    value: {},
    // eslint-disable-next-line
    modelValue: {},
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isChecked () {
      return this.modelValue === this.value
    },
  },
  methods: {
    update () {
      this.$emit('change', this.value)
    },
  },
}
</script>
