<template>
  <component
    :is="component"
    :name="name"
    :rules="rules"
    :title="schema.title"
    :value="value"
    :required="required"
    :help="schema.help"
    :horizontal="horizontal"
    v-bind="binds"
    trim
    @input="v => processInput(v)"
  />
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line
    value: {},
    schema: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    component () {
      if (this.isDate) {
        return 'BaseInputDate'
      } else if (this.isInteger) {
        return 'BaseInputNumber'
      } else if (this.isTextArea) {
        return 'BaseTextarea'
      } else if (this.isBoolean) {
        return 'BaseInputCheckbox'
      } else if (this.isEnum || this.schema.oneOf || this.schema.anyOf) {
        return 'BaseSelect'
      } else {
        return 'BaseInput'
      }
    },
    isDate () {
      return this.schema.type === 'string' && this.schema.format && this.schema.format === 'date'
    },
    isInteger () {
      return this.schema.type === 'integer'
    },
    isBoolean () {
      return this.schema.type === 'boolean'
    },
    isTextArea () {
      return this.schema.type === 'string' && this.schema['ui:widget'] && this.schema['ui:widget'] === 'textarea'
    },
    isEnum () {
      return this.schema.enum || (this.schema.items && this.schema.items.enum)
    },
    rules () {
      const r = {}
      if (this.isInteger) {
        r.integer = true
        if (this.schema.minimum) {
          r.min_value = this.schema.minimum
        }
        if (this.schema.maximum) {
          r.max_value = this.schema.minimum
        }
      } else {
        if (this.schema.minLength) {
          r.min = this.schema.minLength
        }
        if (this.schema.pattern) {
          r.regex = this.schema.pattern
        }
        if (this.schema.format === 'uri') {
          r.url = true
        } else if (this.schema.format === 'email') {
          r.email = true
        }
      }
      return r
    },
    binds () {
      if (this.component === 'BaseInput') {
        let t = 'text'
        if (this.schema.writeOnly) {
          t = 'password'
        }
        return { maxlength: this.schema.maxLength, type: t }
      } else if (this.component === 'BaseSelect') {
        const opts = this.schema.oneOf || this.schema.anyOf
        if (opts) {
          return {
            multiple: !!this.schema.anyOf,
            options: opts.map(o => o.const),
            customLabel: (v) => {
              const opt = opts.find(o => o.const === v)
              if (opt) {
                return opt.title || v
              }
              return v
            },
          }
        }
        return {
          multiple: this.schema.type === 'array',
          options: this.schema.enum || this.schema.items.enum,
        }
      } else if (this.component === 'BaseInputDate') {
        const b = {}
        if (this.schema.disabledDates) {
          if (this.schema.disabledDates.toToday) {
            const today = new Date()
            today.setHours(0, 0, 0, 0)
            b.disabledDates = { to: today }
          }
        }
        return b
      }
      return {}
    },
  },
  methods: {
    processInput (v) {
      // convert integer strings to numbers
      if (this.isInteger) {
        const n = parseFloat(v)
        if (!isNaN(n)) {
          v = n
        }
      }
      this.$emit('input', v)
    },
  },
}
</script>
