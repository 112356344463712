<template>
  <ValidationProvider
    v-slot="{ errors, validate }"
    :rules="calcRules"
    :name="title"
    slim
  >
    <FormGroup
      :title="title"
      :help="help"
      :error="errors[0]"
      :required="required"
      :horizontal="horizontal"
    >
      <div class="custom-file">
        <input
          id="customFile"
          :name="name"
          :class="{ 'is-invalid': errors[0] }"
          class="custom-file-input"
          type="file"
          @change="e => { $emit('input', e.target.files[0]) ; validate(e) }"
        >
        <label
          class="custom-file-label"
          for="customFile"
        >{{ filename }}</label>
      </div>
    </FormGroup>
  </ValidationProvider>
</template>

<script>
import FormGroup from './FormGroup'
import { ValidationProvider, extend } from 'vee-validate'

extend('excludeFiles', {
  validate: (value, { filenames, message }) => {
    if (Array.isArray(value)) {
      if (value.every(val => !val || !filenames.includes(val.name))) {
        return true
      }
    } else if (!value || !filenames.includes(value.name)) {
      return true
    }
    return message || 'The {_field_} file is excluded'
  },
  params: ['filenames', 'message'],
})

export default {
  components: {
    ValidationProvider,
    FormGroup,
  },
  props: {
    value: {
      type: File,
      default: null,
    },
    rules: {
      type: Object,
      default () {
        return {}
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    maxSize: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    help: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Choose file',
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    excludeFiles: {
      type: Array,
      default () {
        return []
      },
    },
    excludeMessage: {
      type: String,
      default: '',
    },
  },
  computed: {
    filename () {
      return this.value ? this.value.name : this.placeholder
    },
    calcRules () {
      const r = Object.assign({ required: this.required }, this.rules)
      if (this.maxSize) { r.size = this.maxSize }
      if (this.excludeFiles.length > 0) {
        r.excludeFiles = { filenames: this.excludeFiles }
        if (this.excludeMessage) {
          r.excludeFiles.message = this.excludeMessage
        }
      }
      return r
    },
  },
}
</script>
