<template>
  <Modal
    ref="modal"
    title="Review Pending"
  >
    <BaseForm
      submit-title="Submit"
      @submit="save"
      @cancel="cancel"
    >
      <BaseInput
        :value="booking.pending_descr"
        title="Pending Description"
        name="pending_descr"
        readonly
      />
      <BaseSelect
        v-model="status"
        :options="statuses"
        title="Change Booking Status"
        name="status"
        required
      />
      <FieldError v-model="error" />
    </BaseForm>
  </Modal>
</template>

<script>
/**
 * Modal to change the Sites assigned to a Project.
 */
import Modal from '@/components/Modal'
import FieldError from '@/components/FieldError'
import bookingAPI from '@/api/booking'

export default {
  components: {
    Modal,
    FieldError,
  },
  data () {
    return {
      error: '',
      booking: {},
      statuses: ['booked', 'cancelled'],
      status: '',
      isChild: false,
    }
  },
  methods: {
    reset () {
      this.error = ''
      this.status = ''
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    edit (booking) {
      this.reset()
      this.booking = booking
      this.$refs.modal.show()
    },
    save () {
      const id = this.booking.id
      bookingAPI.updateBookingStatus(id, this.status).then(resp => {
        this.$emit('updated')
        this.cancel()
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
